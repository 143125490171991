/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormAutomatedActionConfiguration } from '@se/data/forms/types.ts';
import { DataGrid } from '@seeeverything/ui.primitives/src/components/DataGrid/DataGrid.tsx';
import { IDataGridCellTheme } from '@seeeverything/ui.primitives/src/components/DataGrid/themes.ts';
import {
  IDataGridCellThemeArgs,
  IDataGridColumn,
} from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { AutomatedActionsContentPlaceholder } from './AutomatedActionsContentPlaceholder.tsx';
import { AutomatedActionsGridDescriptionCell } from './AutomatedActionsGridDescriptionCell.tsx';
import { AutomatedActionsGridDescriptionCellInactive } from './AutomatedActionsGridDescriptionCellInactive.tsx';

const COACHING_COLUMNS: IDataGridColumn[] = [
  {
    id: 'QuestionInsight',
    label: 'Question Insight',
    width: '4*',
    isSortable: false,
  },
  {
    id: 'Classification',
    label: 'Classification',
    width: '3.5*',
    isSortable: false,
  },
  {
    id: 'Action',
    label: 'Playbook Action',
    width: '9*',
    isSortable: false,
  },
  {
    id: 'Modified',
    label: 'Modified',
    width: '1.5*',
    isSortable: false,
  },
  {
    id: 'ModifiedBy',
    label: 'Modified By',
    width: '2*',
    isSortable: false,
  },
];

const COMPLIANCE_COLUMNS: IDataGridColumn[] = [
  {
    id: 'Issue',
    label: 'Issue',
    width: '3*',
    isSortable: false,
  },
  {
    id: 'Classification',
    label: 'Classification',
    width: '5*',
    isSortable: false,
  },
  {
    id: 'Action',
    label: 'Smart Action',
    width: '8.5*',
    isSortable: false,
  },
  {
    id: 'Modified',
    label: 'Modified',
    width: '1.5*',
    isSortable: false,
  },
  {
    id: 'ModifiedBy',
    label: 'Modified By',
    width: '2*',
    isSortable: false,
  },
];

type RenderCellArgs = {
  columnId: string;
  row: IGridRow<FormAutomatedActionConfiguration>;
  cellValue: string;
};

export interface IAutomatedActionsContentProps {
  onShowDropdownHighlight: () => void;
  onHideDropdownHighlight: () => void;
}

export const AutomatedActionsContent: React.FC<
  IAutomatedActionsContentProps
> = ({ onShowDropdownHighlight, onHideDropdownHighlight }) => {
  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const isLoading = useFormsSelector(
    (state) => state.automatedActionConfiguration.isLoading,
  );

  const gridData = useFormsSelector(
    (state) => state.automatedActionConfiguration.gridData,
  );

  const selectedTemplate = useFormsSelector(
    (state) => state.automatedActionConfiguration.selectedTemplate,
  );

  const editingRows = useFormsSelector(
    (state) => state.automatedActionConfiguration.editingRows,
  );

  const savingRows = useFormsSelector(
    (state) => state.automatedActionConfiguration.savingRows,
  );

  const errorRows = useFormsSelector(
    (state) => state.automatedActionConfiguration.errorRows,
  );

  const formLabel = useTenantLocale((l) => l.label.form);

  const handleRenderCell = useCallback(
    (cellProps: RenderCellArgs) => {
      if (cellProps.columnId !== 'Action') return;

      if (!cellProps.row.value.inCurrentDefinition)
        return (
          <AutomatedActionsGridDescriptionCellInactive
            row={cellProps.row}
            key={cellProps.row.id}
          />
        );

      const rowId = cellProps.row.id;

      return (
        <AutomatedActionsGridDescriptionCell
          key={cellProps.row.id}
          row={cellProps.row}
          editing={editingRows[rowId]}
          saving={savingRows[rowId]}
          error={errorRows[rowId]}
        />
      );
    },
    [editingRows, errorRows, savingRows],
  );

  const cellThemeOverrides = useCallback(
    ({ rowIndex }: IDataGridCellThemeArgs): IDataGridCellTheme => {
      if (isLoading) return;
      if (!gridData) return;

      const rowData = gridData[rowIndex];
      if (!rowData) return;

      const automatedAction = rowData.value;

      const inactiveRow = !automatedAction.inCurrentDefinition;
      if (inactiveRow)
        return {
          italic: true,
          textColor: color.format(-0.35),
          bgColor: 'white',
          fontWeight: 400,
        };

      const savingRow = Boolean(savingRows[rowData.id]);

      const classification =
        automatedAction.type === 'Issue'
          ? automatedAction.issueClassification
          : automatedAction.insightClassification;

      const allClassificationsRow = classification === null;
      if (allClassificationsRow) {
        const blue = color.create(COLORS.BLUE).alpha(0.1).hex();

        return {
          bgColor: blue,
          textColor: savingRow ? color.format(-0.3) : color.format(-0.9),
        };
      }

      return { textColor: savingRow ? color.format(-0.3) : color.format(-0.7) };
    },
    [gridData, isLoading, savingRows],
  );

  if (module === 'cadence') return;

  return (
    <div css={styles.base}>
      <DataGrid
        id={'automatedActions'}
        cellThemeOverrides={cellThemeOverrides}
        columns={
          module === 'compliance' ? COMPLIANCE_COLUMNS : COACHING_COLUMNS
        }
        data={isLoading ? [] : gridData}
        highlightRowOnMouseOver={false}
        isScrollable={true}
        isVirtualized={false}
        key={selectedTemplate?.id}
        renderCellContents={handleRenderCell}
      />
      {!selectedTemplate && (
        <div
          css={styles.placeholder}
          onMouseEnter={onShowDropdownHighlight}
          onMouseLeave={onHideDropdownHighlight}
        >
          <Text italic={true} color={color.format(-0.5)} size={15}>
            {`Select a ${formLabel.toLowerCase()} type to get started.`}
          </Text>
        </div>
      )}
      {isLoading && <AutomatedActionsContentPlaceholder />}
    </div>
  );
};

const styles = {
  base: css({
    borderTop: `solid 1px ${color.format(-0.1)}`,
    paddingTop: 5,
    width: '100%',
    overflowY: 'auto',
    height: '100%',
    position: 'relative',
  }),
  placeholder: css({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 50,
  }),
};
