import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { InstanceCancelReason } from '../types.ts';

export const getFormInstanceCancelReasons = async (
  client: IGraphQLClient,
): Promise<QueryResult<InstanceCancelReason[]>> => {
  try {
    const response = await client.query<{
      forms: {
        formInstanceCancelReasons: Array<{
          id: string;
          reason: string;
        }>;
      };
    }>({
      query: gql`
        query FormInstanceCancelReasons {
          forms {
            formInstanceCancelReasons {
              id
              reason
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    });

    const reasons = response.data.forms.formInstanceCancelReasons;

    if (!reasons) {
      log.error('Failed to retrieve instance cancel reasons');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: reasons.map((r) => ({
        id: r.id,
        reason: r.reason,
      })),
    };
  } catch (error) {
    log.error(
      'Something went wrong trying to query instance cancel reasons',
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
