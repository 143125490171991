/** @jsxImportSource @emotion/react */
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { ISectionLine } from '../types/types.ts';

export interface ISectionFactoryContainerProps {
  line: ISectionLine;
  instanceId: string;
}

export const SectionFactoryContainer: React.FC<
  ISectionFactoryContainerProps
> = ({ line, instanceId }) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      id={line.id}
      highlightOnHover={false}
      marginTop={60}
      marginBottom={15}
      bulletTop={-13.5}
      bulletLeft={0}
      bullet={'block'}
      isVisible={isVisible}
    >
      <SectionTitle text={line.title} />
    </FormListItemWrapped>
  );
};
