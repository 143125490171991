import { QueryOptions } from '@apollo/client';
import { PrimaryTeam } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import * as R from 'ramda';
import { client } from '../common/index.ts';
import { IPerson } from './types.ts';

export async function personById(
  id: string,
  options?: QueryOptions,
): Promise<IPerson | undefined> {
  if (!id) {
    return undefined;
  }

  const response = await client.query<any>({
    fetchPolicy: 'cache-first', // Default to cache-first.
    ...options,
    query: queryPersonById,
    variables: {
      personId: id,
    },
  });

  const person = response.data.orgHierarchy.person;

  return person
    ? {
        ...person,
        memberships: person.memberships.nodes,
      }
    : undefined;
}

export async function personPrimaryTeam(
  personId: string,
  templateId: string,
  options?: QueryOptions,
): Promise<PrimaryTeam | undefined> {
  const response = await client.query<IPeopleV2HeaderGraphqlResponse>({
    fetchPolicy: 'cache-first', // Default to cache-first.
    ...options,
    query: queryPersonPrimaryTeam,
    variables: {
      personId,
      templateId,
    },
  });

  const primaryTeam = response.data?.dashboards?.person.header.primaryTeam;
  if (!primaryTeam?.team) {
    return undefined;
  }

  return {
    id: primaryTeam.team?.id,
    name: R.last(primaryTeam.team?.path?.split('/')),
    path: primaryTeam.team.path,
    role: primaryTeam.role,
  };
}

const queryPersonById = gql`
  query PersonById($personId: ID!) {
    orgHierarchy {
      person(id: $personId) {
        id
        firstName
        lastName
        positionTitle
        memberships {
          nodes {
            team {
              id
              name
              path
              type
            }
            role
            sourceKey
          }
        }
        roles {
          id
          name
        }
      }
    }
  }
`;

const queryPersonPrimaryTeam = gql`
  query PersonPrimaryTeam(
    $personId: ID!
    $templateId: ID!
    $timespan: DashboardTimespanFilterInput
  ) {
    dashboards {
      person(id: $personId) {
        header(templateId: $templateId, timespan: $timespan) {
          primaryTeam {
            team {
              id
              path
            }
            role
          }
        }
      }
    }
  }
`;

interface IPeopleV2HeaderGraphqlResponse {
  dashboards: {
    person: {
      header: {
        primaryTeam?: {
          team: {
            id: string;
            path: string;
          };
          role: string;
        };
      };
    };
  };
}
