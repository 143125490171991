import { SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';

export interface IDataGridCellTheme {
  italic?: boolean;
  textColor?: string;
  bgColor?: string;
  baseStyle?: SerializedStyles;
  fontWeight?: number;
  borderTop?: string | number;
  borderBottom?: string | number | false;
  fontSize?: string | number;
}

const BASE_THEME: IDataGridCellTheme = {
  textColor: color.format(-0.65),
  fontWeight: 400,
  fontSize: 14,
};

const DEEMPHASIZED_THEME: IDataGridCellTheme = {
  italic: true,
  textColor: color.format(-0.35),
  fontWeight: 400,
  fontSize: 14,
};

const EMPHASIZED_THEME: IDataGridCellTheme = {
  textColor: color.format(-0.6),
  fontWeight: 900,
  fontSize: 14,
};

const FOOTER_THEME: IDataGridCellTheme = {
  borderTop: `solid 3px ${color.format(-0.1)}`,
  borderBottom: false,
  textColor: color.format(-0.5),
  fontWeight: 900,
  fontSize: 14,
};

export type CellTheme = 'base' | 'deemphasized' | 'emphasized' | 'footer';

const CELL_THEMES: { [name in CellTheme]: IDataGridCellTheme } = {
  base: BASE_THEME,
  deemphasized: DEEMPHASIZED_THEME,
  emphasized: EMPHASIZED_THEME,
  footer: FOOTER_THEME,
};

export const getCellTheme = (theme: CellTheme = 'base') => {
  if (!CELL_THEMES[theme]) {
    log.error(new Error(`Unable to find cell theme ${theme}.`));
    return CELL_THEMES.base;
  }
  return CELL_THEMES[theme];
};
