/** @jsxImportSource @emotion/react */
import DateFnsUtils from '@date-io/date-fns';
import { css, SerializedStyles } from '@emotion/react';
import { InputProps, PopoverProps } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useDateContext,
  useMomentTenantTimezone,
} from '../../hooks/useDateContext.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { toDisplayValue } from './util.ts';

const KEYBOARD_PROPS = { 'aria-label': 'change date' };

export interface IDatePickerProps {
  outputDate?: 'TenantEndOfDay' | 'TenantStartOfDay';
  dataTest?: string;
  dateFormat?: 'MM/dd/yyyy' | 'dd/MM/yyyy';
  error?: string;
  helperText?: string;
  id: string;
  isEnabled?: boolean;
  label?: string;
  maxDate?: string;
  minDate?: string;
  onChange?: (toIso: string) => void;
  style?: SerializedStyles;
  value?: string;
}

/**
 * See:
 *  https://material-ui.com/components/pickers/
 */
export const DatePicker: React.FC<IDatePickerProps> = ({
  outputDate = 'TenantStartOfDay',
  dataTest,
  dateFormat: propDateFormat,
  error,
  helperText,
  id,
  isEnabled = true,
  label = 'Choose a date',
  maxDate,
  minDate,
  onChange,
  style,
  value,
}) => {
  const moment = useMomentTenantTimezone();
  const dateContext = useDateContext();
  const dateFormat = propDateFormat ?? dateContext.format;

  const [statefulValue, setStatefulValue] = useState(
    value ? toDisplayValue(value, dateContext) : null,
  );

  useEffect(() => {
    if (value) {
      setStatefulValue(toDisplayValue(value, dateContext));
    } else {
      setStatefulValue(null);
    }
  }, [dateContext, value]);

  const resetValueAndInput = useCallback((newValue: string) => {
    setStatefulValue(null);
    setTimeout(() => {
      setStatefulValue(newValue);
    }, 0);
  }, []);

  const [statefulError, setError] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const handleFocus = useCallback(() => setIsOpen(true), []);
  const handleBlur = useCallback(() => {
    setIsOpen(false);

    const repopulateValueIfCleared =
      inputRef.current?.value === '' && statefulValue !== null;
    if (repopulateValueIfCleared) resetValueAndInput(statefulValue);
  }, [resetValueAndInput, statefulValue]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const mouseEnter = useCallback(() => {
    if (!isOpen && statefulValue) setTooltipOpen(true);
  }, [isOpen, statefulValue]);
  const mouseLeave = useCallback(() => setTooltipOpen(false), []);

  const [isTabbingAway, setIsTabbingAway] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const handlePopperFocus = useCallback(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  const handlePopperBlur = useCallback(() => {
    inputRef.current?.blur();
  }, []);

  const handleDateChange = useCallback(
    (date: Date) => {
      if (!date) return;

      const to =
        outputDate === 'TenantEndOfDay'
          ? moment(date, undefined, true).endOf('day').toISOString()
          : moment(date, undefined, true)
              .hour(2)
              .minute(0)
              .second(0)
              .toISOString();

      const invalidReason = getInvalidReason(to, moment, minDate, maxDate);

      if (invalidReason) {
        setError(invalidReason);
        return;
      }

      setError(undefined);

      onChange?.(to);
      resetValueAndInput(to);
    },
    [outputDate, maxDate, minDate, moment, onChange, resetValueAndInput],
  );

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        e.stopPropagation();
        setIsOpen(false);
        return;

      case 'Tab':
        setIsOpen(false);
        setIsTabbingAway(true);
        return;

      default:
        return;
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      inputRef.current?.blur();
    }
  }, [isOpen]);

  const popoverProps: Partial<PopoverProps> = {
    anchorEl: divRef.current,
    anchorOrigin: { horizontal: 'left', vertical: statefulError ? 70 : 50 },
    transformOrigin: { horizontal: 'left', vertical: 'top' },
    disableEnforceFocus: true,
    onFocus: handlePopperFocus,
    onBlur: handlePopperBlur,
  };

  const inputProps: Partial<InputProps> = useMemo(
    () => ({
      onFocus: !isOpen && !isTabbingAway ? handleFocus : undefined,
      onBlur: !isOpen ? handleBlur : undefined,
      inputRef,
    }),
    [handleBlur, handleFocus, isOpen, isTabbingAway],
  );

  useEffect(() => {
    // Ensures a keyboard user can tab away from the field without it regaining focus.
    setTimeout(() => {
      if (isTabbingAway) setIsTabbingAway(false);
    }, 0);
  }, [isTabbingAway]);

  const elPicker = (
    <div data-test={dataTest}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          allowKeyboardControl={false}
          autoOk={true}
          autoComplete={'off'}
          disabled={!isEnabled}
          disableToolbar={false}
          error={Boolean(statefulError || error)}
          format={dateFormat}
          fullWidth={true}
          helperText={statefulError || error || helperText}
          id={id}
          initialFocusedDate={statefulValue}
          InputProps={inputProps}
          KeyboardButtonProps={KEYBOARD_PROPS}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDateChange}
          onClose={handleBlur}
          onKeyDown={handleKeyDown}
          onOpen={handleFocus}
          open={isOpen}
          placeholder={dateFormat.toLowerCase()}
          PopoverProps={popoverProps}
          value={statefulValue}
          variant={'inline'}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        />
      </MuiPickersUtilsProvider>
    </div>
  );

  return (
    <div css={[styles.base, style]} ref={divRef}>
      <Tooltip
        open={tooltipOpen}
        title={
          statefulValue !== null
            ? toDisplayValue(value, dateContext, 'dddd D MMMM, YYYY')
            : undefined
        }
      >
        {elPicker}
      </Tooltip>
    </div>
  );
};

const getInvalidReason = (
  date: string,
  moment: ReturnType<typeof useMomentTenantTimezone>,
  minDate?: string,
  maxDate?: string,
) => {
  const result = moment(date);

  if (minDate && result.isBefore(moment(minDate).startOf('day')))
    return 'Date is before the minimum allowed.';

  if (maxDate && result.isAfter(moment(maxDate).endOf('day')))
    return 'Date is after the maximum allowed.';

  if (!result.isValid()) return 'Not a valid date format.';
};

const styles = {
  base: css({
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  }),
};
