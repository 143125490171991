/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { MultiSelectList } from '@seeeverything/ui.primitives/src/components/MultiSelectList/MultiSelectList.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { DistributionListTeamRuleDetail } from '../../../redux/distributionList/types.ts';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';

export interface ITeamsMultiSelectContainer {
  ruleId: string;
}

export const TeamsMultiSelectContainer: React.FC<
  ITeamsMultiSelectContainer
> = ({ ruleId }) => {
  const dispatch = useFormsDispatch();
  const client = useGraphQL();

  const tenantConfig = useFormsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const isEditable = useFormsSelector(
    (state) => state.formEditDistributionList.draft.canEdit === true,
  );

  const rule = useFormsSelector((state) =>
    state.formEditDistributionList.draft.rules.find((r) => r.id === ruleId),
  );

  const selections = useMemo(() => {
    if (!rule) return [];
    return rule.selections as DistributionListTeamRuleDetail[];
  }, [rule]);

  const buttonLabel = useMemo(() => {
    if (!selections.length) return 'Select Teams';
    return selections.map((s) => s.name).join(`, `);
  }, [selections]);

  const isError = Boolean(rule?.errors);
  const [filter, setFilter] = useState('');
  const [isDropdownShowing, setIsDropdownShowing] = useState(false);

  const showPopup = useCallback(() => {
    setFilter('');
    setIsDropdownShowing(true);
  }, []);

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      placeholderData: keepPreviousData,
      queryKey: [{ key: 'TeamsMultiSelect', filter, tenantConfig }],
      queryFn: async ({ pageParam, queryKey }) => {
        const response = await orgHierarchyQuery.getTeams(client, {
          pageNumber: pageParam,
          name: queryKey[0].filter,
          tenantConfig: queryKey[0].tenantConfig,
        });
        return response.isSuccess ? response.data : null;
      },
      getNextPageParam: (latestPage) => {
        if (!latestPage) return null;
        if (!latestPage.pageInfo.hasNextPage) return null;
        return latestPage.pageInfo.currentPage + 1;
      },
      initialPageParam: 1,
    });

  const teams = useMemo(() => {
    if (!data?.pages) return [];

    return data.pages
      .filter(Boolean)
      .map((page) => page.teams)
      .flat();
  }, [data?.pages]);

  const handleItemSelectionChanged = useCallback(
    (id: string, checked: boolean) => {
      const team = teams.find((t) => t.id === id);
      if (!team) return;

      const nextSelections = checked
        ? [...selections, { id: team.id, name: team.name }]
        : selections.filter((s) => s.id !== id);

      dispatch(
        editDistributionListSlice.updateRuleRow({
          id: ruleId,
          selections: nextSelections.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
          type: rule.type,
        }),
      );
    },
    [dispatch, rule.type, ruleId, selections, teams],
  );

  const dropdownItems = useMemo(() => {
    if (!data?.pages) return;

    return data.pages
      .filter(Boolean)
      .map((page) => page.teams)
      .flat()
      .map((t) => ({
        id: t.id,
        label: t.name,
        description: t.path,
        checked: selections.some((s) => s.id === t.id),
        dataTest: t.id,
      }));
  }, [data?.pages, selections]);

  return (
    <div css={css({ display: 'flex' })}>
      <LabelButton
        bold={!isError}
        capitalized={false}
        ellipsis={true}
        isEnabled={isEditable}
        label={buttonLabel}
        maxWidth={233}
        onClick={showPopup}
        theme={isError ? 'ERROR' : undefined}
      />
      {isDropdownShowing && (
        <Popup onHide={() => setIsDropdownShowing(false)} hideOnClick={false}>
          <MultiSelectList
            isLoading={isLoading}
            items={dropdownItems}
            filter={filter}
            onNextPageRequested={fetchNextPage}
            hasNextPage={isFetching ? 'LOADING' : hasNextPage}
            onItemSelectionChanged={handleItemSelectionChanged}
            onFilterChanged={setFilter}
          />
        </Popup>
      )}
    </div>
  );
};
