/** @jsxImportSource @emotion/react */
import { ActionPlanFactoryContainer } from '../../factory/ActionPlanFactory.tsx';
import { AttendanceFactoryContainer } from '../../factory/AttendanceFactory.tsx';
import { DigitalContentFactoryContainer } from '../../factory/DigitalContentFactory.tsx';
import { FooFactoryContainer } from '../../factory/FooFactory.tsx';
import { FormFilesFactoryContainer } from '../../factory/FormFilesFactory.tsx';
import { InputsFactoryContainer } from '../../factory/InputsFactory.tsx';
import { NumberSliderFactoryContainer } from '../../factory/NumberSliderFactory.tsx';
import { QuestionFactoryContainer } from '../../factory/QuestionFactory.tsx';
import { ScoreFactoryContainer } from '../../factory/ScoreFactory.tsx';
import { SectionFactoryContainer } from '../../factory/SectionFactory.tsx';
import { SignoffFactoryContainer } from '../../factory/SignoffFactory.tsx';
import { SliderAnswerFactoryContainer } from '../../factory/SliderAnswerFactory.tsx';
import { SpeechBlockFactoryContainer } from '../../factory/SpeechBlockFactory.tsx';
import { SwitchAnswerFactoryContainer } from '../../factory/SwitchAnswerFactory.tsx';
import { FormLineById } from '../../redux/form-instance/types.ts';
import { FormLineCoachingSummary } from '../FormLineCoachingSummary/FormLineCoachingSummary.tsx';
import { FormLineOption } from '../FormLineOption/FormLineOption.tsx';
import { IssueCheckInContainer } from '../IssueCheckIn/IssueCheckInContainer.tsx';
import { IssueCoachingPlanContainer } from '../IssueCoachingPlan/IssueCoachingPlanContainer.tsx';
import { IssueSummaryContainer } from '../IssueSummary/IssueSummaryContainer.tsx';
import { ActionPlanPlaceholder } from './components/ActionPlanPlaceholder.tsx';

export interface IFormProps {
  canUpdateInstance: boolean;
  instanceId?: string;
  isFormsDesigner?: boolean;
  isSpinning?: boolean;
  lines?: FormLineById;
}

/**
 * A list of form items.
 */
export const Form: React.FC<IFormProps> = ({
  canUpdateInstance,
  instanceId,
  isFormsDesigner,
  lines = {},
}) => {
  const linesArray = Object.values(lines);

  return (
    <>
      {linesArray.map((line) => {
        if (line.type === 'actionPlan')
          return isFormsDesigner ? (
            <ActionPlanPlaceholder
              key={line.id}
              line={line}
              instanceId={instanceId}
            />
          ) : (
            <ActionPlanFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'attendance')
          return (
            <AttendanceFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'digitalContent')
          return (
            <DigitalContentFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              isFormsDesigner={isFormsDesigner}
              line={line}
            />
          );

        if (line.type === 'foo')
          return (
            <FooFactoryContainer
              key={line.id}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'inputs')
          return (
            <InputsFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'fileUpload')
          return (
            <FormFilesFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'issueCheckIn')
          return (
            <IssueCheckInContainer key={line.id} instanceId={instanceId} />
          );

        if (line.type === 'issueCoachingPlan' && !line.subSection)
          return (
            <IssueCoachingPlanContainer key={line.id} instanceId={instanceId} />
          );

        if (line.type === 'issueSummary')
          return (
            <IssueSummaryContainer
              key={line.id}
              title={line.title}
              instanceId={instanceId}
            />
          );

        if (line.type === 'numberSliderAnswer')
          return (
            <NumberSliderFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'optionsAnswer')
          return (
            <FormLineOption
              key={line.id}
              line={line}
              instanceId={instanceId}
              canUpdateInstance={canUpdateInstance}
            />
          );

        if (line.type === 'question')
          return (
            <QuestionFactoryContainer
              key={line.id}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'score')
          return (
            <ScoreFactoryContainer
              key={line.id}
              line={line}
              instanceId={instanceId}
            />
          );

        if (line.type === 'section')
          return (
            <SectionFactoryContainer
              key={line.id}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'signoff')
          return (
            <SignoffFactoryContainer
              key={line.id}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'sliderAnswer')
          return (
            <SliderAnswerFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'speechBlock')
          return (
            <SpeechBlockFactoryContainer
              key={line.id}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'switchAnswer')
          return (
            <SwitchAnswerFactoryContainer
              key={line.id}
              canUpdateInstance={canUpdateInstance}
              instanceId={instanceId}
              line={line}
            />
          );

        if (line.type === 'coachingSummary')
          return (
            <FormLineCoachingSummary
              key={line.id}
              line={line}
              instanceId={instanceId}
            />
          );

        return null;
      })}
    </>
  );
};
