/** @jsxImportSource @emotion/react */
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export type CoachingSummaryErrorProps = {
  type: 'highlights' | 'issues' | 'learning areas';
  onRetryLoad: () => void;
};

export const CoachingSummaryError: React.FC<CoachingSummaryErrorProps> = ({
  type,
  onRetryLoad,
}) => (
  <Button
    style={styles.base}
    onClick={onRetryLoad}
    hoverAlpha={0.18}
    fill={COLORS.ORANGE_TRANSPARENT}
  >
    <>
      <Icons.contactSupport
        size={95}
        fill={COLORS.ORANGE_LIGHT}
        style={{ paddingBottom: 5 }}
      />
      <Text
        color={COLORS.ORANGE_DARK}
        size={18}
        align={'center'}
        cursor={'inherit'}
      >
        {`We aren't able to show any ${type} right now.`}
      </Text>
      <Text
        color={COLORS.ORANGE_DARK}
        size={14}
        align={'center'}
        cursor={'inherit'}
      >
        {`Click here to reload. If this problem continues, please try again later.`}
      </Text>
    </>
  </Button>
);

const styles = {
  base: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    padding: '30px 0',
    margin: '20px 0',
    backgroundColor: COLORS.ORANGE_TRANSPARENT,
  } as const,
};
