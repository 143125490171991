import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClassificationsDefinition,
  FormInsight,
  InsightType,
  NotesDefinition,
} from '@se/data/forms/types.ts';
import { ascend, prop, sortWith } from 'ramda';
import { FormAnswer } from '../../types/types.ts';

const sortInsights = sortWith<FormInsight>([
  ascend(prop('formQuestionIndex')),
  ascend(prop('label')),
]);

export type InsightState = {
  isLoading: boolean;
  insights: FormInsight[];
  canView?: boolean;
  loadError?: boolean;
  insightErrors: Record<string, { classifications?: string; notes?: string }>;
  insightLoading: Record<string, boolean>;
};

const DEFAULT_STATE: InsightState = {
  isLoading: false,
  insights: [],
  insightErrors: {},
  insightLoading: {},
};

const slice = createSlice({
  name: 'libs/forms/insight',
  initialState: DEFAULT_STATE,
  reducers: {
    addClassification(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        classificationValue: string;
        itemIndex: number;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      const existingClassification = insight.classificationValues.some(
        (existing) => existing === action.payload.classificationValue,
      );
      if (existingClassification) return;

      insight.classificationValues.push(action.payload.classificationValue);
      state.insightErrors[insight.id] = {};
    },
    answerChangedWithNewInsightId(
      state,
      action: PayloadAction<{
        instanceId: string;
        toAnswer: FormAnswer;
        insightId: string;
      }>,
    ) {
      state.insightErrors[action.payload.insightId] = {};
      state.insightLoading[action.payload.insightId] = false;
    },
    setErrors(
      state,
      action: PayloadAction<{
        answerId: string;
        classification?: string;
        notes?: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      if (action.payload.classification)
        state.insightErrors[insight.id].classifications =
          action.payload.classification;

      if (action.payload.notes)
        state.insightErrors[insight.id].notes = action.payload.notes;
    },
    existingInsightForAnswerIdLoading(
      state,
      action: PayloadAction<{ answerId: string }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;
      state.insightLoading[insight.id] = true;
    },
    addInsight(
      state,
      action: PayloadAction<{
        instanceId: string;
        answerId: string;
        classificationDefinition: ClassificationsDefinition;
        classificationValues: string[];
        formAnswerSelected: string;
        formLineId: string;
        formQuestionIndex: number;
        formQuestionText: string;
        formSectionName: string;
        id: string;
        label: string;
        notes?: string;
        notesDefinition?: NotesDefinition;
        parentSectionLineId: string;
        type: InsightType;
      }>,
    ) {
      const newInsight = action.payload;
      state.insights.push({
        id: newInsight.id,
        answerId: newInsight.answerId,
        classificationDefinition: newInsight.classificationDefinition,
        classificationValues: newInsight.classificationValues,
        formAnswerSelected: newInsight.formAnswerSelected,
        formLineId: newInsight.formLineId,
        formQuestionIndex: newInsight.formQuestionIndex,
        formQuestionText: newInsight.formQuestionText,
        formSectionName: newInsight.formSectionName,
        label: newInsight.label,
        notesValue: newInsight.notes,
        notesDefinition: newInsight.notesDefinition,
        parentSectionLineId: newInsight.parentSectionLineId,
        type: newInsight.type,
      });

      state.insights = sortInsights(state.insights);
      state.insightLoading[newInsight.id] = false;
      state.insightErrors[newInsight.id] = {};
      state.isLoading = false;
    },
    deleteClassification(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        classificationValue: string;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      insight.classificationValues = insight.classificationValues.filter(
        (existing) => existing !== action.payload.classificationValue,
      );

      state.insightErrors[insight.id].classifications = undefined;
    },
    loadError(state) {
      state.isLoading = false;
      state.loadError = true;
    },
    loadedInsights(
      state,
      action: PayloadAction<{
        insights: FormInsight[];
        canView: boolean;
      }>,
    ) {
      state.isLoading = false;
      state.loadError = false;
      state.insights = sortInsights(action.payload.insights ?? []);
      state.canView = action.payload.canView;

      for (const insight of action.payload.insights) {
        state.insightErrors[insight.id] = {};
        state.insightLoading[insight.id] = false;
      }
    },
    clearInsights(state) {
      state.isLoading = false;
      state.insights = [];
      state.insightErrors = {};
      state.insightLoading = {};
    },
    loadInsights(state) {
      state.isLoading = true;
      state.insights = [];
      state.insightErrors = {};
      state.insightLoading = {};
    },
    removeInsightByAnswerId(
      state,
      action: PayloadAction<{ answerId: string; instanceId: string }>,
    ) {
      state.insights = state.insights.filter(
        ({ answerId }) => answerId !== action.payload.answerId,
      );
    },
    saveClassifications(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      state.insightErrors[insight.id].classifications = undefined;
    },
    setNotes(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        notes: string;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      insight.notesValue = action.payload.notes;
      state.insightErrors[insight.id].notes = undefined;
    },
    notifyInsightChanged: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ success: boolean; instanceId: string }>,
    ) => state,
  },
});

export const {
  addClassification,
  addInsight,
  answerChangedWithNewInsightId,
  clearInsights,
  deleteClassification,
  existingInsightForAnswerIdLoading,
  loadedInsights,
  loadError,
  loadInsights,
  notifyInsightChanged,
  removeInsightByAnswerId,
  saveClassifications,
  setErrors,
  setNotes,
} = slice.actions;
export const reducer = slice.reducer;
export type State = InsightState;
