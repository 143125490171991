import { FormIssueReviewAction } from '@se/data/forms/types.ts';
import momentTz from 'moment-timezone';
import { formatDueBy } from '../../util/util.instance.ts';

export const formatReviewActions = (
  timezone: string,
  reviewActions: FormIssueReviewAction[],
) => {
  const nonCancelledActions = reviewActions.filter(
    ({ status }) => status !== 'Cancelled',
  );

  const completedActions = reviewActions.filter(
    ({ status }) => status === 'Completed',
  );

  if (
    completedActions.length > 0 &&
    completedActions.length === nonCancelledActions.length
  )
    return `Remediation Actions: All completed`;

  const overdueActions = reviewActions.filter(
    ({ status }) => status === 'Overdue',
  );

  const openActions = reviewActions.filter(({ status }) => status === 'Open');

  if (overdueActions.length === 0 && openActions.length === 0) return;

  const hasCompletedActions = Boolean(completedActions.length);
  const hasOpenActions = Boolean(openActions.length);
  const hasOverdueActions = Boolean(overdueActions.length);

  if (hasCompletedActions && hasOpenActions && hasOverdueActions) {
    const completedAndOpenText = [
      toCompletedText(completedActions.length),
      toOpenText(openActions.length),
    ].join(', ');

    const allText = [
      completedAndOpenText,
      toOverdueText(overdueActions.length),
    ].join(', and ');

    return `Remediation Actions: ${allText} - ${
      toOverdueDueText(overdueActions, timezone) ||
      toOpenDueText(openActions, timezone)
    }`;
  }

  const overdueAndOpenText = [
    toCompletedText(completedActions.length),
    toOpenText(openActions.length),
    toOverdueText(overdueActions.length),
  ]
    .filter(Boolean)
    .join(' and ');

  return `Remediation Actions: ${overdueAndOpenText} - ${
    toOverdueDueText(overdueActions, timezone) ||
    toOpenDueText(openActions, timezone)
  }`;
};

const nextDueAction = (
  timezone: string,
  actions?: FormIssueReviewAction[],
): { action: FormIssueReviewAction; countWithSameDueDate: number } => {
  if (!actions?.length) return { action: undefined, countWithSameDueDate: 0 };

  return actions.reduce(
    (acc, nextAction) => {
      if (!nextAction.dueBy) return acc;
      if (!acc.action) return { action: nextAction, countWithSameDueDate: 1 };

      const actionDueBy = momentTz(acc.action.dueBy).tz(timezone).endOf('day');
      const nextActionDueBy = momentTz(nextAction.dueBy)
        .tz(timezone)
        .endOf('day');

      if (actionDueBy.toISOString() === nextActionDueBy.toISOString())
        return {
          action: acc.action,
          countWithSameDueDate: acc.countWithSameDueDate + 1,
        };

      return actionDueBy < nextActionDueBy
        ? acc
        : { action: nextAction, countWithSameDueDate: 1 };
    },
    { action: undefined, countWithSameDueDate: 0 },
  );
};

const toOverdueText = (overdueCount: number) =>
  overdueCount ? `${overdueCount} overdue` : '';

const toOverdueDueText = (
  overdueActions: FormIssueReviewAction[],
  timezone: string,
) => {
  const overdueCount = overdueActions?.length;
  if (!overdueCount) return '';

  const { action, countWithSameDueDate } = nextDueAction(
    timezone,
    overdueActions,
  );
  const dueBy = action?.dueBy;

  if (!dueBy) return '';
  if (countWithSameDueDate === undefined) return '';

  return formatDueBy({
    dueBy,
    showDistantDates: true,
    capitalized: true,
    timezone,
  });
};

const toCompletedText = (completedCount: number) =>
  completedCount ? `${completedCount} completed` : '';

const toOpenText = (openCount: number) =>
  openCount ? `${openCount} open` : '';

const toOpenDueText = (
  openActions: FormIssueReviewAction[],
  timezone: string,
) => {
  const openCount = openActions?.length;
  if (!openCount) return '';

  const { action, countWithSameDueDate } = nextDueAction(timezone, openActions);
  const dueBy = action?.dueBy;

  if (!dueBy) return '';
  if (countWithSameDueDate === undefined) return '';

  const formattedDueBy = formatDueBy({
    dueBy,
    showDistantDates: true,
    capitalized: openCount === 1,
    timezone,
  });

  if (openCount === 1) return formattedDueBy;

  if (openCount !== countWithSameDueDate) return `next ${formattedDueBy}`;

  if (openCount === 2) return `both ${formattedDueBy}`;
  return `all ${formattedDueBy}`;
};
