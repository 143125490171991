import { isAnyOf } from '@reduxjs/toolkit';
import { formsQuery } from '@se/data/forms/query/index.ts';
import {
  CoachingSummarySectionInsight,
  CoachingSummarySectionIssue,
} from '@se/data/forms/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map, mergeMap, of } from 'rxjs';
import { hasCoachingSummaries } from '../../util/util.instance.ts';
import {
  ReduxFormInstanceAnswerChanged,
  ReduxFormInstanceServerLoad,
  ReduxFormInstanceSubjectChange,
  ReduxFormInstanceSubjectChanged,
} from '../form-instance/types.ts';
import { insightSlice } from '../insight/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { formCoachingSummarySlice } from './index.ts';

export const formCoachingSummaryEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  clearOnInstanceLoadEpic,
  clearOnSubjectDeletedEpic,
  loadCoachingSummariesOnSubjectChangedEpic,
  loadCoachingSummaryEpic,
  reloadCoachingSummariesEpic,
  reloadCoachingSummariesOnAnswerDeletedEpic,
  setLoadingOnSubjectChangeEpic,
  setReloadingCoachingSummariesOnInsightChangeEpic,
);

function loadCoachingSummaryEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formCoachingSummarySlice.loadCoachingSummary.match),
    mergeMap(async (action) => {
      const { instanceId, lineId, sectionType, sectionFilter } = action.payload;

      const response = await formsQuery.getCoachingSummarySection(client, {
        instanceId,
        sectionType,
        sectionFilter,
      });

      if (!response.isSuccess)
        return of(
          formCoachingSummarySlice.loadCoachingSummaryError({
            instanceId,
            lineId,
          }),
        );

      return of(
        formCoachingSummarySlice.loadedCoachingSummary({
          instanceId,
          lineId,
          issues:
            sectionType === 'IssuesIdentified'
              ? (response.data as CoachingSummarySectionIssue[])
              : undefined,
          insights:
            sectionType !== 'IssuesIdentified'
              ? (response.data as CoachingSummarySectionInsight[])
              : undefined,
          sectionType,
          sectionFilter,
        }),
      );
    }),
    concatAll(),
  );
}

function setLoadingOnSubjectChangeEpic(
  action$: Observable<ReduxFormInstanceSubjectChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/SUBJECT_CHANGE'),
    filter((action) => action.payload.change.type !== 'DELETE'),
    filter(
      (action) =>
        action.payload.instanceId ===
        state$.value.formCoachingSummary.instanceId,
    ),
    filter((action) =>
      hasCoachingSummaries(
        state$.value.formInstance.instances[action.payload.instanceId],
      ),
    ),
    map((action) =>
      from(
        Object.keys(state$.value.formCoachingSummary.sections).map((lineId) =>
          formCoachingSummarySlice.markSectionAsLoading({
            instanceId: action.payload.instanceId,
            lineId,
            clearExisting: true,
            stale: false,
          }),
        ),
      ),
    ),
    concatAll(),
  );
}

function loadCoachingSummariesOnSubjectChangedEpic(
  action$: Observable<ReduxFormInstanceSubjectChanged>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/SUBJECT_CHANGED'),
    filter((action) => action.payload.change.type !== 'DELETE'),
    filter(
      (action) =>
        action.payload.instanceId ===
        state$.value.formCoachingSummary.instanceId,
    ),
    filter((action) =>
      hasCoachingSummaries(
        state$.value.formInstance.instances[action.payload.instanceId],
      ),
    ),
    map((action) =>
      from(
        Object.keys(state$.value.formCoachingSummary.sections).map((lineId) =>
          formCoachingSummarySlice.markSectionAsLoading({
            instanceId: action.payload.instanceId,
            lineId,
            clearExisting: true,
            stale: true,
          }),
        ),
      ),
    ),
    concatAll(),
  );
}

function setReloadingCoachingSummariesOnInsightChangeEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        insightSlice.addClassification.match,
        insightSlice.addInsight.match,
        insightSlice.answerChangedWithNewInsightId.match,
        insightSlice.deleteClassification.match,
        insightSlice.removeInsightByAnswerId.match,
        insightSlice.saveClassifications.match,
        insightSlice.setNotes.match,
      ),
    ),
    filter(
      (action) =>
        action.payload.instanceId ===
        state$.value.formCoachingSummary.instanceId,
    ),
    filter((action) =>
      hasCoachingSummaries(
        state$.value.formInstance.instances[action.payload.instanceId],
      ),
    ),
    map((action) =>
      from(
        Object.entries(state$.value.formCoachingSummary.sections)
          .filter(([, section]) => section.sectionType !== 'IssuesIdentified')
          .map(([lineId]) =>
            formCoachingSummarySlice.markSectionAsLoading({
              instanceId: action.payload.instanceId,
              lineId,
              clearExisting: false,
              stale: false,
            }),
          ),
      ),
    ),
    concatAll(),
  );
}

function reloadCoachingSummariesOnAnswerDeletedEpic(
  action$: Observable<ReduxFormInstanceAnswerChanged>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGED'),
    filter(
      (action) =>
        action.payload.instanceId ===
        state$.value.formCoachingSummary.instanceId,
    ),
    filter((action) =>
      hasCoachingSummaries(
        state$.value.formInstance.instances[action.payload.instanceId],
      ),
    ),
    filter((action) => {
      if (action.payload.change.type !== 'DELETE') return false;
      if (action.payload.change.fromAnswer.type !== 'optionsAnswer')
        return false;

      const instance =
        state$.value.formInstance.instances[action.payload.instanceId];
      if (!instance) return false;

      const question = instance.lines[action.payload.change.fromAnswer.lineId];
      if (!question) return false;
      if (question.type !== 'optionsAnswer') return false;
      return (question.options ?? []).some((o) =>
        Boolean(o.insight || o.issue),
      );
    }),
    map((action) =>
      from(
        Object.entries(state$.value.formCoachingSummary.sections)
          .filter(([, section]) => section.sectionType !== 'IssuesIdentified')
          .map(([lineId]) =>
            formCoachingSummarySlice.markSectionAsLoading({
              instanceId: action.payload.instanceId,
              lineId,
              clearExisting: false,
              stale: true,
            }),
          ),
      ),
    ),
    concatAll(),
  );
}

function reloadCoachingSummariesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(insightSlice.notifyInsightChanged.match),
    filter(
      (action) =>
        action.payload.instanceId ===
        state$.value.formCoachingSummary.instanceId,
    ),
    filter((action) =>
      hasCoachingSummaries(
        state$.value.formInstance.instances[action.payload.instanceId],
      ),
    ),
    map((action) =>
      from(
        Object.entries(state$.value.formCoachingSummary.sections)
          .filter(([, section]) => section.sectionType !== 'IssuesIdentified')
          .map(([lineId]) =>
            formCoachingSummarySlice.markSectionAsLoading({
              instanceId: action.payload.instanceId,
              lineId,
              clearExisting: false,
              stale: true,
            }),
          ),
      ),
    ),
    concatAll(),
  );
}

function clearOnSubjectDeletedEpic(
  action$: Observable<ReduxFormInstanceSubjectChanged>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/SUBJECT_CHANGED'),
    filter((action) => action.payload.change.type === 'DELETE'),
    map(() => formCoachingSummarySlice.clearCoachingSummaries()),
  );
}

function clearOnInstanceLoadEpic(
  action$: Observable<ReduxFormInstanceServerLoad>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOAD'),
    map(() => formCoachingSummarySlice.clearCoachingSummaries()),
  );
}
