import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
  FormActionPlanAction,
  FormActionPlanGoal,
  FormInstanceActionPlan,
  FormInstanceActionPlanType,
} from '@se/data/forms/types.ts';
import { statusWithOverdue } from '@se/data/forms/utils.ts';

type FormActionPlan = {
  isLoading: boolean;
  loadError: boolean;
  plan?: FormInstanceActionPlan;
};

export type FormActionPlanState = {
  actionPlan: Record<string, FormActionPlan>;
  isLoadingAction: Record<string, boolean>;
  isLoadingGoal: Record<string, boolean>;
};

const DEFAULT_STATE: FormActionPlanState = {
  actionPlan: {},
  isLoadingAction: {},
  isLoadingGoal: {},
};

const slice = createSlice({
  name: 'libs/forms/actionPlan',
  initialState: DEFAULT_STATE,
  reducers: {
    reset: () => DEFAULT_STATE,
    loadActionPlan(
      state,
      action: PayloadAction<{
        includeCategories: string[];
        instanceId: string;
        lineId: string;
        typeFilter?: FormInstanceActionPlanType;
        hasSections: boolean;
      }>,
    ) {
      state.actionPlan[action.payload.lineId] = {
        isLoading: true,
        loadError: false,
      };
    },
    loadedActionPlan(
      state,
      action: PayloadAction<{
        instanceId: string;
        lineId: string;
        plan: FormInstanceActionPlan;
      }>,
    ) {
      state.actionPlan[action.payload.lineId] = {
        isLoading: false,
        loadError: false,
        plan: action.payload.plan,
      };
    },
    loadError(
      state,
      action: PayloadAction<{ instanceId: string; lineId: string }>,
    ) {
      state.actionPlan[action.payload.lineId].isLoading = false;
      state.actionPlan[action.payload.lineId].loadError = true;
    },
    upsertActions(
      state,
      action: PayloadAction<{
        actions: FormActionPlanAction[];
        instanceId: string;
        timezone: string;
      }>,
    ) {
      action.payload.actions.forEach((newAction) => {
        Object.values(state.actionPlan)
          .filter(({ plan }) => Boolean(plan))
          .map(({ plan }) => {
            // Goal action
            if (plan.hasSections === true && newAction.goalId) {
              plan.sections.forEach((section) => {
                section.goals
                  .filter((g) => g.id === newAction.goalId)
                  .forEach((g) =>
                    mutateStateUpsertAction(
                      g.actions,
                      newAction,
                      true,
                      action.payload.timezone,
                    ),
                  );
              });
            }

            if (plan.hasSections === true && !newAction.goalId) {
              plan.sections.forEach((section) => {
                // Issue actions
                if (newAction.issueId && section.type === 'IssueActions') {
                  mutateStateUpsertAction(
                    section.actions,
                    newAction,
                    false,
                    action.payload.timezone,
                  );
                }

                // Standalone actions
                if (!newAction.issueId && section.type === 'Actions') {
                  const allowAdd = plan.typeFilter !== 'OnlyExisting';
                  mutateStateUpsertAction(
                    section.actions,
                    newAction,
                    allowAdd,
                    action.payload.timezone,
                  );
                }
              });
            }

            if (plan.hasSections === true) return;

            // Goals without sections are not supported.
            if (newAction.goalId) return;

            // No sections - Standalone actions + Issue actions
            // Issue coaching plan, issue check-in, compliance, or cadence
            mutateStateUpsertAction(
              plan.actions,
              newAction,
              true,
              action.payload.timezone,
            );
          });
      });
    },
    upsertGoals(
      state,
      action: PayloadAction<{
        goals: FormActionPlanGoal[];
        instanceId: string;
        timezone: string;
      }>,
    ) {
      action.payload.goals.forEach((newGoal) => {
        Object.values(state.actionPlan)
          .filter(({ plan }) => Boolean(plan))
          .map(({ plan }) => {
            if (plan.hasSections === false) return;

            const match = plan.sections.find((section) =>
              !newGoal.goalCategory
                ? section.type === 'UncategorizedGoals'
                : section.type === 'CategoryGoals' &&
                  section.goalCategory.id === newGoal.goalCategory.id,
            );

            if (!match) return;
            mutateStateUpsertGoal(
              match.goals,
              newGoal,
              plan.typeFilter !== 'OnlyExisting',
              action.payload.timezone,
            );
          });
      });
    },
  },
});

const mutateStateUpsertAction = (
  actions: Draft<FormActionPlanAction>[],
  newAction: FormActionPlanAction,
  allowAdd: boolean,
  timezone: string,
) => {
  let updated = false;

  // Will update, rather than add.
  actions.forEach((action) => {
    if (action.id !== newAction.id) return;

    updated = true;

    /**
     * With RTK/Immer, we have to update the object properties
     * in-place like this, rather than overwrite the object.
     */
    action.assignedTo = newAction.assignedTo;
    action.description = newAction.description;
    action.dueBy = newAction.dueBy;
    action.goalId = newAction.goalId;
    action.formInstanceId = newAction.formInstanceId;
    action.insightId = newAction.insightId;
    action.issueId = newAction.issueId;
    action.status = statusWithOverdue(
      newAction.status,
      timezone,
      newAction.dueBy,
    );
  });

  // If it hasn't been updated, add this new action to the end of the list.
  if (!updated && allowAdd) actions.push(newAction);
};

const mutateStateUpsertGoal = (
  goals: Draft<FormActionPlanGoal>[],
  newGoal: FormActionPlanGoal,
  allowAdd: boolean,
  timezone: string,
) => {
  let updated = false;

  // Will update, rather than add.
  goals.forEach((goal) => {
    if (goal.id !== newGoal.id) return;

    updated = true;

    /**
     * With RTK/Immer, we have to update the object properties
     * in-place like this, rather than overwrite the object.
     */
    goal.assignedTo = newGoal.assignedTo;
    goal.description = newGoal.description;
    goal.dueBy = newGoal.dueBy;
    goal.status = statusWithOverdue(newGoal.status, timezone, newGoal.dueBy);
  });

  // If it hasn't been updated, add this new goal to the end of the list.
  if (!updated && allowAdd) goals.push(newGoal);
};

export const {
  reset,
  loadActionPlan,
  loadedActionPlan,
  loadError,
  upsertActions,
  upsertGoals,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormActionPlanState;
