/** @jsxImportSource @emotion/react */
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { useCallback } from 'react';
import { useFormsSelector } from '../../redux/store.ts';
import { SpeechBlock } from './SpeechBlock.tsx';

export type SpeechBlockContainerProps = {
  canHide?: boolean;
  id: string;
  instanceId: string;
  markdown: string;
};

export const SpeechBlockContainer: React.FC<SpeechBlockContainerProps> = ({
  canHide,
  id,
  instanceId,
  markdown,
}) => {
  const templateId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.template?.id,
  );

  const localStorageKey = `com.seeeverything.forms/${templateId}/${id}/speechBlockHidden`;
  const [isHidden, setIsHidden] = useLocalStorage(localStorageKey, false);

  const handleHideClicked = useCallback(() => {
    if (!templateId) return;

    setIsHidden(!isHidden);
  }, [isHidden, setIsHidden, templateId]);

  return (
    <SpeechBlock
      canHide={canHide}
      id={id}
      isHidden={templateId ? isHidden : undefined}
      markdown={markdown}
      onHideClicked={handleHideClicked}
    />
  );
};
