/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { useTenant } from '@seeeverything/ui.primitives/src/hooks/useTenant.ts';
import getConfig from 'next/config.js';
import { TenantDateTime } from './TenantDateTime.tsx';

export const TenantDetails: React.FC = () => {
  const tenant = useTenant();
  const endpoint = getConfig().publicRuntimeConfig.GRAPHQL_ENDPOINT;
  const version = getConfig().publicRuntimeConfig.VERSION;

  return (
    <div css={styles.base}>
      <a href={'/tenant'} css={styles.link}>
        {tenant}
      </a>
      <TenantDateTime />
      <a href={endpoint} target={'_blank'} rel={'noreferrer'} css={styles.link}>
        {`GraphQL: ${endpoint}`}
      </a>
      {!version && <Markdown text={`Version: **${version}**`} />}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  link: css({
    textDecoration: 'none',
  }),
};
