import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, map, mergeMap, Observable } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { formBulkUploadImportFileSlice } from './index.ts';

export const formBulkUploadImportFileEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  tabChangeOnFileUploadedEpic,
  tabChangeOnWorkflowSelectedEpic,
  validateImportFileOnSelectWorkflowEpic,
  importBulkUploadFileEpic,
);

function tabChangeOnFileUploadedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.fileUpload.match),
    map(() => formBulkUploadImportFileSlice.changeTab({ tabId: 'WORKFLOW' })),
  );
}

function tabChangeOnWorkflowSelectedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.selectWorkflow.match),
    map(() => formBulkUploadImportFileSlice.changeTab({ tabId: 'REVIEW' })),
  );
}

function validateImportFileOnSelectWorkflowEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.selectWorkflow.match),
    mergeMap(async (action) => {
      const includeAutomatedActions =
        state$.value.tenantState.tenant.module === 'compliance' &&
        state$.value.formBulkUploadImportFile.importSettings
          .createAutomatedActions;

      const validationResult = await formsQuery.getFormsBulkUploadValidate(
        client,
        {
          file: state$.value.formBulkUploadImportFile.validatedFile,
          workflow: action.payload.workflow,
          createAutomatedActions: includeAutomatedActions,
        },
      );

      return validationResult.isSuccess
        ? formBulkUploadImportFileSlice.fileValidated({
            errors: validationResult.data.errors,
            rows: validationResult.data.rows,
          })
        : formBulkUploadImportFileSlice.fileValidatedError();
    }),
  );
}

function importBulkUploadFileEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.importFile.match),
    mergeMap(async (action) => {
      const includeAutomatedActions =
        state$.value.tenantState.tenant.module === 'compliance' &&
        action.payload.createAutomatedActions;

      await formsMutation.formsBulkUploadImportFile(client, {
        file: action.payload.file,
        workflowType: action.payload.workflow,
        createAutomatedActions: includeAutomatedActions,
      });

      return formBulkUploadImportFileSlice.importFileStarted();
    }),
  );
}
