/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { GridCellDecorator } from '../../types.ts';

export const tooltipDecorator: GridCellDecorator = (args) => {
  const { datapoint, columnDefinition } = args;
  if (columnDefinition.trendMap) {
    const { trend, displayTrend } = datapoint;

    const change =
      trend === undefined || trend === 0
        ? ''
        : `${displayTrend} ` + (trend < 0 ? `decrease` : `increase`);

    const elChange = change && (
      <>
        <br />
        <Text style={styles.changeText}>{change}</Text>
      </>
    );

    const tooltipNode = (
      <>
        <Text style={styles.headingText}>{'Previous Period:'}</Text>
        <br />
        <Text style={styles.previousText}>
          {datapoint.displayPreviousValue || 'None'}
        </Text>
        {elChange}
      </>
    );

    return {
      tooltip: {
        body: tooltipNode,
      },
    };
  }
  return {};
};

const styles = {
  headingText: css({
    fontWeight: 400,
    lineHeight: 1.15,
    color: color.format(-0.5),
    fontSize: 14,
  }),
  previousText: css({
    fontWeight: 900,
    lineHeight: 1.15,
    color: color.format(-0.5),
    fontSize: 14,
  }),
  changeText: css({
    marginTop: 5,
    fontWeight: 400,
    lineHeight: 1.15,
    color: color.format(-0.5),
    fontSize: 14,
  }),
};
