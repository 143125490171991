/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CoachingSummarySectionIssue } from '@se/data/forms/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback, useMemo } from 'react';
import { useFormsSelector } from '../../../../redux/store.ts';
import { formatDueBy } from '../../../../util/util.instance.ts';
import { formatReviewActions } from '../../../Issue/util.formatReviewActions.ts';

const CONTENT_COLOR = color.format(-0.8);
const ICON_COLOR = color.format(-0.5);

export type CoachingSummaryIssueProps = {
  instanceId: string;
  issue: CoachingSummarySectionIssue;
};

export const CoachingSummaryIssue: React.FC<CoachingSummaryIssueProps> = ({
  instanceId,
  issue,
}) => {
  const dateContext = useDateContext();
  const tenant = useFormsSelector((state) => state.tenantState.tenant.id);
  const formLabel = useTenantLocale((l) => l.label.form);

  const openSessionInNewWindow = useCallback((link: string) => {
    if (link) window.open(link, '_blank');
  }, []);

  const sessionLink =
    issue.instance &&
    issue.instance.id !== instanceId &&
    urlDeepLinkUtil.generateUrlPath({
      tenant,
      module: 'compliance',
      chips: [
        {
          type: 'forms',
          value: issue.instance.id,
          label: issue.instance.name,
        },
      ],
    });

  const sessionStatus = issue.instance?.status
    ? str.humanize(issue.instance.status, false, true)
    : undefined;

  const sessionDueBy = useMemo(() => {
    const status = issue.instance?.status;
    if (
      status === 'Cancelled' ||
      status === 'Archived' ||
      status === 'Completed'
    )
      return;

    return formatDueBy({
      dueBy: issue.instance?.dueBy,
      showDistantDates: true,
      capitalized: false,
      timezone: dateContext.tenantTimezone,
    });
  }, [
    dateContext.tenantTimezone,
    issue.instance?.dueBy,
    issue.instance?.status,
  ]);

  const formStatusLabel = [sessionStatus, sessionDueBy]
    .filter(Boolean)
    .join(', ');

  const reviewActionsText = useMemo(() => {
    const reviewActions = issue.reviewActions;
    if (!reviewActions?.length) return;

    return formatReviewActions(dateContext.tenantTimezone, reviewActions);
  }, [dateContext.tenantTimezone, issue.reviewActions]);

  const elLinkedForm = issue.instance?.id !== instanceId &&
    issue.instance?.name && (
      <div>
        <div css={styles.contentInnerReview}>
          <Icons.dashboard
            fill={ICON_COLOR}
            style={styles.iconPadding}
            tooltip={`Originating Review`}
          />
          {sessionLink && (
            <>
              <Text color={CONTENT_COLOR}>{'Review:'}</Text>
              <Button onClick={() => openSessionInNewWindow(sessionLink)}>
                <div css={styles.linkButton}>
                  <Text color={COLORS.BLUE} cursor={'pointer'}>
                    {issue.instance.name}
                  </Text>
                  <Icons.launch />
                </div>
              </Button>
            </>
          )}
          <Text color={CONTENT_COLOR} ellipsis={true}>
            {formStatusLabel}
          </Text>
        </div>
        {reviewActionsText && (
          <Text
            color={CONTENT_COLOR}
            ellipsis={true}
            style={styles.actionsText}
          >
            {reviewActionsText}
          </Text>
        )}
      </div>
    );

  const formSectionNameLabel =
    issue.formSectionName && `*${issue.formSectionName}*`;

  const formQuestionText = str.removeMarkdownAndSpecialCharacters(
    issue.formQuestionText,
  );

  const formQuestionTextLabel = str.conditionalSuffix(formQuestionText);

  const questionPart = [formSectionNameLabel, formQuestionTextLabel]
    .filter(Boolean)
    .join(' -- ');

  const formAnswer = issue.answer?.displayValue ?? issue.answer?.value;
  const formAnswerSelectedLabel = formAnswer && `**${formAnswer}**`;

  const questionWithAnswer = [questionPart, formAnswerSelectedLabel]
    .filter(Boolean)
    .join(' ');

  const elFormDetails = questionWithAnswer && (
    <div css={styles.formDetailsOuter}>
      <div css={styles.contentInner}>
        <Icons.helpOutline
          fill={ICON_COLOR}
          style={styles.iconPadding}
          tooltip={`${formLabel} section, question, and response details`}
        />
        <Markdown text={questionWithAnswer} />
      </div>
      {Boolean(issue.classifications?.length) && (
        <div css={styles.classifications}>
          {issue.classifications.map((classification, index) => (
            <Markdown
              key={`${classification}-${index}`}
              color={CONTENT_COLOR}
              size={15}
              text={`- ${classification}`}
            />
          ))}
        </div>
      )}
    </div>
  );

  const notesLabel = issue.notesDetail?.label
    ? str.conditionalSuffix(issue.notesDetail.label)
    : 'Notes:';

  const elNotes = Boolean(issue.notesDetail?.value) && (
    <div css={styles.contentInner}>
      <Icons.questionAnswerIcon
        fill={ICON_COLOR}
        style={styles.iconPadding}
        tooltip={'Notes'}
      />
      <Markdown
        color={CONTENT_COLOR}
        text={`${notesLabel} ${issue.notesDetail.value}`}
      />
    </div>
  );

  return (
    <div key={issue.id} css={styles.base}>
      {elLinkedForm}
      {elFormDetails}
      {elNotes}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    borderBottom: `solid 2px ${color.format(-0.1)}`,
    ':last-child': {
      borderBottom: 'none',
    },
    padding: '10px 15px',
    margin: '-10px -15px',
    ':hover': {
      backgroundColor: color.format(-0.06),
    },
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  contentInnerReview: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  formDetailsOuter: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  actionsText: css({
    paddingLeft: 98,
  }),
  classifications: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    gap: 5,
  }),
  iconPadding: {
    paddingRight: 10,
  },
  linkButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
};
