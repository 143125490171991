/** @jsxImportSource @emotion/react */
import { formsQuery } from '@se/data/forms/query/index.ts';
import { InstanceCancelReason } from '@se/data/forms/types.ts';
import { InstanceCancelDialog } from '@seeeverything/ui.forms/src/components/InstanceCancelDialog/InstanceCancelDialog.tsx';
import {
  cancelFormInstance,
  cancelledFormInstance,
} from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import { useFormsSelector } from '@seeeverything/ui.forms/src/redux/store.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import { useTenantModule } from '@seeeverything/ui.primitives/src/hooks/useTenantModule.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch } from '../../redux/store.ts';
import { DialogOuter } from '../DialogOuter/index.ts';

export type InstanceCancelDialogContainerProps = {
  instanceId: string;
};

export const InstanceCancelDialogContainer: React.FC<
  InstanceCancelDialogContainerProps
> = ({ instanceId }) => {
  const dispatch = useShellDispatch();
  const client = useGraphQL();

  const module = useTenantModule();

  const {
    data: cancelReasons,
    isFetching: isFetchingCancelReasons,
    error,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [{ key: 'cancelReasons', module }],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await formsQuery.getFormInstanceCancelReasons(client);

      if (!response.isSuccess) throw new Error('Failed to load cancel reasons');

      return response.data;
    },
  });

  const instanceCreatedById = useFormsSelector(
    (state) => state.formInstance.instances[instanceId].createdBy.id,
  );
  const userId = useFormsSelector(
    (state) => state.tenantState.tenant.authenticatedUser.id,
  );

  const isReasonRequired =
    module === 'compliance' || instanceCreatedById !== userId;

  const [cancelReason, setCancelReason] = useState<InstanceCancelReason>();
  const [notes, setNotes] = useState<string>();
  const [showCancelReasonValidation, setShowCancelReasonValidation] =
    useState<boolean>(false);
  const [showNotesValidation, setShowNotesValidation] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleCancelReasonChange = useCallback((to: InstanceCancelReason) => {
    setShowCancelReasonValidation(!to);
    setCancelReason(to);
  }, []);

  const handleNotesChange = useCallback((to: string) => {
    setShowNotesValidation(!to);
    setNotes(to);
  }, []);

  const cancelInstance = useCallback(() => {
    if (isReasonRequired && !cancelReason && cancelReasons?.length > 0)
      setShowCancelReasonValidation(true);
    if (isReasonRequired && !notes?.length) setShowNotesValidation(true);

    const isValid =
      !isReasonRequired ||
      ((cancelReason || cancelReasons?.length === 0) && notes?.length);

    if (!isValid) return;

    setIsSaving(true);
    dispatch(cancelFormInstance(instanceId, notes, cancelReason?.id));
  }, [
    cancelReason,
    cancelReasons?.length,
    dispatch,
    instanceId,
    isReasonRequired,
    notes,
  ]);

  const actionButtons: IActionBarButton[] = [
    {
      id: 'Cancel',
      label: 'Cancel',
      onAction: () => {
        dispatch(hideModalDialog());
      },
      isEnabled: !isFetchingCancelReasons && !isSaving,
    },
    {
      dataTest: 'instanceCancelDialogContainer-okButton',
      id: 'Ok',
      label: 'Ok',
      onAction: cancelInstance,
      isEnabled: !isFetchingCancelReasons && !isSaving,
    },
  ];

  if (error) {
    dispatch(cancelledFormInstance(instanceId, true));
    return;
  }

  return (
    <DialogOuter actions={actionButtons}>
      <InstanceCancelDialog
        isReasonRequired={isReasonRequired}
        cancelReason={cancelReason}
        cancelReasons={cancelReasons}
        isLoadingCancelReasons={isFetchingCancelReasons}
        isSaving={isSaving}
        notes={notes}
        showCancelReasonValidation={showCancelReasonValidation}
        showNotesValidation={showNotesValidation}
        onCancelReasonChange={handleCancelReasonChange}
        onNotesChange={handleNotesChange}
      />
    </DialogOuter>
  );
};
