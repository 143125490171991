/** @jsxImportSource @emotion/react */
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { useDebounce } from '@seeeverything/ui.primitives/src/hooks/useDebounce.ts';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { safeParse } from '@seeeverything/ui.util/src/json/json.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo, useState } from 'react';
import { usePeopleQuery } from '../../query/usePeopleQuery.ts';
import { useTeamsQuery } from '../../query/useTeamsQuery.ts';
import {
  formInstanceReviewerChange,
  formInstanceSubjectChange,
} from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, IDropdownAnswerLine } from '../../types/types.ts';
import { DropdownAnswer } from '../DropdownAnswer/DropdownAnswer.tsx';

export interface ISubjectAnswerContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  line: IDropdownAnswerLine;
}

export const SubjectAnswerContainer: React.FC<ISubjectAnswerContainerProps> = ({
  canUpdateInstance,
  instanceId,
  line,
}) => {
  const client = useGraphQL();
  const { isPdfExport } = usePdfExportContext();
  const [inputFilter, setInputFilter] = useState('');
  const [inputDebounce] = useDebounce(inputFilter, 250);

  const updateInputFilter = useCallback(
    (to: string, reason: 'input' | 'reset') => {
      const newTo = reason === 'reset' ? '' : to;
      setInputFilter(newTo);
    },
    [],
  );

  const isSubjectTeam = line.dropdownListName === 'TEAMS';

  const subject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const reviewer = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.reviewer,
  );

  const reviewerAnswer = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.answers?.assignedTo,
  );

  const { people, isFetchingPeople } = usePeopleQuery(client, {
    inputFilter: inputDebounce,
    isEnabled: !isSubjectTeam && !isPdfExport,
  });

  const { teams, isFetchingTeams } = useTeamsQuery(client, {
    inputFilter: inputDebounce,
    isEnabled: isSubjectTeam && !isPdfExport,
  });

  const isLoading = isSubjectTeam ? isFetchingTeams : isFetchingPeople;

  const dispatch = useFormsDispatch();

  const answerId = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id]?.id;
  });

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValueSelection = useMemo(() => {
    if (!answer) return;

    const answerJson = safeParse<{ id: string }>(answer.value);
    if (!answerJson?.id) return;

    return {
      id: answerJson.id,
      value: answerJson.id,
      content: { text: answer.displayValue },
    };
  }, [answer]);

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      const subjectId = to.id.toString();
      const newSubject = isSubjectTeam
        ? {
            id: subjectId,
            name: to.value?.name,
            kind: 'Team' as const,
            path: to.value?.path,
          }
        : {
            id: subjectId,
            name: `${to.value?.firstName} ${to.value?.lastName}`.trim(),
            kind: 'Person' as const,
            positionTitle: to.value?.positionTitle,
          };

      const subjectType = isSubjectTeam ? 'Team' : 'Person';

      const toAnswer: FormAnswer = {
        id: answerId ?? uuid.generate(),
        value: JSON.stringify({ id: subjectId, kind: subjectType }),
        lineId: line.id,
        type: 'inputs',
        subType: 'dropdownAnswer',
        displayValue: to.content?.text?.toString(),
        group: line.group,
      };

      // Existing reviewer is assigned to the "subject" field, so clear it.
      if (reviewer?.id === subjectId) {
        dispatch(
          formInstanceReviewerChange(instanceId, {
            type: 'DELETE',
            fromAnswer: reviewerAnswer,
            fromReviewer: reviewer,
          }),
        );
      }

      dispatch(
        formInstanceSubjectChange(
          instanceId,
          answerId
            ? {
                type: 'UPDATE',
                fromAnswer: answer,
                fromSubject: subject,
                toAnswer,
                toSubject: newSubject,
              }
            : {
                type: 'CREATE',
                toAnswer,
                toSubject: newSubject,
                creationPreviouslyFailed: false,
              },
        ),
      );
    },
    [
      isSubjectTeam,
      answerId,
      line.id,
      line.group,
      reviewer,
      dispatch,
      instanceId,
      answer,
      subject,
      reviewerAnswer,
    ],
  );

  return (
    <DropdownAnswer
      error={error}
      floatingText={line.floatingText}
      helperText={line.helperText}
      id={line.id}
      isDropdownLoading={inputFilter !== inputDebounce || isLoading}
      isEnabled={canUpdateInstance}
      onChange={updateAnswer}
      onInputChange={updateInputFilter}
      selections={isSubjectTeam ? teams : people}
      value={answerValueSelection}
    />
  );
};
