import { formsQuery } from '@se/data/forms/query/index.ts';
import { FormActionPlanAction } from '@se/data/forms/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { formActionSlice } from '../formAction/index.ts';
import { issueSlice } from '../issue/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formActionPlanSlice from './formActionPlanSlice.ts';

export const formActionPlanEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  upsertActionEpic,
  loadActionPlanEpic,
  reloadActionOnComplianceAnswerIssueIdChangedEpic,
  resetActionPlanEpic,
);

function upsertActionEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) =>
      Boolean(
        action.payload.isSuccess &&
          action.payload.updated &&
          action.payload.source.type === 'FORM',
      ),
    ),
    map((action) =>
      formActionPlanSlice.upsertActions({
        actions: [
          {
            id: action.payload.updated.id,
            assignedTo: action.payload.updated.assignedTo,
            formInstanceId: action.payload.updated.instanceId,
            description: action.payload.updated.description,
            dueBy: action.payload.updated.dueBy,
            goalId: action.payload.updated.goalId,
            insightId: action.payload.updated.insightId,
            issueId: action.payload.updated.issueId,
            status: action.payload.updated.status,
          },
        ],
        instanceId:
          action.payload.source.type === 'FORM'
            ? action.payload.source.instanceId
            : undefined,
        timezone: state$.value.tenantState.tenant.configuration.timezone,
      }),
    ),
  );
}

function loadActionPlanEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionPlanSlice.loadActionPlan.match),
    mergeMap(async (action) => {
      const { instanceId, lineId, typeFilter, includeCategories } =
        action.payload;

      const response = await formsQuery.getFormInstanceActionPlan(client, {
        hasSections: action.payload.hasSections,
        includeCategories,
        instanceId,
        timezone: state$.value.tenantState.tenant.configuration.timezone,
        typeFilter,
      });

      if (!response.isSuccess)
        return formActionPlanSlice.loadError({ instanceId, lineId });

      return formActionPlanSlice.loadedActionPlan({
        instanceId,
        lineId,
        plan: response.data,
      });
    }),
  );
}

function reloadActionOnComplianceAnswerIssueIdChangedEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.answerChangedWithNewIssueId.match),
    filter((action) => {
      const answerId = action.payload.toAnswer.id;

      const issue = state$.value.formIssue.issues.find(
        (i) => i.answerId === answerId,
      );
      if (!issue) return false;

      const hasIssueActions = Object.values(
        state$.value.formActionPlan.actionPlan,
      ).some((actionPlan) => {
        if (!actionPlan.plan) return false;
        if (actionPlan.plan.hasSections === true) return false;

        return actionPlan.plan.actions.some((a) => Boolean(a.issueId));
      });
      return hasIssueActions;
    }),
    mergeMap(async (action) => {
      const answerId = action.payload.toAnswer.id;

      const issue = state$.value.formIssue.issues.find(
        (i) => i.answerId === answerId,
      );

      const issueActions = Object.values(
        state$.value.formActionPlan.actionPlan,
      ).flatMap((actionPlan) => {
        if (!actionPlan.plan) return;
        if (actionPlan.plan.hasSections === true) return;

        return actionPlan.plan.actions.filter((a) => a.issueId === issue.id);
      });

      return formActionPlanSlice.upsertActions({
        actions: (
          await Promise.all(
            issueActions.map(
              async (issueAction): Promise<FormActionPlanAction> => {
                const response = await formsQuery.getFormAction(
                  client,
                  issueAction.id,
                );
                if (!response.isSuccess) return;

                return {
                  id: response.data.id,
                  goalId: response.data.goalId,
                  insightId: response.data.insight?.id,
                  issueId: response.data.issue?.id,
                  formInstanceId: response.data.formInstance.id,
                  description: response.data.description,
                  dueBy: response.data.dueBy,
                  assignedTo: response.data.assignedTo,
                  status: response.data.status,
                };
              },
            ),
          )
        ).filter(Boolean),
        instanceId: action.payload.instanceId,
        timezone: state$.value.tenantState.tenant.configuration.timezone,
      });
    }),
  );
}

function resetActionPlanEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    ofType(
      'ui.forms/instance/SERVER_LOADED',
      'ui.forms/instance/ANSWER/SUBJECT_CHANGED',
    ),
    map(() => formActionPlanSlice.reset()),
  );
}
