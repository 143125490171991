/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DashboardComment } from '@se/data/dashboards/query/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { useCallback } from 'react';
import { useDashboardsSelector } from '../../../redux/store.ts';
import { CommentsDescription } from './CommentsDescription.tsx';

export type CommentProps = {
  comment: DashboardComment;
  entityType: 'person' | 'team';
  onFormClicked: (instanceId: string, instanceLabel: string) => void;
};

export const Comment: React.FC<CommentProps> = ({
  comment,
  entityType,
  onFormClicked,
}) => {
  const locale = useDashboardsSelector(
    (state) => state.tenantState.tenant.locale,
  );

  const handleFormClicked = useCallback(() => {
    if (!comment.form) return;
    onFormClicked(comment.form.id, comment.form.name);
  }, [comment.form, onFormClicked]);

  return (
    <div key={`comment-${comment.id}`} css={styles.base}>
      <Button
        isEnabled={Boolean(comment.form)}
        onClick={handleFormClicked}
        style={{ padding: 5, margin: -5 }}
      >
        <div css={styles.button}>
          {Boolean(comment.form) && <Icons.launch size={16} />}
          <Text
            color={comment.form ? COLORS.BLUE : color.format(-1)}
            size={14}
            weight={500}
            cursor={'inherit'}
          >
            {commentText(comment, locale, entityType)}
          </Text>
        </div>
      </Button>
      <CommentsDescription description={comment.description} />
    </div>
  );
};

const commentText = (
  comment: DashboardComment,
  locale: TenantLocale,
  entityType: 'person' | 'team',
) => {
  const commentParts = new Array<string>();

  if (comment.form.name) {
    commentParts.push(comment.form.name);
  }

  if (comment.assignedTo) {
    commentParts.push(`${locale.label.formAssignedTo}: ${comment.assignedTo}`);
  }

  if (comment.subject && entityType !== 'person') {
    commentParts.push(`${locale.label.formSubject}: ${comment.subject}`);
  }

  if (comment.occurredAt) {
    commentParts.push(comment.occurredAt);
  }

  return commentParts.join(' | ');
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    borderBottom: `1px solid ${color.format(-0.1)}`,
    gap: 5,
  }),
  button: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  }),
};
