/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Accordion } from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import {
  useDateContext,
  useMomentTenantTimezone,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { getLocale } from '@seeeverything/ui.util/src/redux/tenant/util.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback, useMemo, useState } from 'react';
import { formActionSlice } from '../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { formatDueBy } from '../../util/util.instance.ts';
import { Action } from '../FormActionPlan/components/Action.tsx';
import { AddAction } from '../FormActionPlan/components/AddAction.tsx';
import { formatReviewActions } from './util.formatReviewActions.ts';

const COACHING_TITLE = {
  MANDATORY: 'Coaching Required:',
  RECOMMENDED: 'Coaching Recommended:',
  NONE: 'Issue Identified:',
};

const CONTENT_COLOR = color.format(-0.8);
const ICON_COLOR = color.format(-0.5);

export type IssueContainerProps = {
  id: string;
  enableActions: boolean;
  showReviewLink?: boolean;
  defaultExpanded?: boolean;
  instanceId: string;
  showCreatedActions: boolean;
};

export const IssueContainer: React.FC<IssueContainerProps> = ({
  id,
  enableActions,
  showReviewLink,
  defaultExpanded,
  instanceId,
  showCreatedActions,
}) => {
  const dispatch = useFormsDispatch();
  const momentTz = useMomentTenantTimezone();

  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  const issue = useFormsSelector((state) =>
    state.formIssue.issues.find((formIssue) => formIssue.id === id),
  );

  const configuration = useFormsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const complianceLocale = useMemo(
    () => getLocale(configuration, 'compliance'),
    [configuration],
  );

  const complianceFormLabel = complianceLocale.label.form;

  const dateContext = useDateContext();

  const reviewActionsText = useMemo(() => {
    const reviewActions = issue.instance?.reviewActions;
    if (!reviewActions?.length) return;

    return formatReviewActions(dateContext.tenantTimezone, reviewActions);
  }, [dateContext.tenantTimezone, issue.instance?.reviewActions]);

  const reviewDueDate = useMemo(() => {
    if (!showReviewLink) return undefined;

    const dueDate = issue.instance?.dueDate;
    if (!dueDate) return;

    return formatDueBy({
      dueBy: dueDate,
      showDistantDates: true,
      capitalized: false,
      timezone: dateContext.tenantTimezone,
    });
  }, [dateContext.tenantTimezone, issue.instance?.dueDate, showReviewLink]);

  const actionPlan = useFormsSelector(
    (state) => state.formActionPlan.actionPlan.issueCoaching,
  );

  const issueActions = useMemo(() => {
    if (!actionPlan?.plan) return [];
    if (actionPlan.isLoading) return [];
    if (actionPlan.plan.hasSections === true) return [];

    return actionPlan.plan.actions.filter(
      (a) => a.issueId === id && a.status !== 'Cancelled',
    );
  }, [actionPlan, id]);

  const reviewName = showReviewLink ? issue.instance?.name : undefined;
  const reviewStatus =
    showReviewLink && issue.instance?.status
      ? str.humanize(issue.instance.status, false, true)
      : undefined;

  const tenant = useFormsSelector((state) => state.tenantState.tenant.id);
  const tenantHasCompliance = configuration.modules?.compliance?.isEnabled;

  const reviewLink = useMemo(() => {
    if (!showReviewLink) return;
    if (!tenantHasCompliance) return;
    if (!issue.instance) return;

    return urlDeepLinkUtil.generateUrlPath({
      tenant,
      module: 'compliance',
      chips: [
        {
          type: 'forms',
          value: issue.instance.id,
          label: issue.instance.name,
        },
      ],
    });
  }, [issue.instance, showReviewLink, tenant, tenantHasCompliance]);

  const issueActionLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.issueAction,
  );

  const openReviewInNewWindow = useCallback(() => {
    if (reviewLink) window.open(reviewLink, '_blank');
  }, [reviewLink]);

  const handleViewAction = useCallback(
    (actionId: string) => {
      dispatch(
        formActionSlice.load({
          actionId,
          source: { type: 'FORM', instanceId },
        }),
      );
    },
    [dispatch, instanceId],
  );

  const coachingRequirementColor =
    COLORS.COACHING_REQUIREMENT[issue.coaching.requirement];

  const coachingRequirementText = COACHING_TITLE[issue.coaching.requirement];

  const formSectionNameLabel =
    issue.formSectionName && `*${issue.formSectionName}*`;

  const formQuestionTextLabel = str.conditionalSuffix(issue.formQuestionText);

  const questionPart = [formSectionNameLabel, formQuestionTextLabel]
    .filter(Boolean)
    .join(' -- ');

  const formAnswerSelectedLabel =
    issue.formAnswerSelected && `**${issue.formAnswerSelected}**`;

  const questionWithAnswer = [questionPart, formAnswerSelectedLabel]
    .filter(Boolean)
    .join(' ');

  const elFormDetails = questionWithAnswer && (
    <div css={styles.formDetailsOuter}>
      <div css={styles.contentInner}>
        <Icons.helpOutline
          fill={ICON_COLOR}
          style={styles.iconPadding}
          tooltip={`${complianceFormLabel} section, question, and response details`}
        />
        <Markdown text={questionWithAnswer} />
      </div>
      {Boolean(issue.classifications?.length) && (
        <div css={styles.classifications}>
          {issue.classifications.map((classification, index) => (
            <Markdown
              key={`${classification.value}-${index}`}
              color={CONTENT_COLOR}
              size={15}
              text={`- ${classification.value}`}
            />
          ))}
        </div>
      )}
    </div>
  );

  const formStatusLabel = [reviewStatus, reviewDueDate]
    .filter(Boolean)
    .join(', ');

  const elLinkedForm = reviewName && (
    <div>
      <div css={styles.contentInnerReview}>
        <Icons.dashboard
          fill={ICON_COLOR}
          style={styles.iconPadding}
          tooltip={'Originating Review'}
        />
        <Text color={CONTENT_COLOR}>{'Review:'}</Text>
        <Button onClick={openReviewInNewWindow}>
          <div css={styles.linkButton}>
            <Text color={COLORS.BLUE} cursor={'pointer'}>
              {reviewName}
            </Text>
            <Icons.launch />
          </div>
        </Button>
        <Text color={CONTENT_COLOR} ellipsis={true}>
          {formStatusLabel}
        </Text>
      </div>
      {reviewActionsText && (
        <Text color={CONTENT_COLOR} ellipsis={true} style={styles.actionsText}>
          {reviewActionsText}
        </Text>
      )}
    </div>
  );

  const elSummaryContent = (
    <div css={styles.contentInner}>
      <Icons.localLibrary
        fill={coachingRequirementColor}
        style={styles.iconPadding}
        tooltip={'Issue'}
        cursor={'pointer'}
      />
      <div css={styles.summaryText}>
        <Text
          color={coachingRequirementColor}
          ellipsis={true}
          cursor={'pointer'}
        >
          {coachingRequirementText}
        </Text>
        <Text
          color={color.format(-0.8)}
          ellipsis={true}
          style={styles.issueLabel}
          cursor={'pointer'}
          weight={700}
        >
          {issue.label}
        </Text>
      </div>
      {issueActions.length > 0 && showCreatedActions && (
        <Tooltip
          arrow={true}
          interactive={true}
          title={
            <div
              css={styles.createdActionTooltip}
              onClick={(e) => e.stopPropagation()}
            >
              {issueActions.map((issueAction, index) => {
                const subText =
                  issueAction.status === 'Completed' ||
                  issueAction.status === 'Cancelled'
                    ? `Assigned to ${issueAction.assignedTo.name} (${issueAction.status}):`
                    : `Assigned to ${issueAction.assignedTo.name} (Due ${momentTz(issueAction.dueBy).format('D MMM YY')}):`;

                return (
                  <div
                    key={index}
                    onClick={() => handleViewAction(issueAction.id)}
                    css={styles.viewActionTooltip}
                  >
                    <Text size={15} color={'white'}>
                      {subText}
                    </Text>
                    <Text
                      size={13}
                      color={'white'}
                      tooltip={'Click to View'}
                      style={styles.viewActionTooltipText}
                    >{`• ${str.removeMarkdownAndSpecialCharacters(issueAction.description)}`}</Text>
                  </div>
                );
              })}
            </div>
          }
        >
          <div css={styles.actionsCreatedCount}>
            <Icons.actionList fill={'white'} />
            <Text
              size={14}
              color={'white'}
              cursor={'pointer'}
              ellipsis={true}
            >{`${issueActions.length} ${str.plural(issueActionLabel, issueActions.length)} Created`}</Text>
          </div>
        </Tooltip>
      )}
    </div>
  );

  const elNotes = issue.notes && (
    <div css={styles.contentInner}>
      <Icons.questionAnswerIcon
        fill={ICON_COLOR}
        style={styles.iconPadding}
        tooltip={'Reviewer Notes'}
      />
      <Markdown
        color={CONTENT_COLOR}
        text={
          issue.notesDefinition
            ? `${issue.notesDefinition.label}: ${issue.notes}`
            : issue.notes
        }
      />
    </div>
  );

  const elDetailsContent = (
    <div css={styles.contentOuter}>
      {elLinkedForm}
      {elFormDetails}
      {elNotes}
    </div>
  );

  return (
    <div css={styles.contentContainer}>
      {issue.isLoading && (
        <>
          <div css={styles.issueLoading}>
            <Spinner center={true} />
          </div>
          <div css={styles.issueLoadingMask} />
        </>
      )}
      <Accordion
        id={`issue-${id}`}
        elevation={0}
        isExpanded={isExpanded}
        onChange={(e) => setIsExpanded(e.isExpanded)}
        hideDividers={true}
        isDefaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          style={styles.accordionBase}
          expandedStyle={styles.accordionExpanded}
          contentStyle={styles.accordionContent}
          expandIcon={<Icons.arrowDropDown size={26} />}
        >
          <div css={styles.summaryContent}>
            {elSummaryContent}
            <Text
              color={COLORS.BLUE}
              size={14}
              cursor={'pointer'}
              style={styles.summaryShowHideText}
            >
              {isExpanded ? 'Hide' : 'Show'}
            </Text>
          </div>
        </AccordionSummary>
        <AccordionDetails style={styles.detailsBase}>
          {elDetailsContent}
        </AccordionDetails>
      </Accordion>
      {enableActions && (
        <div css={styles.actionsOuter}>
          {issueActions?.map((action) => (
            <Action
              key={`CoachingIssueAction-${action.id}`}
              action={action}
              source={{ type: 'FORM', instanceId }}
            />
          ))}
          <div css={styles.addActionButton}>
            <span css={styles.itemTrack} />
            <AddAction
              message={
                issueActions?.length > 0
                  ? `Add another ${issueActionLabel.toLowerCase()}.`
                  : `Add a ${issueActionLabel.toLowerCase()}.`
              }
              style={styles.addAction}
              instanceId={instanceId}
              issueId={id}
              existingActions={issueActions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  accordionBase: {
    backgroundColor: color.format(-0.05),
    border: `1px solid ${color.format(-0.1)}`,
    borderRadius: 4,
    '&$expanded > $expandIcon': {
      transform: 'rotate(-180deg)',
    },
    '&$expanded > $expandIcon span svg': {
      fill: COLORS.BLUE,
    },
    '&:hover': {
      background: color.format(-0.1),
    },
    overflow: 'hidden',
  },
  accordionExpanded: {
    backgroundColor: color.format(-0.05),
    minHeight: 48,
    color: color.format(-0.4),
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      background: color.format(-0.1),
    },
  },
  accordionContent: {
    color: COLORS.BLUE,
    display: 'inline-block' as const,
    textAlign: 'end' as const,
    '&$expanded': {
      margin: '12px 0',
      color: COLORS.BLUE,
    },
  },
  summaryText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'start',
  }),
  contentContainer: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  }),
  contentOuter: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  contentInnerReview: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  formDetailsOuter: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  actionsText: css({
    paddingLeft: 98,
  }),
  classifications: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    gap: 5,
  }),
  detailsBase: {
    background: '#fefefe',
    border: `1px solid #cecece`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    padding: '12px 16px',
  },
  iconPadding: {
    paddingRight: 10,
  },
  actionsOuter: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 0 18px',
  }),
  addActionButton: css({
    display: 'flex',
    gap: 12,
  }),
  itemTrack: css({
    marginLeft: 10,
    height: 22,
    minWidth: 22,
    borderLeft: `solid 2px ${color.format(-0.2)}`,
    borderBottom: `solid 2px ${color.format(-0.2)}`,
  }),
  issueLoading: css({
    position: 'absolute',
    inset: 0,
    zIndex: 2,
  }),
  issueLoadingMask: css({
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    backgroundColor: color.format(0.5),
  }),
  linkButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  summaryContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
  summaryShowHideText: css({
    alignSelf: 'center',
    padding: '0 5px 0 20px',
  }),
  issueLabel: css({
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
  }),
  addAction: css({
    margin: '5px 0 0 1px',
  }),
  actionsCreatedCount: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginLeft: 10,
    backgroundColor: color.format(-0.3),
    borderRadius: 5,
    border: `solid 1px ${color.format(-0.1)}`,
    padding: '3px 9px 3px 8px',
  }),
  createdActionTooltip: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  }),
  viewActionTooltip: css({
    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  viewActionTooltipText: css({
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    textWrap: 'wrap',
    overflow: 'hidden',
    display: '-webkit-box',
    whiteSpace: 'wrap',
  }),
};
