/** @jsxImportSource @emotion/react */
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import {
  useDateContext,
  useMomentTenantTimezone,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { useCallback, useEffect, useState } from 'react';
import { interval } from 'rxjs';

export const TenantDateTime = () => {
  const momentTenantTimezone = useMomentTenantTimezone();
  const dateContext = useDateContext();

  const getValues = useCallback(() => {
    const momentTz = momentTenantTimezone();
    const momentLocal = momentTenantTimezone(undefined, undefined, true);

    return {
      tenantCurrentTime: momentTz.format('DD MMMM YYYY, h:mm:ss a'),
      browserTime: momentLocal.format('DD MMMM YYYY, h:mm:ss a'),
      tenantIsoTime: momentTz.toISOString(),
      tenantDatePickerTime: momentTz.hour(2).minute(0).second(0).toISOString(),
      tenantStartTime: momentTz.startOf('day').toISOString(),
      tenantEndTime: momentTz.endOf('day').toISOString(),
    };
  }, [momentTenantTimezone]);

  const [values, setValues] = useState(getValues());

  useEffect(() => {
    const subscription = interval(1000).subscribe(() => {
      setValues(getValues());
    });

    return () => subscription.unsubscribe();
  }, [getValues]);

  return (
    <Markdown
      text={`
#### ${dateContext.tenantTimezone}
- ${values.tenantCurrentTime} (Current Time)
- ${values.browserTime} (Browser Time - ${Intl.DateTimeFormat().resolvedOptions().timeZone})
- ${values.tenantIsoTime} (ISO 8601)
- ${values.tenantDatePickerTime} (Date Picker)
- ${values.tenantStartTime} (Start of day)
- ${values.tenantEndTime} (End of day)
      `}
    />
  );
};
