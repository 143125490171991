/** @jsxImportSource @emotion/react */
import {
  DateContext,
  IDateContext,
} from '@seeeverything/ui.util/src/react-context/dateContext.ts';
import { GlobalState } from '@seeeverything/ui.util/src/redux/types.ts';
import momentTz from 'moment-timezone';
import { useCallback, useContext } from 'react';

const DEFAULT_DATE_CONTEXT: IDateContext = {
  format: 'MM/dd/yyyy',
  tenantTimezone: momentTz.tz.guess(),
};

export const useDateContext = (): IDateContext => {
  const configDateContext = useContext(DateContext) ?? DEFAULT_DATE_CONTEXT;

  return {
    format: configDateContext.format ?? DEFAULT_DATE_CONTEXT.format,
    tenantTimezone:
      configDateContext.tenantTimezone ?? DEFAULT_DATE_CONTEXT.tenantTimezone,
  };
};

export const useMomentTenantTimezone = () => {
  const dateContext = useDateContext();

  return useCallback(
    (
      input?: momentTz.MomentInput,
      format?: momentTz.MomentFormatSpecification,
      keepLocalTime?: boolean,
    ) => momentTz(input, format).tz(dateContext.tenantTimezone, keepLocalTime),
    [dateContext.tenantTimezone],
  );
};

export const todayInTenantTz = (tenantTimezone = 'Etc/UTC'): string =>
  momentTz().tz(tenantTimezone, false).startOf('day').toISOString();

export const tomorrowInTenantTz = (tenantTimezone = 'Etc/UTC'): string =>
  momentTz()
    .tz(tenantTimezone, false)
    .add(1, 'day')
    .startOf('day')
    .toISOString();

export const dayAfterDate = (date: string): string =>
  momentTz(date).add(1, 'day').toISOString();

export const momentTzFromState = (state: GlobalState) => {
  const tenantTimezone =
    state.tenantState.tenant.configuration.timezone ?? 'Etc/UTC';

  return (
    input?: momentTz.MomentInput,
    format?: momentTz.MomentFormatSpecification,
  ) => momentTz(input, format).tz(tenantTimezone);
};
