/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useMemo } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { useDashboardsSelector } from '../redux/store.ts';

export interface IFactoryDashboardSectionContainerProps {
  id: string;
  parentId?: string;
}

export const FactoryDashboardSectionContainer: React.FC<
  IFactoryDashboardSectionContainerProps
> = ({ id, parentId }) => {
  const [forwardedRef] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const scrollToDataId = useDashboardsSelector(
    (state) => state.scroll.containers.scrollPanel?.scrollToDataId,
  );

  const settings = useDashboardsSelector(
    (state) => state.dashboardsV2.settings,
  );
  const defaultDateFilter = useDashboardsSelector(
    (state) => state.dashboardsV2.HEADER?.defaultDateFilter,
  );

  const dateFilters = useDashboardsSelector(
    (state) => state.dashboardsV2.HEADER?.dateFilters,
  );

  const showBullet = useDashboardsSelector(
    (state) => state.dashboardsV2.SECTION[id]?.showBullet,
  );

  const title = useDashboardsSelector(
    (state) => state.dashboardsV2.SECTION[id]?.title,
  );

  const dateFilterLabel = useMemo(() => {
    if (!settings) return;
    if (!defaultDateFilter) return;

    const selectedDateFilterId = settings.selectedDateFilterId;

    const selection =
      (selectedDateFilterId &&
        dateFilters?.find((filter) => filter.id === selectedDateFilterId)) ??
      defaultDateFilter;

    return selection.toLabel({
      financialYearStartMonth: settings.financialYearStartMonth,
      customOptions: settings.selectedCustomDates,
    });
  }, [dateFilters, defaultDateFilter, settings]);

  const computedStyles = {
    bullet: css({
      width: 10,
      marginRight: 23,
      backgroundColor: color.format(-0.4),
      height: `${dateFilterLabel ? 65 : 50}px`,
    }),
  };

  return (
    <div
      id={id}
      css={
        scrollToDataId === id
          ? [styles.base, CommonStyles.ScrollPulse]
          : [styles.base]
      }
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      {showBullet && <div css={computedStyles.bullet} />}
      <div css={styles.textOuter}>
        <Text
          size={20}
          weight={700}
          uppercase={true}
          color={color.format(-0.6)}
        >
          {title}
        </Text>
        {dateFilterLabel && (
          <Text
            size={13}
            weight={400}
            uppercase={true}
            color={color.format(-0.6)}
          >
            {dateFilterLabel}
          </Text>
        )}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 40,
    overflow: 'hidden',
  }),
  textOuter: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};
