/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CoachingSummarySectionIssue } from '@se/data/forms/types.ts';
import { Accordion } from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useState } from 'react';
import { formActionSlice } from '../../../../redux/formAction/index.ts';
import { useFormsDispatch } from '../../../../redux/store.ts';
import { CoachingSummaryIssue } from './CoachingSummaryIssue.tsx';

export type CoachingSummaryIssueGroupProps = {
  instanceId: string;
  label: string;
  issues: CoachingSummarySectionIssue[];
  sectionExpanded: boolean;
};

export const CoachingSummaryIssueGroup: React.FC<
  CoachingSummaryIssueGroupProps
> = ({ instanceId, label, issues, sectionExpanded }) => {
  const dispatch = useFormsDispatch();
  const momentTz = useMomentTenantTimezone();

  const [isExpanded, setIsExpanded] = useState(sectionExpanded);

  const issueActionLabel = useTenantLocale((l) => l.label.issueAction);

  const issueCoachingRequired = issues[0].issueCoachingRequired;
  const issueColor = getIssueColor(issueCoachingRequired);
  const issueCoachingRequirementLabel = getIssueCoachingRequirementLabel(
    issueCoachingRequired,
  );

  const handleViewAction = useCallback(
    (actionId: string) => {
      dispatch(
        formActionSlice.load({
          actionId,
          source: { type: 'FORM', instanceId },
        }),
      );
    },
    [dispatch, instanceId],
  );

  const actions = issues.flatMap((issue) => issue.actions);

  const elSummaryContent = (
    <div css={styles.contentInner}>
      <Icons.localLibrary
        fill={issueColor}
        style={styles.iconPadding}
        tooltip={'Issue'}
        cursor={'pointer'}
      />
      <div css={styles.summaryText}>
        <Text color={issueColor} ellipsis={true} cursor={'pointer'}>
          {issueCoachingRequirementLabel}
        </Text>
        <Text
          color={color.format(-0.8)}
          ellipsis={true}
          style={styles.issueLabel}
          cursor={'pointer'}
          weight={700}
        >
          {label}
        </Text>
      </div>
      {actions.length > 0 && (
        <Tooltip
          arrow={true}
          interactive={true}
          title={
            <div
              css={styles.createdActionTooltip}
              onClick={(e) => e.stopPropagation()}
            >
              {actions.map((action, index) => {
                const subText =
                  action.status === 'Completed' || action.status === 'Cancelled'
                    ? `Assigned to ${action.assignedTo.name} (${action.status}):`
                    : `Assigned to ${action.assignedTo.name} (Due ${momentTz(action.dueBy).format('D MMM YY')}):`;

                return (
                  <div
                    key={index}
                    onClick={() => handleViewAction(action.id)}
                    css={styles.viewActionTooltip}
                  >
                    <Text size={15} color={'white'}>
                      {subText}
                    </Text>
                    <Text
                      size={13}
                      color={'white'}
                      tooltip={'Click to View'}
                      style={styles.viewActionTooltipText}
                    >{`• ${str.removeMarkdownAndSpecialCharacters(action.description)}`}</Text>
                  </div>
                );
              })}
            </div>
          }
        >
          <div css={styles.actionsCreatedCount}>
            <Icons.actionList fill={'white'} />
            <Text
              size={14}
              color={'white'}
              cursor={'pointer'}
              ellipsis={true}
            >{`${actions.length} ${str.plural(issueActionLabel, actions.length)} Created`}</Text>
          </div>
        </Tooltip>
      )}
    </div>
  );

  return (
    <Accordion
      id={`issue-${label}`}
      elevation={0}
      isExpanded={isExpanded}
      onChange={(e) => setIsExpanded(e.isExpanded)}
      hideDividers={true}
      isDefaultExpanded={true}
    >
      <AccordionSummary
        style={styles.accordionBase}
        expandedStyle={styles.accordionExpanded}
        contentStyle={styles.accordionContent}
        expandIcon={<Icons.arrowDropDown size={26} />}
      >
        <div css={styles.summaryContent}>
          {elSummaryContent}
          <Text
            color={COLORS.BLUE}
            size={14}
            cursor={'pointer'}
            style={styles.summaryShowHideText}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </Text>
        </div>
      </AccordionSummary>
      <AccordionDetails style={styles.detailsBase}>
        <div css={styles.issues}>
          {issues.map((issue) => (
            <CoachingSummaryIssue
              key={issue.id}
              instanceId={instanceId}
              issue={issue}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const getIssueColor = (issueCoachingRequired?: boolean) => {
  if (issueCoachingRequired === true)
    return COLORS.COACHING_REQUIREMENT.MANDATORY;
  if (issueCoachingRequired === false)
    return COLORS.COACHING_REQUIREMENT.RECOMMENDED;
  return COLORS.COACHING_REQUIREMENT.NONE;
};

const getIssueCoachingRequirementLabel = (issueCoachingRequired?: boolean) => {
  if (issueCoachingRequired === true) return 'Coaching Required:';
  if (issueCoachingRequired === false) return 'Coaching Recommended:';
  return 'Issue Identified:';
};

const styles = {
  accordionBase: {
    backgroundColor: color.format(-0.05),
    border: `1px solid ${color.format(-0.1)}`,
    borderRadius: 4,
    '&$expanded > $expandIcon': {
      transform: 'rotate(-180deg)',
    },
    '&$expanded > $expandIcon span svg': {
      fill: COLORS.BLUE,
    },
    '&:hover': {
      background: color.format(-0.1),
    },
    overflow: 'hidden',
  },
  accordionExpanded: {
    backgroundColor: color.format(-0.05),
    minHeight: 48,
    color: color.format(-0.4),
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      background: color.format(-0.1),
    },
  },
  accordionContent: {
    color: COLORS.BLUE,
    display: 'inline-block' as const,
    textAlign: 'end' as const,
    '&$expanded': {
      margin: '12px 0',
      color: COLORS.BLUE,
    },
  },
  summaryText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'start',
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  detailsBase: {
    background: '#fefefe',
    border: `1px solid #cecece`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    padding: '12px 16px',
  },
  iconPadding: {
    paddingRight: 10,
  },
  summaryContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
  summaryShowHideText: css({
    alignSelf: 'center',
    padding: '0 5px 0 20px',
  }),
  issueLabel: css({
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
  }),
  actionsCreatedCount: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginLeft: 10,
    backgroundColor: color.format(-0.3),
    borderRadius: 5,
    border: `solid 1px ${color.format(-0.1)}`,
    padding: '3px 9px 3px 8px',
  }),
  createdActionTooltip: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  }),
  viewActionTooltip: css({
    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  viewActionTooltipText: css({
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    textWrap: 'wrap',
    overflow: 'hidden',
    display: '-webkit-box',
    whiteSpace: 'wrap',
  }),
  issues: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
};
