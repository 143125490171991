/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback, useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';

export const ActionTabInsightDetails: React.FC = () => {
  const localeLabels = useTenantLocale((locale) => ({
    formLabel: locale.label.form,
    formSubject: locale.label.formSubject,
  }));

  const existingInsight = useFormsSelector(
    (state) => state.formActionV2.insight,
  );

  const draftInsight = useFormsSelector((state) =>
    state.formInsight.insights.find(
      (i) => i.id === state.formActionV2.draftChanges.action.insightId,
    ),
  );

  const insight = draftInsight ?? existingInsight;

  const tenant = useFormsSelector((state) => state.tenantState.tenant.id);

  const openInstanceUrl = useCallback(() => {
    if (draftInsight) return;
    if (!existingInsight) return;
    if (!existingInsight.instance) return;
    if (!tenant) return;

    const url = urlDeepLinkUtil.generateUrlPath({
      tenant,
      module: 'coaching',
      chips: [
        {
          type: 'forms',
          value: existingInsight.instance.id,
          label: existingInsight.instance.template.name,
        },
      ],
    });
    window.open(url, '_blank');
  }, [draftInsight, existingInsight, tenant]);

  const instanceId = useFormsSelector((state) =>
    state.formActionV2.source?.type === 'FORM'
      ? state.formActionV2.source.instanceId
      : undefined,
  );

  const elInstanceDetails = !draftInsight &&
    existingInsight &&
    existingInsight.instance.id !== instanceId && (
      <div css={styles.contentGroup}>
        <div css={styles.contentRow}>
          <Icons.dashboard
            fill={color.format(-0.5)}
            tooltip={localeLabels.formLabel}
          />
          <div css={styles.headerText}>
            <Text>{`${localeLabels.formLabel}: `}</Text>
            <Button onClick={openInstanceUrl}>
              <div css={styles.linkButton}>
                <Text color={COLORS.BLUE} cursor={'pointer'}>
                  {existingInsight.instance.template.name}
                </Text>
                <Icons.launch />
              </div>
            </Button>
          </div>
        </div>
        <div css={styles.sessionFields}>
          {existingInsight.instance.subject && (
            <div css={styles.sessionField}>
              {existingInsight.instance.subject.type === 'Person' ? (
                <Icons.face
                  fill={color.format(-0.5)}
                  tooltip={`${localeLabels.formLabel} ${localeLabels.formSubject}`}
                />
              ) : (
                <Icons.group
                  fill={color.format(-0.5)}
                  tooltip={`${localeLabels.formLabel} ${localeLabels.formSubject}`}
                />
              )}
              <Text>{existingInsight.instance.subject.name}</Text>
            </div>
          )}
          <div css={styles.sessionField}>
            <Icons.info
              fill={color.format(-0.5)}
              tooltip={`${localeLabels.formLabel} Status`}
            />
            <Text>{existingInsight.instance.statusDisplayValue}</Text>
          </div>
          {existingInsight.instance.datesDisplayValue && (
            <div css={styles.sessionField}>
              <Icons.notifications
                fill={color.format(-0.5)}
                tooltip={`${localeLabels.formLabel} Dates`}
              />
              <Text>{existingInsight.instance.datesDisplayValue}</Text>
            </div>
          )}
        </div>
      </div>
    );

  const elInsightTitle = insight?.label && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.localLibrary fill={COLORS.PURPLE} tooltip={'Learning Area'} />
        <div css={styles.headerText}>
          <Text color={COLORS.PURPLE}>{'Learning Area: '}</Text>
          <Text>{insight.label}</Text>
        </div>
      </div>
    </div>
  );

  const questionGroupText = useMemo(() => {
    if (!insight) return;

    const sectionName = insight.formSectionName
      ? `*${str.titleCase(
          str.removeMarkdownAndSpecialCharacters(insight.formSectionName),
        )}*`
      : '';

    const questionText = insight.formQuestionText
      ? str.removeMarkdownAndSpecialCharacters(insight.formQuestionText)
      : '';

    const questionTextLabel = str.conditionalSuffix(questionText);

    const answer = draftInsight
      ? draftInsight.formAnswerSelected
      : (existingInsight?.answer?.displayValue ??
        existingInsight?.answer?.value);

    const answerText = answer ? `**${answer}**` : '';

    return [
      [sectionName, questionTextLabel].filter(Boolean).join(' -- '),
      answerText,
    ]
      .filter(Boolean)
      .join(' ');
  }, [draftInsight, existingInsight, insight]);

  const elFormQuestion = (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.helpOutline
          fill={color.format(-0.5)}
          tooltip={`${localeLabels.formLabel} section, question, and response details`}
        />
        <div css={styles.headerText}>
          <Markdown text={questionGroupText} />
        </div>
      </div>
      {insight.classificationValues.map((classification, index) => (
        <Markdown
          key={`${insight.id}-${index}`}
          style={styles.classificationText}
          text={`- ${classification}`}
        />
      ))}
    </div>
  );

  const insightNotesLabel = draftInsight
    ? draftInsight.notesDefinition?.label
    : existingInsight?.notesLabel;

  const elNotes = insight.notesValue && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.questionAnswerIcon
          fill={color.format(-0.5)}
          tooltip={'Notes'}
          cursor={'pointer'}
        />
        <div css={styles.headerText}>
          <Markdown
            text={`${str.conditionalSuffix(insightNotesLabel ?? 'Notes:')} *${insight.notesValue}*`}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elInsightTitle}
      {elInstanceDetails}
      {elFormQuestion}
      {elNotes}
    </div>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: 12,
  }),
  contentGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    ':hover': {
      backgroundColor: color.format(-0.06),
      padding: 10,
      margin: -10,
    },
  }),
  contentRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
  }),
  headerText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  sessionFields: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '5%',
    paddingLeft: 40,
    alignItems: 'center',
  }),
  sessionField: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  linkButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  classificationText: css({
    paddingLeft: 25,
  }),
};
