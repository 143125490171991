import { formatDateString } from '@se/data/dashboards/query/util.ts';
import { exportToPdf } from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import {
  ReduxFormInstanceExportPdf,
  ReduxFormInstancePdfExportError,
  ReduxFormInstanceServerCancelled,
} from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { InstanceCancelDialogContainer } from '@seeeverything/ui.shell/src/components/InstanceCancelDialog/InstanceCancelDialogContainer.tsx';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import {
  removeSheet,
  showStatusBar,
} from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { SheetToolbarDropdownClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map, of } from 'rxjs';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';
import { addAuditLogChip } from '../../config.sheets/query.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  addAuditLogChipEpic,
  cancelFormInstanceEpic,
  exportInstancePdfEpic,
  removeSheetWhenFormCancelled,
  showStatusOnFormInstancePdfExportError,
  showStatusOnFormInstancePdfExportRequested,
);

/**
 * Remove the current sheet when a form instance is cancelled, if the user is
 * currently viewing that instance.
 */
function removeSheetWhenFormCancelled(
  action$: Observable<ReduxFormInstanceServerCancelled>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_CANCELLED'),
    filter(
      ({ payload }) =>
        state$.value.sheets.current.type === 'FORM' &&
        payload.instanceId === state$.value.sheets.current.props.instanceId,
    ),
    map(({ payload }) => {
      if (payload.error)
        return from([
          hideModalDialog(),
          showStatusBar(
            'ERROR',
            `Could not withdraw this ${state$.value.tenantState.tenant.locale.label.form.toLowerCase()}. Please try again later.`,
          ),
        ]);

      const instanceName =
        state$.value.formInstance.instances[payload.instanceId].template.name;

      return from([
        hideModalDialog(),
        showStatusBar('SUCCESS', `${instanceName} was successfully withdrawn.`),
        removeSheet(),
      ]);
    }),
    concatAll(),
  );
}

function addAuditLogChipEpic(
  action$: Observable<SheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(({ payload }) => payload.itemId === 'FORM_AUDIT_LOG'),
    map(() => addAuditLogChip()),
  );
}

function cancelFormInstanceEpic(
  action$: Observable<SheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(({ payload }) => payload.itemId === 'SHEET_OPTION_CANCEL_FORM'),
    map((action) =>
      of(
        showModalDialog({
          content: (
            <InstanceCancelDialogContainer
              instanceId={action.payload.sheet.props.instanceId}
            />
          ),
          width: 750,
        }),
      ),
    ),
    concatAll(),
  );
}

function exportInstancePdfEpic(
  action$: Observable<SheetToolbarDropdownClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      ({ payload }) =>
        payload.itemId === 'FORM_EXPORT_INSTANCE_TO_PDF' &&
        Boolean(Object.values(state$.value.formInstance.instances).length),
    ),
    map(() => {
      const instance = Object.values(state$.value.formInstance.instances)?.[0];
      const lastUpdated = formatDateString(instance.status.lastUpdated, {
        format: 'YYYY-MM-DD',
      });

      const fileName = `${instance.name} - Last updated ${lastUpdated}.pdf`;

      return exportToPdf(instance.id, fileName);
    }),
  );
}

function showStatusOnFormInstancePdfExportRequested(
  action$: Observable<ReduxFormInstanceExportPdf>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/EXPORT_INSTANCE_TO_PDF'),
    filter((action) => Boolean(action.payload.instanceId)),
    map(() =>
      showStatusBar(
        'INFO',
        `A PDF is being generated for you. Once ready, it will automatically be downloaded by your browser.`,
        5000,
      ),
    ),
  );
}

function showStatusOnFormInstancePdfExportError(
  action$: Observable<ReduxFormInstancePdfExportError>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/PDF_EXPORT_ERROR'),
    map(() =>
      showStatusBar(
        'ERROR',
        `Something went wrong trying to download your document. Please try again later.`,
        5000,
      ),
    ),
  );
}
