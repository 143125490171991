/** @jsxImportSource @emotion/react */
import { createContext, ReactElement, useContext } from 'react';
import { IGraphQLClient } from './types.ts';

const Context = createContext<IGraphQLClient>(null);

export type GraphQLProviderProps = {
  children: ReactElement;
  client: IGraphQLClient;
};
export const GraphQLProvider: React.FC<GraphQLProviderProps> = ({
  children,
  client,
}) => <Context.Provider value={client}>{children}</Context.Provider>;

/**
 * React hook for providing GraphQL clients for use in components.
 */
export const useGraphQL = () => useContext(Context);
