/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { useMemo, useState } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { ActionAuditContainer } from '../../ActionAudit/ActionAuditContainer.tsx';
import { ActionTabInsightDetails } from './ActionTabInsightDetails.tsx';
import { ActionTabInstanceDetails } from './ActionTabInstanceDetails.tsx';
import { ActionTabIssueDetails } from './ActionTabIssueDetails.tsx';

export const ActionTabStrip: React.FC = () => {
  const formLabel = useTenantLocale((l) => l.label.form);

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const isNew = useFormsSelector((state) => !state.formActionV2.existingAction);

  const hasInstanceDetails = useFormsSelector((state) =>
    Boolean(state.formActionV2.formInstance),
  );

  const hasIssueDetails = useFormsSelector((state) =>
    Boolean(
      state.formActionV2.issue ||
        state.formActionV2.draftChanges.action.issueId,
    ),
  );

  const hasInsightDetails = useFormsSelector((state) =>
    Boolean(
      state.formActionV2.insight ||
        state.formActionV2.draftChanges.action.insightId,
    ),
  );

  const isInsightCleared = useFormsSelector(
    (state) => state.formActionV2.draftChanges.action.insightId === null,
  );

  const isIssueCleared = useFormsSelector(
    (state) => state.formActionV2.draftChanges.action.issueId === null,
  );

  const showInsightDetails = hasInsightDetails && !isInsightCleared;

  const showIssueDetails = hasIssueDetails && !isIssueCleared;

  const showInstanceDetails =
    hasInstanceDetails && !showIssueDetails && !showInsightDetails;

  const showCoachingInstanceDetails =
    module === 'coaching' && showIssueDetails && !isNew;

  const showDetailsTab =
    showIssueDetails || showInsightDetails || showInstanceDetails;

  const detailsLabel = useMemo(() => {
    if (showInsightDetails) return 'Learning Area';
    if (showIssueDetails) return 'Issue details';

    return `${formLabel} details`;
  }, [formLabel, showInsightDetails, showIssueDetails]);

  const tabs = useMemo(
    () =>
      [
        showDetailsTab && {
          id: 'details',
          label: detailsLabel,
          isEnabled: true,
        },
        showCoachingInstanceDetails && {
          id: 'instanceDetails',
          label: `${formLabel} details`,
          isEnabled: true,
        },
        !isNew && {
          id: 'actionAudit',
          label: 'History',
          isEnabled: true,
        },
      ].filter(Boolean),
    [
      detailsLabel,
      formLabel,
      isNew,
      showCoachingInstanceDetails,
      showDetailsTab,
    ],
  );

  const [selectedTabId, setSelectedTabId] = useState('details');

  return (
    Boolean(tabs.length) && (
      <div css={styles.base}>
        <TabStrip
          selectedId={selectedTabId}
          onSelectionChanged={setSelectedTabId}
          tabs={tabs}
        />
        {selectedTabId === 'details' && showInstanceDetails && (
          <ActionTabInstanceDetails />
        )}
        {selectedTabId === 'instanceDetails' && <ActionTabInstanceDetails />}
        {selectedTabId === 'details' && showInsightDetails && (
          <ActionTabInsightDetails />
        )}
        {selectedTabId === 'details' && showIssueDetails && (
          <ActionTabIssueDetails />
        )}
        {selectedTabId === 'actionAudit' && <ActionAuditContainer />}
      </div>
    )
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
};
