/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';
import { useFormsSelector } from '../../../redux/store.ts';

export const AutomatedActionsContentPlaceholder: React.FC = () => {
  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const elRow = (
    <div css={styles.row}>
      {module === 'coaching' ? (
        <>
          <Skeleton animation={'wave'} width={'20%'} height={33} />
          <Skeleton animation={'wave'} width={'17.5%'} height={33} />
          <Skeleton animation={'wave'} width={'45%'} height={33} />
          <Skeleton animation={'wave'} width={'7.5%'} height={33} />
          <Skeleton animation={'wave'} width={'10%'} height={33} />
        </>
      ) : (
        <>
          <Skeleton animation={'wave'} width={'15%'} height={33} />
          <Skeleton animation={'wave'} width={'25%'} height={33} />
          <Skeleton animation={'wave'} width={'42.5%'} height={33} />
          <Skeleton animation={'wave'} width={'7.5%'} height={33} />
          <Skeleton animation={'wave'} width={'10%'} height={33} />
        </>
      )}
    </div>
  );

  return (
    <div css={styles.base}>
      {elRow}
      {elRow}
      {elRow}
      {elRow}
      {elRow}
      {elRow}
      {elRow}
      {elRow}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 8px 0 8px',
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0 10px 0',
    gap: '5px 12px',
    borderBottom: 'solid 1px #e0e0e0',
  }),
};
