/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { DateAnswerContainer } from '../components/InputContainers/DateAnswerContainer.tsx';
import { DropdownAnswerContainer } from '../components/InputContainers/DropdownAnswerContainer.tsx';
import { NumberAnswerContainer } from '../components/InputContainers/NumberAnswerContainer.tsx';
import { ReviewerAnswerContainer } from '../components/InputContainers/ReviewerAnswerContainer.tsx';
import { SelectAnswerContainer } from '../components/InputContainers/SelectAnswerContainer.tsx';
import { SubjectAnswerContainer } from '../components/InputContainers/SubjectAnswerContainer.tsx';
import { TextAnswerContainer } from '../components/InputContainers/TextAnswerContainer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { IInputsLine } from '../types/types.ts';

export interface IInputsFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  line: IInputsLine;
}

export const InputsFactoryContainer: React.FC<IInputsFactoryContainerProps> = ({
  canUpdateInstance,
  instanceId,
  line,
}) => {
  const questionKeys = useMemo(
    () => line.inputs.map((input) => input.id),
    [line.inputs],
  );

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys,
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={`factory-${instanceId}-${line.id}`}
      highlightOnHover={line.highlightOnHover}
      paddingBottom={5}
      marginBottom={0}
      bulletTop={28}
      isVisible={isVisible}
    >
      <div css={styles.base}>
        {line.inputs.map((input) => {
          if (input.type === 'textAnswer')
            return (
              <TextAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                isVisible={isVisible}
                key={input.id}
                line={input}
              />
            );

          if (input.type === 'numberAnswer')
            return (
              <NumberAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                isVisible={isVisible}
                key={input.id}
                line={input}
              />
            );

          if (input.type === 'dateAnswer')
            return (
              <DateAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                isVisible={isVisible}
                key={input.id}
                line={input}
              />
            );

          if (input.type === 'selectAnswer')
            return (
              <SelectAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                isVisible={isVisible}
                key={input.id}
                line={input}
              />
            );

          if (input.type === 'dropdownAnswer' && input.id === 'subject')
            return (
              <SubjectAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                key={input.id}
                line={input}
              />
            );

          if (input.type === 'dropdownAnswer' && input.id === 'assignedTo')
            return (
              <ReviewerAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                key={input.id}
                line={input}
              />
            );

          if (input.type === 'dropdownAnswer')
            return (
              <DropdownAnswerContainer
                canUpdateInstance={canUpdateInstance}
                instanceId={instanceId}
                isVisible={isVisible}
                key={input.id}
                line={input}
              />
            );

          return null;
        })}
      </div>
    </FormListItemWrapped>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    width: '100%',
  }),
};
