/** @jsxImportSource @emotion/react */
import { Accordion } from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { StorageKey } from '@seeeverything/ui.util/src/storage/api.ts';
import { ReactNode } from 'react';

export const DebugSection: React.FC<{
  children: ReactNode;
  id: string;
  title: string;
}> = ({ children, id, title }) => {
  const storageKey = [StorageKey.Debug, id].join('/');
  const [showing, setShowing] = useLocalStorage(storageKey, true);

  return (
    <Accordion
      id={id}
      isExpanded={showing}
      onChange={(e) => setShowing(e.isExpanded)}
    >
      <AccordionSummary style={styles.summary}>
        <Text selectable={false}>{title}</Text>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const styles = {
  summary: {
    background: color.format(-0.04),
    border: `solid 1px ${color.format(-0.08)}`,
    borderRadius: 3,
    '&$expanded': {
      '&:hover': {
        background: color.format(-0.08),
      },
    },
    '&:hover': {
      background: color.format(-0.08),
    },
  },
};
