import {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  NormalizedCacheObject,
  QueryOptions,
} from '@apollo/client';
import moment from 'moment';
import { Subject } from 'rxjs';
import { GqlRequestResponse, IGraphQLClient } from './types.ts';

export const queryMutation$ = new Subject<GqlRequestResponse>();

/**
 * A client for connecting to a GraphQL server.
 */
export class GraphQLClient implements IGraphQLClient {
  private apollo: ApolloClient<NormalizedCacheObject>;

  public constructor(apollo?: ApolloClient<NormalizedCacheObject>) {
    if (apollo) {
      this.apollo = apollo;
    }
  }

  /**
   * Initiates a simple query.
   */
  public async query<T>(request: QueryOptions): Promise<ApolloQueryResult<T>> {
    let response: ApolloQueryResult<T>;
    let error: Error;

    try {
      response = (await this.apollo.query<T>(request)) as ApolloQueryResult<T>;
    } catch (err) {
      error = err;
    }

    queryMutation$.next({
      timestamp: moment().toISOString(),
      payload: { kind: 'query', request, response, error },
    });

    if (error) {
      throw error;
    }

    return response;
  }

  /**
   * Initiates a data mutation.
   */
  public async mutate<T>(request: MutationOptions<T>) {
    let response: FetchResult<T>;
    let error: Error;

    try {
      response = await this.apollo.mutate<T>(request);
    } catch (err) {
      error = err;
    }

    queryMutation$.next({
      timestamp: moment().toISOString(),
      payload: { kind: 'mutation', request, response, error },
    });

    if (error) {
      throw error;
    }

    return response;
  }
}
