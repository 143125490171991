/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import React from 'react';

export interface INextProps {
  onNextClicked?: () => void;
}

export const Next: React.FC<INextProps> = (props) => (
  <div
    data-test={'shell-next-tenantSelection'}
    css={styles.base}
    onClick={props.onNextClicked}
  >
    <Text
      color={'WHITE'}
      uppercase={true}
      size={14}
      weight={400}
      selectable={false}
    >
      {'Continue >'}
    </Text>
  </div>
);

const styles = {
  base: css({
    cursor: 'pointer',
    height: 71,
    backgroundColor: '#709CF2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  }),
};
