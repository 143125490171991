/** @jsxImportSource @emotion/react */
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { ObjectInspector } from '@seeeverything/ui.test/src/components/ObjectInspector/ObjectInspector.tsx';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { interval } from 'rxjs';

export const AuthTokens: React.FC = () => {
  const [idToken, setIdToken] = useState<string>(undefined);
  const [accessToken, setAccessToken] = useState<string>(undefined);
  const [expiresAt, setExpiresAt] = useState<string>(undefined);

  const refreshPeriodicValues = useCallback(async () => {
    try {
      setIdToken(await authApi.getIdToken());
      setAccessToken(await authApi.getAccessToken());
      setExpiresAt(await authApi.getExpiresAt());
    } catch {
      setIdToken(undefined);
      setAccessToken(undefined);
      setExpiresAt(undefined);
    }
  }, []);

  useEffect(() => {
    const subscription = interval(1000).subscribe(refreshPeriodicValues);

    return () => subscription.unsubscribe();
  }, [refreshPeriodicValues]);

  useEffect(() => {
    refreshPeriodicValues();
  }, [refreshPeriodicValues]);

  const authInfoObjectInspection = useMemo(
    () => ({
      idTokenDecoded: idToken && jwtDecode(idToken),
      accessTokenDecoded: accessToken && jwtDecode(accessToken),
      authExpiryDate: expiresAt && new Date(expiresAt),
      tokens: {
        accessToken,
        idToken,
        expiresAt,
      },
    }),
    [accessToken, expiresAt, idToken],
  );

  return (
    <ObjectInspector name={'authTokens'} data={authInfoObjectInspection} />
  );
};
