import { mapAutomatedAction } from '@se/data/forms/query/automatedActionsUtil.ts';
import {
  FormActionStatusWithOverdue,
  FormAnswerAutomatedAction,
  FormInstanceStatus,
} from '@se/data/forms/types.ts';
import { SimpleEntity } from '@se/data/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { pluck } from 'ramda';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, concatAll, from, map, mergeMap, of } from 'rxjs';
import { parseYaml } from '../../../parse/index.ts';
import {
  ServerAnswer,
  convertServerAnswersToInstanceAnswers,
} from '../../../util/util.instance.ts';
import { getIssueInsightsAnswerDefinitions } from '../../../util/util.issueInsights.ts';
import { automatedActionSlice } from '../../automatedAction/index.ts';
import { appealSlice } from '../../formInstanceAppeal/index.ts';
import {
  AppealOutcome,
  FormInstanceAppeal,
} from '../../formInstanceAppeal/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import {
  Attendee,
  FormPerson,
  FormTeam,
  IFormDocument,
  IFormInstance,
  IFormInstancePermissions,
  IFormInstanceScore,
  IFormInstanceSignOff,
  ReduxFormInstanceServerLoad,
} from '../types.ts';
import { errorLoading, serverLoaded } from './actions.ts';
import { queryInstanceLoad } from './query.ts';

export function clearFormsTrackedDataIdsEpic(
  action$: Observable<ReduxFormInstanceServerLoad>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOAD'),
    map(() => scrollSlice.clearTrackedDataIds()),
  );
}

/**
 * Listens for the LOAD action and finds the form-instance
 * from the DB and loads it.
 */
export function loadInstanceEpic(
  action$: Observable<ReduxFormInstanceServerLoad>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOAD'),
    mergeMap(async (action) => {
      const module = state$.value.tenantState.tenant.module;
      const { instanceId } = action.payload;

      const queryResult = await queryFormInstance(
        client,
        instanceId,
        module === 'cadence',
      );

      return queryResult.isSuccess === true
        ? from([
            serverLoaded(instanceId, queryResult.formInstance),
            appealSlice.loadedAppeal(queryResult.appeal),
            automatedActionSlice.setAutomatedActionsForInstance(
              queryResult.answerAutomatedActions,
            ),
          ])
        : of(failedToLoad(instanceId, queryResult.reason));
    }),
    concatAll(),
  );
}

const failedToLoad = (
  instanceId: string,
  reason: 'not-found' | 'bad-form-template',
) => {
  log.error(
    new Error(`Failed to retrieve form instance [${instanceId}] from graphql.`),
  );
  return errorLoading(instanceId, reason);
};

export type QueryFormInstanceSuccess = {
  isSuccess: true;
  formInstance: IFormInstance;
  appeal?: FormInstanceAppeal;
  answerAutomatedActions?: Record<string, FormAnswerAutomatedAction[]>;
};

export type QueryFormInstanceFailed = {
  isSuccess: false;
  reason: 'not-found' | 'bad-form-template';
};

export type QueryFormInstance =
  | QueryFormInstanceSuccess
  | QueryFormInstanceFailed;

const toSubject = (subject: any): FormPerson | FormTeam => {
  if (!subject) return undefined;

  return subject.type === 'Person'
    ? {
        id: subject.id,
        kind: 'Person',
        name: subject.name,
        positionTitle: subject.positionTitle,
      }
    : {
        id: subject.id,
        kind: 'Team',
        name: subject.name,
        path: subject.path,
      };
};

const parseTemplate = (data: any, instanceId: string) => {
  try {
    const definition = data.definition.definition;
    const definitionJson = JSON.parse(definition);
    const lines = parseYaml(definition);

    return {
      isSuccess: true,
      definitionJson,
      lines,
    };
  } catch (err) {
    log.error(`Form instance with id ${instanceId} has bad data.`, err);

    return {
      isSuccess: false,
      reason: 'bad-form-template',
    };
  }
};

export type InstanceActionResponse = {
  id: string;
  assignedTo: SimpleEntity;
  status: FormActionStatusWithOverdue;
  description?: string;
  dueBy?: string;
  formInstanceId?: string;
  completedAt?: string;
  createdAt: string;
  issueId?: string;
  issue?: {
    id: string;
    createdInInstanceId: string;
    issue?: {
      id: string;
      label: string;
      notes?: string;
      issueCoachingRequired?: boolean;
      formSectionName: string;
      formQuestionText: string;
    };
  };
};

type InstanceLoadResponse = {
  forms: {
    formInstance: {
      id: string;
      reportingDate: string;
      createdAt: string;
      createdBy: {
        id: string;
        name: string;
        kind: 'Person';
      };
      definition: {
        id: string;
        definition: string;
      };
      template: {
        id: string;
        name: string;
        category: {
          id: string;
          name: string;
        };
      };
      answers: {
        pageInfo: {
          hasNextPage: boolean;
        };
        nodes: ServerAnswer[];
      };
      assignedTo: {
        id: string;
        name: string;
        positionTitle: string;
      };
      subject: {
        id: string;
        name: string;
        type: string;
        positionTitle: string;
        path: string;
      };
      status: FormInstanceStatus;
      updatedAt: string;
      permissions: IFormInstancePermissions;
      documents: IFormDocument[];
      signOffs: IFormInstanceSignOff[];
      statusNotes: {
        appealCategory: string;
        appealOutcome: AppealOutcome;
        appealOutcomeReason: string;
        appealReason: string;
        appealResponse: string;
      };
      score: IFormInstanceScore;
      attendees: {
        nodes: IGraphQLAttendee[];
      };
    };
  };
};

const queryFormInstance = async (
  client: IGraphQLClient,
  instanceId: string,
  includeAttendance?: boolean,
): Promise<QueryFormInstance> => {
  let response;
  try {
    response = await client.query<InstanceLoadResponse>({
      query: queryInstanceLoad({ includeAttendance }),
      variables: { instanceId },
      fetchPolicy: 'network-only',
    });
  } catch {
    return {
      isSuccess: false,
      reason: 'not-found',
    };
  }
  const data = response.data.forms.formInstance;
  if (!data) {
    return {
      isSuccess: false,
      reason: 'not-found',
    };
  }

  const parseResult = parseTemplate(data, instanceId);

  if (!parseResult.isSuccess) {
    return parseResult as QueryFormInstance;
  }

  const appeal: FormInstanceAppeal = {
    outcome: data.statusNotes?.appealOutcome,
    outcomeReason: data.statusNotes?.appealOutcomeReason,
    reason: data.statusNotes?.appealCategory,
    request: data.statusNotes?.appealReason,
    response: data.statusNotes?.appealResponse,
  };
  const permissions = { ...data.permissions };
  const result: IFormInstance = {
    id: data.id,
    createdAt: data.createdAt,
    createdBy: data.createdBy,
    name: data.template.name,
    version: '1.0.0',
    template: {
      id: data.template.id,
      name: data.template.name,
      category: {
        id: data.template.category.id,
        name: data.template.category.name,
      },
      definitionJson: parseResult.definitionJson,
    },
    lines: parseResult.lines,
    answers: convertServerAnswersToInstanceAnswers(
      data.answers.nodes,
      parseResult.lines,
      data.reportingDate,
    ),
    status: {
      status: data.status,
      lastUpdated: data.updatedAt,
    },
    reviewer: data.assignedTo
      ? {
          id: data.assignedTo.id,
          name: data.assignedTo.name,
          positionTitle: data.assignedTo.positionTitle,
          kind: 'Person',
        }
      : undefined,
    subject: toSubject(data.subject),
    signOffs: data.signOffs || [],
    permissionsIsLoading: false,
    permissions,
    attendees: formAttendees(data.attendees?.nodes),
    fileIds: pluck('id', data.documents),
    documents: data.documents || [],
    score: data.score,
    reportingDate: data.reportingDate,
    questionErrors: {},
  };

  const questionKeys = Object.keys(parseResult.lines);

  const issueInsightAnswerDefinitions = getIssueInsightsAnswerDefinitions(
    parseResult.lines,
  );

  return {
    isSuccess: true,
    formInstance: result,
    appeal,
    answerAutomatedActions: data.answers.nodes.reduce((acc, answer) => {
      if (!answer.automatedActions?.length) return acc;

      return {
        ...acc,
        [answer.id]: (answer.automatedActions ?? [])
          .map((automatedAction) =>
            mapAutomatedAction({
              answerKey: answer.key,
              answerValue: answer.value,
              input: automatedAction,
              issueInsightAnswerDefinitions,
              issueId: answer.issue?.id,
              insightId: answer.insight?.id,
              questionKeys,
            }),
          )
          .filter(Boolean),
      };
    }, {}),
  };
};

interface IGraphQLAttendee {
  id: string;
  name: string;
  attended?: boolean;
  followUp?: boolean;
}

const formAttendees = (data?: IGraphQLAttendee[]) =>
  data?.map(
    (attendee): Attendee => ({
      id: attendee.id,
      name: attendee.name,
      isAttending: attendee.attended,
      isFollowUpRequired: attendee.followUp,
    }),
  );
