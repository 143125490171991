/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormInstanceStatus } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { last } from 'ramda';
import {
  AppealEventHandler,
  IFormInstanceSignOff,
  RevertSignoffEventHandler,
  SignoffEventHandler,
} from '../../redux/form-instance/types.ts';
import { Header } from './components/Header.tsx';
import { SignoffParticipant } from './components/SignoffParticipant.tsx';

export interface ISignoffProps {
  canAppealAsManager: boolean;
  canAppealAsSubject: boolean;
  cannotAppeal: boolean;
  errorPreventSignOff?: string;
  instanceId: string;
  instanceStatus: FormInstanceStatus;
  isSpinning: boolean;
  onAppealClick: AppealEventHandler;
  onlyManagerCanAppeal: boolean;
  onRevertSignoffClick: RevertSignoffEventHandler;
  onSignoffClick: SignoffEventHandler;
  reviewerCanRevert: boolean;
  reviewerCanSignOff: boolean;
  reviewerDescription: string;
  reviewerId: string;
  reviewerLabel: string;
  reviewerName: string;
  reviewerSignOff: IFormInstanceSignOff;
  reviewerSignOffRequired: boolean;
  subjectCanRevert: boolean;
  subjectCanSignOff: boolean;
  subjectDescription: string;
  subjectId: string;
  subjectLabel: string;
  subjectName: string;
  subjectSignOff: IFormInstanceSignOff;
  subjectSignOffRequired: boolean;
}

export const Signoff = ({
  canAppealAsManager,
  canAppealAsSubject,
  cannotAppeal = true,
  errorPreventSignOff,
  instanceStatus,
  isSpinning,
  onAppealClick,
  onlyManagerCanAppeal,
  onRevertSignoffClick,
  onSignoffClick,
  reviewerCanRevert,
  reviewerCanSignOff,
  reviewerDescription,
  reviewerId,
  reviewerLabel = 'Reviewer',
  reviewerName,
  reviewerSignOff,
  reviewerSignOffRequired = true,
  subjectCanRevert,
  subjectCanSignOff,
  subjectDescription,
  subjectId,
  subjectLabel = 'Subject',
  subjectName,
  subjectSignOff,
  subjectSignOffRequired = true,
}: ISignoffProps) => {
  const getCompletedAt = () => {
    const subjectDate = subjectSignOff?.occurredAt;
    if (!subjectDate && subjectSignOffRequired) return;

    const reviewerDate = reviewerSignOff?.occurredAt;
    if (!reviewerDate && reviewerSignOffRequired) return;

    return last([subjectDate, reviewerDate].filter(Boolean).sort());
  };

  return (
    <div css={styles.base}>
      <Header completedAt={getCompletedAt()} />
      {isSpinning && <Spinner center={true} />}
      <div css={styles.bodyOuter}>
        {!isSpinning && (
          <>
            <SignoffParticipant
              authorisedByName={reviewerSignOff?.authorisedBy?.name}
              canAppealAsManager={false}
              canAppealAsSubject={false}
              cannotAppeal={true}
              canRevert={reviewerCanRevert}
              canSignOff={reviewerCanSignOff}
              errorPreventSignOff={errorPreventSignOff}
              instanceStatus={instanceStatus}
              onAppealClick={onAppealClick}
              onlyManagerCanAppeal={false}
              onRevertSignoffClick={onRevertSignoffClick}
              onSignoffClick={onSignoffClick}
              participantDescription={reviewerDescription}
              participantId={reviewerId}
              participantName={reviewerName}
              reviewerSignOff={reviewerSignOff}
              signOff={reviewerSignOff}
              signOffRequired={reviewerSignOffRequired}
              type={'REVIEWER'}
              typeLabel={reviewerLabel}
            />
            <SignoffParticipant
              authorisedByName={subjectSignOff?.authorisedBy?.name}
              canAppealAsManager={canAppealAsManager}
              canAppealAsSubject={canAppealAsSubject}
              cannotAppeal={cannotAppeal}
              canRevert={subjectCanRevert}
              canSignOff={subjectCanSignOff}
              errorPreventSignOff={errorPreventSignOff}
              instanceStatus={instanceStatus}
              onAppealClick={onAppealClick}
              onlyManagerCanAppeal={onlyManagerCanAppeal}
              onRevertSignoffClick={onRevertSignoffClick}
              onSignoffClick={onSignoffClick}
              participantDescription={subjectDescription}
              participantId={subjectId}
              participantName={subjectName}
              reviewerSignOff={reviewerSignOff}
              signOff={subjectSignOff}
              signOffRequired={subjectSignOffRequired}
              type={'SUBJECT'}
              typeLabel={subjectLabel}
            />
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: color.format(-0.02),
    borderRadius: 3,
    maxWidth: 700,
    '@media (max-width: 953px)': {
      maxWidth: 340,
    },
  }),
  bodyOuter: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '15px 0',
    minHeight: 145,
    overflowX: 'auto',
    '@media (max-width: 953px)': {
      padding: '25px 0px',
      gap: 30,
    },
  }),
};
