import { QueryOptions } from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import * as R from 'ramda';
import { client } from '../common/index.ts';
import { IInstance } from './types.ts';
import { formatDateString } from './util.ts';

export async function formInstancesPaged(
  pageNumber = 1,
  personId?: string,
  filter?: string,
  options?: QueryOptions,
) {
  const isFilter =
    filter && R.is(String, filter) && (filter as string).length > 1;

  const response = await client.query<FormInstancesPagedQueryResponse>({
    fetchPolicy: 'network-only', // Default to network-only.
    ...options,
    query: QueryFormInstancesPaged,
    variables: {
      search: isFilter ? (filter as string).toLowerCase() : undefined,
      associatedPersonId: personId,
      pageNumber,
      orderBy: { fieldName: 'createdAt', direction: 'Descending' },
    },
  });

  return {
    currentPage: pageNumber,
    hasNextPage: response.data.forms.formInstances.pageInfo.hasNextPage,
    data: response.data.forms.formInstances.nodes.map(toInstance),
  };
}

const toInstance = (node: any): IInstance => {
  const { id, template, assignedTo, subject, status, createdAt } = node;

  const createdDateAndTime = new Date(createdAt);
  const createdDate = new Date(moment(createdDateAndTime).format('MM/DD/YYYY'));

  return {
    id,
    formName: template.name,
    formCategory: toCategory(template.category),
    assignedTo: assignedTo && {
      id: assignedTo.id,
      name: assignedTo.name,
    },
    subject: subject && {
      id: subject.id,
      name: subject.name,
    },
    status,
    createdDateAndTime,
    createdDate,
    createdDateLabel: formatDateString(createdAt, false),
  };
};

const toCategory = (node: any) => {
  const { id, name } = node;
  return {
    id,
    name,
    kind: 'category' as const,
  };
};

interface IFormInstance {
  id: string;
  template: {
    id: string;
    name: string;
    category: {
      id: string;
      name: string;
    };
  };
}

type FormInstancesPagedQueryResponse = {
  forms: {
    formInstances: {
      pageInfo: {
        hasNextPage: boolean;
      };
      nodes: IFormInstance[];

      assignedTo: {
        id: string;
        name: string;
      };
      subject: {
        id: string;
        name: string;
        type: string;
      };
      status: {
        updatedBy: {
          id: string;
          name: string;
        };
        createdAt: Date;
      };
    };
  };
};

const QueryFormInstancesPaged = gql`
  query FormInstancesPaged(
    $pageNumber: Int
    $search: String
    $associatedPersonId: ID
    $orderBy: [OrderByInput!]
  ) {
    forms {
      formInstances(
        pagination: { pageNumber: $pageNumber, size: 50 }
        search: $search
        associatedPersonId: $associatedPersonId
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
        }
        nodes {
          id
          template {
            id
            name
            category {
              id
              name
            }
          }
          assignedTo {
            id
            name
          }
          subject {
            id
            name
            type
          }
          status
          updatedBy {
            id
            name
          }
          createdAt
        }
      }
    }
  }
`;
