/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DashboardComment } from '@se/data/dashboards/query/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LoadMore } from '@seeeverything/ui.primitives/src/components/LoadMore/LoadMore.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/index.ts';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { useMeasure } from '@seeeverything/ui.primitives/src/hooks/useMeasure.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/index.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { viewDetail, viewEntity } from '../../redux/actions.ts';
import { useDashboardsDispatch } from '../../redux/store.ts';
import { IDashboardCommentsList } from '../../types.ts';
import { Comment } from './components/index.ts';

export interface ICommentsListProps {
  dashboardComponentId: string;
  commentsList: IDashboardCommentsList;
  comments: DashboardComment[];
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasMorePages: boolean;
  fetchNextPage: () => void;
  type: 'FULL_SHEET' | 'DASHBOARD_COMPONENT';
  entityType: 'person' | 'team';
}

export const CommentsList: React.FC<ICommentsListProps> = ({
  dashboardComponentId,
  commentsList,
  comments,
  isLoading,
  isFetching,
  isFetchingNextPage,
  hasMorePages,
  fetchNextPage,
  type,
  entityType,
}) => {
  const dispatch = useDashboardsDispatch();

  const isInit = useRef(false);

  const [measureRef, commentsSize] = useMeasure({
    remeasureDependency: isLoading,
  });

  const [collapsed, setCollapsed] = useState(
    type === 'DASHBOARD_COMPONENT' && !isLoading && commentsSize?.height >= 270,
  );

  const loadMoreText = useMemo(() => {
    if (isLoading) return;
    if (type === 'FULL_SHEET') return;

    return hasMorePages ? 'Load more' : 'Expand full screen';
  }, [isLoading, type, hasMorePages]);

  const handleViewFullSheet = useCallback(
    () => dispatch(viewDetail('COMMENTS_LIST', dashboardComponentId)),
    [dashboardComponentId, dispatch],
  );

  const handleLoadMore = useCallback(() => {
    if (!hasMorePages) return;
    fetchNextPage();
  }, [hasMorePages, fetchNextPage]);

  const handleViewForm = useCallback(
    (instanceId: string, instanceLabel: string) => {
      if (!commentsList) return;
      if (!instanceId) return;

      dispatch(
        viewEntity(dashboardComponentId, instanceId, 'Form', instanceLabel),
      );
    },
    [commentsList, dashboardComponentId, dispatch],
  );

  useEffect(() => {
    if (isInit.current) return undefined;
    if (isLoading) return undefined;
    if (!commentsSize?.height) return undefined;

    // Only do this once, and after we have items loaded.
    isInit.current = true;
    if (type === 'FULL_SHEET') return undefined;
    if (commentsSize.height >= 270) setCollapsed(true);
  }, [type, commentsSize?.height, isLoading]);

  const elLoading = isLoading === true && (
    <SkeletonDetailedItemsList
      itemStyle={styles.skeletonList}
      numberOfSkeletonItems={3}
    />
  );

  const elEmpty = !isLoading && !comments?.length && !hasMorePages && (
    <Text color={color.format(-0.65)} size={14} style={styles.empty}>
      {'There are no recent comments to show.'}
    </Text>
  );

  const elComments = !elLoading && !elEmpty && comments && (
    <div ref={measureRef} css={styles.commentsOuter}>
      {comments.map((comment, index) => (
        <Comment
          key={`comment-${index}`}
          comment={comment}
          entityType={entityType}
          onFormClicked={handleViewForm}
        />
      ))}
    </div>
  );

  const elLoadMoreText = loadMoreText && (
    <div
      css={[styles.loadMore, comments?.length ? undefined : styles.borderTop]}
    >
      <TextButton onClick={handleViewFullSheet} size={14}>
        {loadMoreText}
      </TextButton>
    </div>
  );

  const elLoadMore = useMemo(() => {
    if (type !== 'FULL_SHEET') return;
    if (isLoading) return;
    if (!hasMorePages) return;

    if (isFetchingNextPage)
      return (
        <div css={styles.spinner}>
          <Spinner center={true} />
        </div>
      );

    return (
      <div css={styles.loadMore}>
        <LoadMore onInView={handleLoadMore} />
      </div>
    );
  }, [isFetchingNextPage, handleLoadMore, hasMorePages, isLoading, type]);

  const elTitle = type === 'DASHBOARD_COMPONENT' && (
    <Text
      uppercase={true}
      color={color.format(-0.5)}
      weight={900}
      size={15}
      style={styles.title}
    >
      {commentsList?.title}
    </Text>
  );

  const expand = useCallback(() => setCollapsed(false), []);

  const elExpand = collapsed && (
    <div css={styles.expand} onClick={expand}>
      <Text color={COLORS.BLUE} cursor={'pointer'}>
        {'Show more'}
      </Text>
      <Icons.arrowDropDown size={40} cursor={'pointer'} />
    </div>
  );

  return (
    <div
      css={[
        styles.base,
        collapsed ? styles.collapsed : undefined,
        isFetching ? styles.mask : undefined,
      ]}
    >
      {elTitle}
      {elLoading}
      {elEmpty}
      {elComments}
      {elLoadMoreText}
      {elLoadMore}
      {elExpand}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    position: 'relative',
    overflow: 'hidden',
  }),
  collapsed: css({
    maxHeight: 300,
    cursor: 'pointer',
  }),
  expand: css({
    position: 'absolute',
    inset: 'auto 0 0 0',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTop: 'solid 6px #E5E5E5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  }),
  title: css({
    borderBottom: `8px solid ${color.format(-0.1)}`,
    padding: '0px 8px 8px 8px',
  }),
  commentsOuter: css({
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    marginTop: 3,
  }),
  skeletonList: css({
    margin: 8,
  }),
  mask: css({
    opacity: 0.6,
  }),
  loadMore: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `solid 1px ${color.format(-0.1)}`,
    boxSizing: 'border-box',
  }),
  spinner: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 40,
  }),
  borderTop: css({ borderTop: `solid 1px ${color.format(-0.1)}` }),
  empty: css({
    margin: 8,
  }),
};
