import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { StateObservable } from 'redux-observable';
import { Observable, concatAll, filter, from, mergeMap } from 'rxjs';
import { formActionPlanSlice } from '../formActionPlan/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formEditGoalSlice from './formEditGoalSlice.ts';

/**
 * Responsible for creating a goal on the server (via GraphQL).
 */
export function createOnServer(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formEditGoalSlice.createGoal.match),
    mergeMap(async (action) => {
      const { goal, source } = action.payload;
      const goalId = goal.id;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;

      try {
        const response = await client.mutate<{
          forms: { createFormGoal: { ok: boolean } };
        }>({
          mutation: gql`
            mutation CreateFormGoal(
              $id: ID!
              $formInstanceId: ID!
              $assignedToPersonId: ID!
              $description: String!
              $dueBy: DateTime!
              $note: String
              $additionalFields: [CreateFormGoalAdditionalFieldInput!]
              $goalCategoryId: ID
            ) {
              forms {
                createFormGoal(
                  input: {
                    id: $id
                    formInstanceId: $formInstanceId
                    assignedToPersonId: $assignedToPersonId
                    description: $description
                    dueBy: $dueBy
                    note: $note
                    additionalFields: $additionalFields
                    goalCategoryId: $goalCategoryId
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: {
            id: goalId,
            formInstanceId: goal.formInstanceId,
            assignedToPersonId: goal.assignedTo.id,
            description: goal.description,
            note: goal.note,
            additionalFields: goal.additionalFields,
            goalCategoryId: goal.goalCategory?.id,
            dueBy: goal.dueBy,
          },
        });

        const isSuccess = response.data.forms.createFormGoal.ok;
        return from([
          formActionPlanSlice.upsertGoals({
            goals: [
              {
                id: goal.id,
                actions: [],
                assignedTo: goal.assignedTo,
                description: goal.description,
                dueBy: goal.dueBy,
                goalCategory: goal.goalCategory,
                status: goal.status.value,
              },
            ],
            instanceId: goal.formInstanceId,
            timezone,
          }),
          formEditGoalSlice.createdGoal({ isSuccess, goalId, source }),
        ]);
      } catch (err) {
        log.error('Error occurred while trying to save goal', err);
      }

      return from([
        formEditGoalSlice.createdGoal({
          isSuccess: false,
          goalId,
          source,
        }),
      ]);
    }),
    concatAll(),
  );
}
