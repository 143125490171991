type FilePickerArgs = {
  onSelect: (files: File) => void;
  allowedMimeTypes: string;
};

/**
 * Invokes an input element programmatically to allow selection of a file.
 */
export const filePicker = ({ onSelect, allowedMimeTypes }: FilePickerArgs) => {
  if (typeof window === 'undefined') return;

  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = false;
  input.accept = allowedMimeTypes;

  input.addEventListener(
    'change',
    () => {
      const file = input.files?.[0];
      if (!file) return;

      onSelect(file);
    },
    { once: true },
  );

  input.click();
};

/**
 * Adapted from:
 *  https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
export function getReadableSize(fileSizeInBytes: number, precision = 1) {
  if (fileSizeInBytes <= 0) {
    return '0 KB';
  }

  let i = -1;
  const byteUnits = [' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes >= 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(precision) + byteUnits[i];
}

export function getExtension(filename = '', defaultVal = '') {
  const index = filename.lastIndexOf('.');
  if (index === -1) {
    return defaultVal;
  }

  return filename.slice(index + 1).toLowerCase();
}
