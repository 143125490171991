/** @jsxImportSource @emotion/react */
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { NumberSliderAnswer } from '../components/NumberSliderAnswer/NumberSliderAnswer.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { formInstanceAnswerChange } from '../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { FormAnswer, INumberSliderLine } from '../types/types.ts';

export interface INumberSliderFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  line: INumberSliderLine;
}

export const NumberSliderFactoryContainer: React.FC<
  INumberSliderFactoryContainerProps
> = ({ canUpdateInstance, instanceId, line }) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [line.id],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValue = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    const value = instance?.answers?.[line.id]?.value;
    return value !== undefined ? Number(value) : undefined;
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: number, displayValue: string) => {
      if (!isVisible) return;

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: to.toString(),
        displayValue,
        lineId: line.id,
        type: 'numberSliderAnswer',
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bulletTop={22}
      isVisible={isVisible}
    >
      <NumberSliderAnswer
        error={error}
        isEnabled={canUpdateInstance}
        marks={line.marks}
        onChange={updateAnswer}
        question={line.question}
        value={answerValue}
      />
    </FormListItemWrapped>
  );
};
