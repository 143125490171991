/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ErrorRetry } from '@seeeverything/ui.primitives/src/components/ErrorRetry/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Paper } from '@seeeverything/ui.primitives/src/components/Paper/Paper.tsx';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useState } from 'react';
import { Comment, CommentField, CommentSkeleton } from './components/index.ts';
import {
  IDigitalContentComment,
  IDigitalContentCommentAddHandler,
  IDigitalContentCommentDeleteHandler,
  IDigitalContentReloadHandler,
} from './types.ts';

export interface IDigitalContentCommentsProps {
  bannerMessage?: string;
  canAddComments?: boolean;
  canReplyToComments?: boolean;
  comments?: IDigitalContentComment[];
  guidance?: string;
  hasNextPage?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadError?: string;
  loadMoreError?: string;
  onAddComment?: IDigitalContentCommentAddHandler;
  onCommentErrorClick?: (commentId: string) => void;
  onDeleteComment?: IDigitalContentCommentDeleteHandler;
  onLoadMore?: () => void;
  onReloadClick?: IDigitalContentReloadHandler;
}

/**
 * Displays comments alongside Digital Content.
 */
export const DigitalContentComments: React.FC<IDigitalContentCommentsProps> = ({
  bannerMessage,
  canAddComments,
  canReplyToComments,
  comments,
  loadError,
  loadMoreError,
  guidance,
  hasNextPage,
  isLoading,
  isLoadingMore,
  onAddComment,
  onCommentErrorClick,
  onDeleteComment,
  onLoadMore,
  onReloadClick,
}) => {
  const [draftComment, setDraftComment] = useState('');

  const changeComment = useCallback((to: string) => setDraftComment(to), []);
  const discardComment = useCallback(() => setDraftComment(''), []);
  const submitComment = useCallback(() => {
    onAddComment?.(draftComment);
    setDraftComment('');
  }, [draftComment, onAddComment]);

  const elGuidance =
    guidance !== undefined ? (
      <Paper
        style={styles.guidanceContainer}
        elevation={0}
        variant={'outlined'}
      >
        <Text color={color.format(-0.6)} weight={400}>
          {guidance}
        </Text>
      </Paper>
    ) : (
      <Skeleton style={styles.skeleton} height={44} variant={'rect'} />
    );

  const elHeading = (
    <div css={styles.heading}>
      <Text color={color.format(-0.6)} weight={FontWeight.bold} size={18}>
        {'Responses'}
      </Text>
    </div>
  );

  const elAddComment = canAddComments && (
    <CommentField
      id={'add-comment'}
      value={draftComment}
      onChange={changeComment}
      onDiscard={discardComment}
      onSubmit={submitComment}
      style={styles.addComment}
    />
  );

  const elBanner = bannerMessage && (
    <div css={styles.banner}>
      <Text color={color.format(-0.7)}>{bannerMessage}</Text>
    </div>
  );

  const handleReloadClick = useCallback(
    () => onReloadClick?.(loadError ? 'LOAD' : 'LOAD_MORE'),
    [loadError, onReloadClick],
  );

  const elLoadError = loadError && (
    <ErrorRetry onClick={handleReloadClick} message={loadError} />
  );

  const elLoadMoreError = loadMoreError && (
    <ErrorRetry onClick={handleReloadClick} message={loadMoreError} />
  );

  const elLoadMore = hasNextPage &&
    !isLoading &&
    !isLoadingMore &&
    !loadMoreError && (
      <TextButton icon={Icons.launch} iconSide={'RIGHT'} onClick={onLoadMore}>
        {'Load More'}
      </TextButton>
    );

  const elLoadingMoreSkeleton = isLoadingMore && (
    <CommentSkeleton key={'skeleton-comment-load-more'} />
  );

  if (loadError) {
    return (
      <div css={styles.base}>
        {elHeading}
        {elGuidance}
        {elAddComment}
        {elLoadError}
        {elLoadMoreError}
      </div>
    );
  }

  if (!isLoading && !comments?.length) {
    return (
      <div css={styles.base}>
        {elHeading}
        {elGuidance}
        {elAddComment}
        <Text style={styles.noCommentsMessage}>
          {'No comments to display.'}
        </Text>
      </div>
    );
  }

  return (
    <div css={styles.base}>
      {elHeading}
      {elBanner}
      {elGuidance}
      {elAddComment}
      {isLoading && (
        <>
          <CommentSkeleton />
          <CommentSkeleton />
        </>
      )}
      {!isLoading &&
        comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            onErrorClick={onCommentErrorClick}
            canReplyActive={canReplyToComments}
            onReply={onAddComment}
            onDelete={onDeleteComment}
          />
        ))}
      {elLoadingMoreSkeleton}
      {elLoadMoreError}
      {elLoadMore}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: '1 1 auto',
  }),
  heading: css({
    flex: '1 1 auto',
    display: 'flex',
  }),
  guidanceContainer: {
    margin: '22px 0 6px',
    background: color.format(-0.02),
    padding: 12,
    borderRadius: 4,
  },
  skeleton: {
    margin: '22px 0 6px',
  },
  noCommentsMessage: css({
    textAlign: 'center',
    flex: '1 1 auto',
    fontStyle: 'italic',
    fontWeight: 300,
    color: color.format(-0.2),
    marginTop: 6,
  }),
  addComment: css({
    marginBottom: 12,
  }),
  banner: css({
    margin: '22px 0 0',
    padding: 8,
    background: color.create(COLORS.BLUE).alpha(0.1).css(),
    border: `solid 1px ${color.create(COLORS.BLUE).alpha(0.1).css()}`,
    borderRadius: 3,
  }),
};
