/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CoachingSummarySectionFilter,
  CoachingSummarySectionLearningAreas,
} from '@se/data/forms/types.ts';
import { INTERSECTION_OBSERVER_DEFAULTS } from '@seeeverything/ui.primitives/src/common/constants.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { useViewport } from '@seeeverything/ui.primitives/src/hooks/useViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useFormsSelector } from '../../../../redux/store.ts';
import { useFormCoachingSummary } from '../../hooks/useFormCoachingSummary.ts';
import { CoachingSummaryError } from '../CoachingSummaryError.tsx';
import { CoachingSummaryLearningAreas } from './CoachingSummaryLearningAreas.tsx';

export type CoachingSummaryLearningAreasContainerProps = {
  filter: CoachingSummarySectionFilter;
  hideIfEmpty: boolean;
  instanceId: string;
  lineId: string;
};

export const CoachingSummaryLearningAreasContainer: React.FC<
  CoachingSummaryLearningAreasContainerProps
> = ({ instanceId, lineId, filter, hideIfEmpty }) => {
  const [viewportRef, inViewport] = useViewport(INTERSECTION_OBSERVER_DEFAULTS);
  const { isPdfExport } = usePdfExportContext();

  const {
    coachingSummary,
    coachingSummaryLoading,
    loadCoachingSummary,
    loadError,
    showLoadingSkeleton,
  } = useFormCoachingSummary<CoachingSummarySectionLearningAreas>({
    instanceId,
    lineId,
    coachingSummaryType: 'LearningAreasIdentified',
    filter,
    inViewport,
    hideIfEmpty,
  });

  const elTitle = (
    <div css={styles.title}>
      {!isPdfExport && <Icons.school fill={'#999999'} style={styles.icon} />}
      <Text color={color.format(-0.7)} size={20} weight={700}>
        {'Learning Areas Identified'}
      </Text>
    </div>
  );

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  if (!instanceSubject) return;

  if (showLoadingSkeleton)
    return (
      <div css={styles.base} ref={viewportRef}>
        {elTitle}
        <SkeletonDetailedItemsList numberOfSkeletonItems={3} />
      </div>
    );

  if (loadError)
    return (
      <div css={styles.base}>
        {elTitle}
        <CoachingSummaryError
          type={'learning areas'}
          onRetryLoad={loadCoachingSummary}
        />
      </div>
    );

  if (hideIfEmpty && coachingSummary.learningAreas.length === 0)
    return <div ref={viewportRef} />;

  return (
    <div css={styles.base} ref={viewportRef}>
      {elTitle}
      <CoachingSummaryLearningAreas
        filter={filter}
        instanceId={instanceId}
        isReloading={coachingSummaryLoading}
        learningAreas={coachingSummary.learningAreas}
      />
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  title: css({
    position: 'relative',
  }),
  icon: {
    position: 'absolute',
    inset: 'auto auto auto -44.5px',
  } as const,
};
