/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { IDataGridColumn } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { DataGridColumnFilter } from '../../../types/types.ts';
import { DataGridFormTemplateFilter } from './DataGridFormTemplateFilter.tsx';
import { DataGridPersonFilter } from './DataGridPersonFilter.tsx';

export type DataGridFilterPopupContainerProps = {
  column: IDataGridColumn;
  sort: 'Ascending' | 'Descending';
  filters: DataGridColumnFilter[];
  gridId: string;
  onColumnSort: (
    gridId: string,
    columnId: string,
    direction: 'ASC' | 'DESC',
  ) => void;
  onColumnFilterSelection: (
    gridId: string,
    columnId: string,
    filter: DataGridColumnFilter,
    to: boolean,
  ) => void;
  onColumnFilterClearAll: (gridId: string, columnId: string) => void;
  onHidePopup: () => void;
  showPopupForColumnId: string;
};

export const DataGridFilterPopupContainer: React.FC<
  DataGridFilterPopupContainerProps
> = ({
  column,
  sort,
  filters,
  gridId,
  onColumnFilterSelection,
  onColumnSort,
  onColumnFilterClearAll,
  onHidePopup,
  showPopupForColumnId,
}) => {
  const canSort = column.isSortable !== false;
  const canFilter =
    column.isFilterable === true &&
    (column.type === 'PEOPLE' || column.type === 'FORM_TEMPLATE');

  const handleSortAscending = useCallback(() => {
    onColumnSort(gridId, column.id, 'ASC');
  }, [column.id, gridId, onColumnSort]);

  const handleSortDescending = useCallback(() => {
    onColumnSort(gridId, column.id, 'DESC');
  }, [column.id, gridId, onColumnSort]);

  if (!canSort && !canFilter) return;
  if (showPopupForColumnId !== column.id) return;

  const elSort = canSort && (
    <div css={styles.group}>
      <div css={styles.title}>
        <Text
          size={13}
          selectable={false}
          uppercase={true}
          weight={900}
          color={color.format(-0.4)}
        >
          {'Sort'}
        </Text>
      </div>
      <Button onClick={handleSortAscending}>
        <div css={styles.itemButton}>
          <Icons.sortAscending />
          <Text
            color={color.format(-0.6)}
            cursor={'inherit'}
            selectable={false}
            size={15}
          >
            {'Sort ascending (A-Z)'}
          </Text>
          {sort === 'Ascending' && (
            <div css={styles.tick}>
              <Icons.tickDone fill={COLORS.GREEN_TICK} />
            </div>
          )}
        </div>
      </Button>
      <Button onClick={handleSortDescending}>
        <div css={styles.itemButton}>
          <Icons.sortDescending />
          <Text
            color={color.format(-0.6)}
            cursor={'inherit'}
            selectable={false}
            size={15}
          >
            {'Sort descending (Z-A)'}
          </Text>
          {sort === 'Descending' && (
            <div css={styles.tick}>
              <Icons.tickDone fill={COLORS.GREEN_TICK} />
            </div>
          )}
        </div>
      </Button>
    </div>
  );

  const elPeopleFilter = canFilter && column.type === 'PEOPLE' && (
    <DataGridPersonFilter
      column={column}
      gridId={gridId}
      filters={filters.filter((filter) => filter.type === 'PERSON')}
      onColumnFilterSelection={onColumnFilterSelection}
      onColumnFilterClearAll={onColumnFilterClearAll}
    />
  );

  const elTemplateFilter = canFilter && column.type === 'FORM_TEMPLATE' && (
    <DataGridFormTemplateFilter
      column={column}
      gridId={gridId}
      filters={filters.filter((filter) => filter.type === 'FORM_TEMPLATE')}
      onColumnFilterSelection={onColumnFilterSelection}
      onColumnFilterClearAll={onColumnFilterClearAll}
    />
  );

  return (
    <Popup onHide={onHidePopup} hideOnClick={false}>
      <div css={styles.base}>
        {elSort}
        {elPeopleFilter}
        {elTemplateFilter}
      </div>
    </Popup>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    width: 500,
  }),
  title: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 10px',
    backgroundColor: color.format(-0.1),
    borderBottom: `solid 1px ${color.format(-0.05)}`,
    alignItems: 'center',
  }),
  group: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  itemButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    padding: '5px 10px',
    minHeight: 28,
  }),
  tick: css({
    marginLeft: 'auto',
  }),
};
