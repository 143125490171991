/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormInsight } from '@se/data/forms/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';

export type SelectLearningAreaPopupProps = {
  onHide: () => void;
  insightLearningAreas: FormInsight[];
  onCreateAction: (insightLearningArea?: FormInsight) => void;
};

export const SelectLearningAreaPopup: React.FC<
  SelectLearningAreaPopupProps
> = ({ onHide, onCreateAction, insightLearningAreas }) => {
  const actionLabel = useTenantLocale((l) => l.label.action.toLowerCase());

  const delayForAnimations = useCallback(() => {
    setTimeout(() => {
      onHide();
    }, 200);
  }, [onHide]);

  if (!insightLearningAreas.length) return;

  return (
    <Popup onHide={delayForAnimations}>
      <div css={styles.base}>
        <div css={styles.title}>
          <Text uppercase={true} size={12} color={color.format(-0.9)}>
            {'Learning Areas'}
          </Text>
          <Text size={10} color={color.format(-0.6)}>
            {`Select to create linked ${actionLabel}`}
          </Text>
        </div>
        <div css={styles.scrollingPanel}>
          {insightLearningAreas.map((insightLearningArea, index) => {
            const isLast = insightLearningAreas.length - 1 === index;

            return (
              <Button
                key={insightLearningArea.id}
                onClick={() => onCreateAction(insightLearningArea)}
                style={{ width: '100%' }}
                tooltip={`Create ${actionLabel} against this learning area`}
              >
                <div css={[styles.selectionItem, !isLast && styles.border]}>
                  <Icons.school fill={COLORS.BLUE} />
                  <div css={styles.selectionItemContent}>
                    <Text color={COLORS.BLUE} size={16} cursor={'inherit'}>
                      {insightLearningArea.label}
                    </Text>
                  </div>
                </div>
              </Button>
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    border: `solid 1px ${color.format(-0.3)}`,
  }),
  scrollingPanel: css({
    maxHeight: 600,
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  title: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#dfe8fd',
    padding: '7px 10px',
    gap: '20%',
    borderBottom: `solid 1px ${color.format(-0.15)}`,
  }),
  selectionItem: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    padding: '12px 30px 12px 10px',
  }),
  selectionItemContent: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: COLORS.BLUE,
    },
  }),
  border: css({
    borderBottom: `solid 1px ${color.format(-0.25)}`,
  }),
};
