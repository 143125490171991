import {
  CoachingSummarySection,
  CoachingSummarySectionFilter,
  CoachingSummarySectionType,
} from '@se/data/forms/types.ts';
import { useCallback, useEffect, useMemo } from 'react';
import { formCoachingSummarySlice } from '../../../redux/formCoachingSummary/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';

type UseFormCoachingSummaryArgs = {
  instanceId: string;
  lineId: string;
  coachingSummaryType: CoachingSummarySectionType;
  filter: CoachingSummarySectionFilter;
  inViewport: boolean;
  hideIfEmpty: boolean;
};

type UseFormCoachingSummaryResult<
  T extends CoachingSummarySection = CoachingSummarySection,
> = {
  loadCoachingSummary: () => void;
  coachingSummary: T;
  coachingSummaryLoading: boolean;
  loadError: boolean;
  showLoadingSkeleton: boolean;
};

export const useFormCoachingSummary = <
  T extends CoachingSummarySection = CoachingSummarySection,
>({
  coachingSummaryType,
  filter,
  hideIfEmpty,
  instanceId,
  inViewport,
  lineId,
}: UseFormCoachingSummaryArgs): UseFormCoachingSummaryResult<T> => {
  const dispatch = useFormsDispatch();

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const coachingSummary = useFormsSelector((state) =>
    state.formCoachingSummary.instanceId === instanceId
      ? (state.formCoachingSummary.sections[lineId] as T)
      : undefined,
  );

  const loadingState = useFormsSelector(
    (state) => state.formCoachingSummary.loading[lineId],
  );

  const loadError = useFormsSelector((state) =>
    Boolean(state.formCoachingSummary.loadErrors[lineId]),
  );

  const loadCoachingSummary = useCallback(() => {
    dispatch(
      formCoachingSummarySlice.loadCoachingSummary({
        instanceId,
        lineId,
        sectionType: coachingSummaryType,
        sectionFilter: filter,
      }),
    );
  }, [coachingSummaryType, dispatch, filter, instanceId, lineId]);

  const coachingSummaryLoading =
    !loadingState || loadingState.stale || loadingState.isLoading;

  const isLoadedAndEmpty = useMemo(() => {
    if (!coachingSummary) return false;

    if (
      coachingSummary.sectionType === 'HighlightsIdentified' &&
      coachingSummaryType === 'HighlightsIdentified'
    )
      return coachingSummary.highlights.length === 0;

    if (
      coachingSummary.sectionType === 'LearningAreasIdentified' &&
      coachingSummaryType === 'LearningAreasIdentified'
    )
      return coachingSummary.learningAreas.length === 0;

    if (
      coachingSummary.sectionType === 'IssuesIdentified' &&
      coachingSummaryType === 'IssuesIdentified'
    )
      return coachingSummary.issues.length === 0;
  }, [coachingSummary, coachingSummaryType]);

  const showLoadingSkeleton = useMemo(() => {
    // Show the loading skeleton if retrying loading while already in an error state.
    if (coachingSummaryLoading && loadError) return true;

    // Errored, don't show.
    if (loadError) return false;

    // Reloading while there is existing data loaded is shown differently to the loading skeleton.
    if (coachingSummaryLoading && isLoadedAndEmpty && !hideIfEmpty) return true;

    // Not initialised, show it.
    return !coachingSummary;
  }, [
    coachingSummary,
    loadError,
    coachingSummaryLoading,
    hideIfEmpty,
    isLoadedAndEmpty,
  ]);

  useEffect(() => {
    if (!instanceSubject) return;
    if (!inViewport) return;

    if (!loadingState || loadingState.stale) loadCoachingSummary();
  }, [
    dispatch,
    instanceId,
    instanceSubject,
    lineId,
    loadCoachingSummary,
    inViewport,
    loadingState,
  ]);

  return {
    coachingSummary,
    coachingSummaryLoading,
    loadCoachingSummary,
    loadError,
    showLoadingSkeleton,
  };
};
