export const COLORS = {
  BLACK: '#000',
  BLUE: '#659CF9',
  ERROR_RED: '#f44336',
  GREEN_TICK: '#7ED321',
  GREEN: '#309830',
  ORANGE_TRANSPARENT: '#EE886618',
  ORANGE_LIGHT: '#EE8866',
  ORANGE_DARK: '#D55E00',
  ORANGE: '#ff661d',
  PURPLE: '#9400D3',
  RED: '#cc0000',
  GOLD: '#daa51e',
  WHITE: '#fff',
  SEE_BRANDING: {
    BLUE: '#5386D9',
    GREEN: '#00CC7A',
    ORANGE: '#FF691F',
    DARK_GREY: '#545454',
  },
  COACHING_REQUIREMENT: {
    MANDATORY: '#ca3311',
    RECOMMENDED: '#ee7733',
    NONE: '#e69f00',
  },
} as const;
