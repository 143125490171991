/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import moment from 'moment';
import React from 'react';

const shortDate = (date: string) => moment(date).format('DD MMMM, YYYY');
const longDate = (date: string) => moment(date).format('h:mm a, DD MMMM, YYYY');

export interface IContentProps {
  uploadedBy: string;
  uploadedAt?: string;
  error?: string;
}
const Content: React.FC<IContentProps> = ({
  uploadedAt,
  error,
  uploadedBy,
}) => {
  const longDateString = uploadedAt && `Submitted at ${longDate(uploadedAt)}.`;
  const shortDateString = uploadedAt && shortDate(uploadedAt);
  return error ? (
    <div css={styles.content}>
      <Text
        size={13}
        weight={FontWeight.normal}
        color={color.format(-0.6)}
        align={'center'}
      >
        {error}
      </Text>
    </div>
  ) : (
    <div css={styles.content}>
      <Text
        size={13}
        weight={900}
        color={color.format(-0.6)}
        ellipsis={true}
        block={true}
        tooltip={longDateString}
      >
        {uploadedBy}
      </Text>
      <Text
        size={12}
        weight={FontWeight.normal}
        color={color.format(-0.6)}
        tooltip={longDateString}
      >
        {shortDateString}
      </Text>
    </div>
  );
};

const styles = {
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '30px 1 0',
    maxWidth: 150,
    textAlign: 'center',
  }),
};

export default React.memo(Content);
