/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormActionStatus,
  FormActionStatusWithOverdue,
  FormActionVerificationStatus,
  FormInsight,
  FormIssue,
} from '@se/data/forms/types.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { MarkdownEditor } from '@seeeverything/ui.primitives/src/components/MarkdownEditor/MarkdownEditor.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import {
  todayInTenantTz,
  useDateContext,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useMemo } from 'react';
import { PersonSelection } from '../../../query/types.ts';
import { FormActionSource } from '../../../redux/formAction/formActionSlice.ts';
import { formActionSlice } from '../../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { PeopleDropdownContainer } from '../../PeopleDropdown/PeopleDropdownContainer.tsx';
import { ActionQuestionsContainer } from './ActionQuestionsContainer.tsx';
import { ActionStatusDropdown } from './ActionStatusDropdown.tsx';
import { ActionTabStrip } from './ActionTabStrip.tsx';
import { ActionVerificationStatusDropdown } from './ActionVerificationStatusDropdown.tsx';

export const ActionDetailV2Container: React.FC = () => {
  const {
    actionLabel,
    assignedTo,
    clearInsight,
    clearIssue,
    computedStatusChange,
    computedVerificationStatusChange,
    description,
    draftStatus,
    draftVerificationStatus,
    dueBy,
    errorAssignedTo,
    errorDescription,
    errorDueBy,
    errorNote,
    errorVerificationNotes,
    formLabel,
    goalId,
    hasAdditionalActionsTemplate,
    initialStatus,
    initialVerificationStatus,
    insight,
    insights,
    instanceId,
    instanceSubject,
    isOpen,
    isSpinning,
    issue,
    issues,
    isUpdateStatusEnabled,
    module,
    note,
    noteLabel,
    permissions,
    showVerificationFields,
    source,
    updateAssignedTo,
    updateDescription,
    updateDueBy,
    updateInsight,
    updateIssue,
    updateNote,
    updateStatus,
    updateVerificationNotes,
    updateVerificationStatus,
    verificationNotes,
  } = useActionFields();

  const dateContext = useDateContext();
  const minimumDueBy = todayInTenantTz(dateContext.tenantTimezone);

  const insightSelections = useMemo(() => {
    if (!insights?.length) return [];
    return insights
      .map(toInsightSelection)
      .concat(getInsightEmptySelection(formLabel));
  }, [formLabel, insights]);

  const insightSelectionValue = useMemo(() => {
    if (!insights?.length) return;
    return insight
      ? toInsightSelection(insight)
      : getInsightEmptySelection(formLabel);
  }, [insights, insight, formLabel]);

  const handleInsightChanged = useCallback(
    (to: ISelectionListItem<IListItemLabel, FormInsight>) => {
      if (!to) return;
      if (to.id === 'N/A') return clearInsight();
      if (!to.value) return;

      updateInsight(to.value);
    },
    [clearInsight, updateInsight],
  );

  const issueSelections = useMemo(() => {
    if (!issues?.length) return [];
    return issues
      .map(toIssueSelection)
      .concat(getIssueEmptySelection(formLabel));
  }, [formLabel, issues]);

  const issueSelectionValue = useMemo(() => {
    if (!issues?.length) return;
    return issue ? toIssueSelection(issue) : getIssueEmptySelection(formLabel);
  }, [issues, issue, formLabel]);

  const handleIssueChanged = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      if (!to) return;
      if (to.id === 'N/A') return clearIssue();

      const instanceIssue = issues?.find((i) => i.id === to.id);
      if (!instanceIssue) return;

      updateIssue(instanceIssue);
    },
    [clearIssue, issues, updateIssue],
  );

  const canShowIssueField = getCanShowIssueField(
    source,
    instanceId,
    module,
    issues,
    permissions,
  );

  const canShowInsightField = getCanShowInsightField(
    source,
    instanceId,
    goalId,
    insights,
    permissions,
  );

  const isEditable = Boolean(permissions?.edit);

  const elDetailsGroup = (
    <div css={[styles.fieldGroup, !isEditable && styles.fieldGroupHover]}>
      <div css={styles.leftColumn}>
        {isEditable ? (
          <MarkdownEditor
            id={'ActionDescription'}
            error={errorDescription}
            includeAllToolbarControls={false}
            markdown={description}
            maxHeight={500}
            onChange={updateDescription}
            title={'Description'}
          />
        ) : (
          <TextField
            id={'description'}
            value={description}
            label={'Description'}
            isEnabled={false}
          />
        )}
        {canShowIssueField && (
          <TextFieldDropdown
            id={'issue'}
            label={'Issue'}
            isEnabled={isEditable}
            selections={issueSelections}
            onChange={handleIssueChanged}
            value={issueSelectionValue}
          />
        )}
        {canShowInsightField && (
          <TextFieldDropdown
            id={'insight'}
            label={'Learning Area'}
            isEnabled={isEditable}
            selections={insightSelections}
            onChange={handleInsightChanged}
            value={insightSelectionValue}
          />
        )}
      </div>
      <div css={styles.rightColumnDetailsGroup}>
        <IconWrapper icon={'notifications'}>
          <DatePicker
            id={'dueDate'}
            dataTest={'forms-actionDialog-dueDatePicker'}
            isEnabled={isEditable}
            value={dueBy}
            onChange={updateDueBy}
            label={'Due Date'}
            minDate={minimumDueBy}
            error={errorDueBy}
            outputDate={'TenantEndOfDay'}
          />
        </IconWrapper>
        <IconWrapper icon={'face'}>
          <PeopleDropdownContainer
            id={'actionAssignedTo'}
            error={errorAssignedTo}
            isEnabled={isOpen}
            label={'Assigned To'}
            onPersonSelected={updateAssignedTo}
            selectedPerson={assignedTo}
            source={'ACTION_PLAN'}
            subject={instanceSubject}
          />
        </IconWrapper>
      </div>
    </div>
  );

  const elStatusGroup = (
    <div css={styles.fieldGroup}>
      <div css={styles.leftColumn}>
        <TextField
          id={'note'}
          value={note}
          label={noteLabel}
          onChange={updateNote}
          multiline={true}
          maxRows={7}
          isEnabled={isOpen}
          error={errorNote}
        />
      </div>
      <div css={errorNote ? styles.rightColumnEndError : styles.rightColumnEnd}>
        <ActionStatusDropdown
          actionLabel={actionLabel}
          computedStatusChange={computedStatusChange}
          draftStatus={draftStatus}
          initialStatus={initialStatus}
          onChange={updateStatus}
          permissions={permissions}
          isEnabled={isUpdateStatusEnabled}
        />
      </div>
    </div>
  );

  const elVerificationGroup = showVerificationFields && (
    <div css={styles.fieldGroup}>
      <div css={styles.leftColumn}>
        <TextField
          id={'verificationNotes'}
          value={verificationNotes}
          label={'Verification Notes'}
          onChange={updateVerificationNotes}
          multiline={true}
          maxRows={7}
          isEnabled={permissions?.verify}
          error={errorVerificationNotes}
        />
      </div>
      <div
        css={
          errorVerificationNotes
            ? styles.rightColumnEndError
            : styles.rightColumnEnd
        }
      >
        <ActionVerificationStatusDropdown
          actionLabel={actionLabel}
          computedStatusChange={computedVerificationStatusChange}
          draftStatus={draftVerificationStatus}
          initialStatus={initialVerificationStatus}
          onChange={updateVerificationStatus}
          permissions={permissions}
          isEnabled={permissions?.verify && initialStatus === draftStatus}
        />
      </div>
    </div>
  );

  return (
    <div css={isSpinning ? [styles.base, styles.spinning] : styles.base}>
      {elDetailsGroup}
      {hasAdditionalActionsTemplate && <ActionQuestionsContainer />}
      {elStatusGroup}
      {elVerificationGroup}
      <ActionTabStrip />
    </div>
  );
};

const useActionFields = () => {
  const dispatch = useFormsDispatch();

  return {
    actionLabel: useFormsSelector(
      (state) => state.tenantState.tenant.locale.label.action,
    ),
    noteLabel: useFormsSelector(
      (state) => state.tenantState.tenant.locale.label.actionNote,
    ),
    isOpen: useFormsSelector((state) =>
      state.formActionV2.existingAction
        ? ['Open', 'Overdue'].includes(state.formActionV2.existingAction.status)
        : true,
    ),
    description: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.description ??
        state.formActionV2.existingAction?.description ??
        '',
    ),
    errorDescription: useFormsSelector(
      (state) => state.formActionV2.errors.action.description,
    ),
    updateDescription: useCallback(
      (to: string) => dispatch(formActionSlice.updateDescription({ to })),
      [dispatch],
    ),
    note: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.note ??
        state.formActionV2.existingAction?.note ??
        '',
    ),
    errorNote: useFormsSelector(
      (state) => state.formActionV2.errors.action.note,
    ),
    updateNote: useCallback(
      (to: string) => dispatch(formActionSlice.updateNote({ to })),
      [dispatch],
    ),
    dueBy: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.dueBy ??
        state.formActionV2.existingAction?.dueBy,
    ),
    errorDueBy: useFormsSelector(
      (state) => state.formActionV2.errors.action.dueBy,
    ),
    updateDueBy: useCallback(
      (to: string) => dispatch(formActionSlice.updateDueBy({ to })),
      [dispatch],
    ),
    permissions: useFormsSelector((state) => state.formActionV2.permissions),
    computedStatusChange: useFormsSelector(
      (state): FormActionStatusWithOverdue => {
        const draftVerificationStatus =
          state.formActionV2.draftChanges.action.verificationStatus;
        return draftVerificationStatus === 'Returned' ? 'Open' : undefined;
      },
    ),
    initialStatus: useFormsSelector((state) =>
      state.formActionV2.existingAction
        ? state.formActionV2.existingAction.status
        : state.formActionV2.draftChanges.action.status,
    ),
    draftStatus: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.status ??
        state.formActionV2.existingAction?.status,
    ),
    updateStatus: useCallback(
      (to: FormActionStatus) => dispatch(formActionSlice.updateStatus({ to })),
      [dispatch],
    ),
    assignedTo: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.assignedTo ??
        state.formActionV2.existingAction?.assignedTo,
    ),
    errorAssignedTo: useFormsSelector(
      (state) => state.formActionV2.errors.action.assignedTo,
    ),
    updateAssignedTo: useCallback(
      (to: PersonSelection) =>
        dispatch(
          formActionSlice.updateAssignedTo({
            to: { id: to.id, name: `${to.firstName} ${to.lastName}` },
          }),
        ),
      [dispatch],
    ),
    instanceSubject: useFormsSelector(
      (state) => state.formActionV2.formInstanceSubject,
    ),
    verificationNotes: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.verificationNotes ??
        state.formActionV2.existingAction?.verificationNotes ??
        '',
    ),
    errorVerificationNotes: useFormsSelector(
      (state) => state.formActionV2.errors.action.verificationNotes,
    ),
    updateVerificationNotes: useCallback(
      (to: string) => dispatch(formActionSlice.updateVerificationNotes({ to })),
      [dispatch],
    ),
    initialVerificationStatus: useFormsSelector((state) =>
      state.formActionV2.existingAction
        ? state.formActionV2.existingAction.verificationStatus
        : state.formActionV2.draftChanges.action.verificationStatus,
    ),
    draftVerificationStatus: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.verificationStatus ??
        state.formActionV2.existingAction?.verificationStatus,
    ),
    computedVerificationStatusChange: useFormsSelector(
      (state): FormActionVerificationStatus => {
        const existingVerificationStatus =
          state.formActionV2.existingAction?.verificationStatus;
        if (!existingVerificationStatus) return;

        const draftStatus = state.formActionV2.draftChanges.action.status;
        if (!draftStatus) return;

        if (existingVerificationStatus === 'Recheck' && draftStatus === 'Open')
          return 'Returned';

        if (
          existingVerificationStatus === 'Returned' &&
          draftStatus === 'Completed'
        )
          return 'Recheck';
      },
    ),
    updateVerificationStatus: useCallback(
      (to: FormActionVerificationStatus) =>
        dispatch(formActionSlice.updateVerificationStatus({ to })),
      [dispatch],
    ),
    showVerificationFields: useFormsSelector((state) => {
      const existingAction = state.formActionV2.existingAction;
      if (!existingAction) return false;

      const canVerify = state.formActionV2.permissions?.verify;
      if (canVerify) return true;

      const verificationStatus = existingAction.verificationStatus;
      if (!verificationStatus) return false;

      return ['Unverified', 'Excluded'].includes(verificationStatus)
        ? false
        : true;
    }),
    isUpdateStatusEnabled: useFormsSelector((state) => {
      const existingAction = state.formActionV2.existingAction;
      if (!existingAction) return true;

      if (!state.formActionV2.permissions?.verify) return true;

      const draftChanges = state.formActionV2.draftChanges;
      return (
        !draftChanges.action.verificationStatus &&
        existingAction.verificationStatus !== 'Verified'
      );
    }),
    insight: useFormsSelector((state) => {
      // Set to cleared.
      if (state.formActionV2.draftChanges.action.insightId === null) return;

      const insightId =
        state.formActionV2.draftChanges.action.insightId ??
        state.formActionV2.existingAction?.insightId;

      return state.formInsight.insights.find((i) => i.id === insightId);
    }),
    clearInsight: useCallback(
      () => dispatch(formActionSlice.clearInsight()),
      [dispatch],
    ),
    updateInsight: useCallback(
      (to: FormInsight) => dispatch(formActionSlice.updateInsight({ to })),
      [dispatch],
    ),
    insights: useFormsSelector((state) =>
      state.formInsight.insights.filter(
        (i) =>
          (i.type === 'Neutral' && i.classificationValues?.length > 0) ||
          i.type === 'LearningArea',
      ),
    ),
    issue: useFormsSelector((state) => {
      // Set to cleared.
      if (state.formActionV2.draftChanges.action.issueId === null) return;

      const issueId =
        state.formActionV2.draftChanges.action.issueId ??
        state.formActionV2.existingAction?.issueId;

      return state.formIssue.issues.find((i) => i.id === issueId);
    }),
    clearIssue: useCallback(
      () => dispatch(formActionSlice.clearIssue()),
      [dispatch],
    ),
    updateIssue: useCallback(
      (to: FormIssue) => dispatch(formActionSlice.updateIssue({ to })),
      [dispatch],
    ),
    issues: useFormsSelector((state) => state.formIssue.issues),
    formLabel: useFormsSelector((state) =>
      state.tenantState.tenant.locale.label.form.toLowerCase(),
    ),
    module: useFormsSelector((state) => state.tenantState.tenant.module),
    isSpinning: useFormsSelector(
      (state) => state.formActionV2.isLoading || state.formActionV2.isSaving,
    ),
    hasAdditionalActionsTemplate: useFormsSelector((state) =>
      Boolean(state.formActionV2.actionTemplate),
    ),
    source: useFormsSelector((state) => state.formActionV2.source),
    instanceId: useFormsSelector(
      (state) => state.formActionV2.formInstance?.id,
    ),
    goalId: useFormsSelector(
      (state) =>
        state.formActionV2.goal?.goalId ??
        state.formActionV2.draftChanges.action.goalId,
    ),
  };
};

const getCanShowInsightField = (
  source: FormActionSource,
  instanceId: string,
  goalId: string,
  instanceInsights?: FormInsight[],
  permissions?: formActionSlice.FormActionPermissions,
) => {
  if (source.type !== 'FORM') return false;
  if (instanceId && source.instanceId !== instanceId) return false;
  if (goalId) return false;
  if (!instanceInsights?.length) return false;
  return Boolean(permissions?.edit);
};

const getCanShowIssueField = (
  source: FormActionSource,
  instanceId: string,
  module: ModuleType,
  issues?: FormIssue[],
  permissions?: formActionSlice.FormActionPermissions,
) => {
  if (source.type !== 'FORM') return false;
  if (instanceId && source.instanceId !== instanceId) return false;
  if (module !== 'compliance') return false;
  if (!issues?.length) return false;
  return Boolean(permissions?.edit);
};

const toInsightSelection = (
  insight: FormInsight,
): ISelectionListItem<IListItemLabel, FormInsight> => ({
  id: insight.id,
  content: {
    text: insight.label,
    description: insight.formSectionName,
  },
  icon: 'school',
  iconColor: {
    default: COLORS.PURPLE,
    selected: COLORS.PURPLE,
  },
  value: insight,
});

const toIssueSelection = (
  issue: FormIssue,
): ISelectionListItem<IListItemLabel, FormIssue> => {
  const coachingRequirementColor =
    issue.issueCoachingRequired === true
      ? COLORS.COACHING_REQUIREMENT.MANDATORY
      : issue.issueCoachingRequired === false
        ? COLORS.COACHING_REQUIREMENT.RECOMMENDED
        : COLORS.COACHING_REQUIREMENT.NONE;

  return {
    id: issue.id,
    content: {
      text: issue.label,
      description: issue.formSectionName,
    },
    icon: 'localLibrary',
    iconColor: {
      default: coachingRequirementColor,
      selected: coachingRequirementColor,
    },
    value: issue,
  };
};

const getInsightEmptySelection = (
  formLabel: string,
): ISelectionListItem<IListItemLabel, FormInsight> => ({
  id: 'N/A',
  content: {
    text: 'No linked insight',
    description: `Not related to any learning areas on this ${formLabel}`,
  },
  icon: 'clear',
  iconColor: { default: '#a0a0a0', selected: '#a0a0a0' },
});

const getIssueEmptySelection = (
  formLabel: string,
): ISelectionListItem<IListItemLabel> => ({
  id: 'N/A',
  content: {
    text: 'No linked issue',
    description: `Not related to any issues on this ${formLabel}`,
  },
  icon: 'clear',
  iconColor: { default: '#a0a0a0', selected: '#a0a0a0' },
});

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 5,
  }),
  spinning: css({
    pointerEvents: 'none',
  }),
  fieldGroup: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  }),
  fieldGroupHover: css({
    margin: -10,
    padding: 10,
    ':hover': {
      backgroundColor: color.format(-0.06),
    },
  }),
  leftColumn: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    flex: '1 1 65%',
    marginBottom: 7.5,
  }),
  rightColumnDetailsGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flex: '1 1 35%',
  }),
  rightColumnEnd: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 18,
    flex: '1 1 35%',
  }),
  rightColumnEndError: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 18,
    flex: '1 1 35%',
    paddingBottom: 25,
  }),
};
