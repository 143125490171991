import { datadogRum } from '@datadog/browser-rum';
import { RumMonitor } from '../rumMonitor.ts';
import { MonitoringUser } from '../types.ts';
import { DatadogInitOptions } from './types.ts';

export class DatadogApi extends RumMonitor {
  constructor() {
    super();
  }

  init(options: DatadogInitOptions) {
    datadogRum.init({
      site: 'datadoghq.com',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      startSessionReplayRecordingManually: true,
      ...options,
    });
    datadogRum.onReady(() => (this._isInitialized = true));
    return this;
  }

  enableCrashReporting() {
    this._isCrashReportingEnabled = true;
    return this;
  }

  enableRum() {
    this._isRumEnabled = true;
    return this;
  }

  setCustomData(customData: object) {
    if (!super.checkRum()) return this;
    datadogRum.setGlobalContext(customData);
    return this;
  }

  setUser({ id }: MonitoringUser) {
    if (!super.checkRum()) return this;
    datadogRum.setUser({ id });
    return this;
  }

  addError(...items: any[]) {
    if (!super.checkCrashReporting()) return;
    items.forEach((error) => datadogRum.addError(error));
  }

  addAction(action: string, context?: object) {
    if (!super.checkRum()) return;
    datadogRum.addAction(action, context);
  }

  addPageView(path: string, context?: object) {
    if (!super.checkRum()) return;
    datadogRum.addAction(`Virtual Page View ${path}`, { path, ...context });
  }
}
