/**
 * Adds a suffix to an input string if the string doesn't already end with
 * one of the supplied characters.
 * Default values are set to most common use-case (i.e. ending as a type of question).
 */
export const conditionalSuffix = (
  input: string,
  checkSuffix: string[] = ['?', ':', '.'],
  suffix: string = ':',
) => {
  if (!input) return;

  if (checkSuffix.some((check) => input.endsWith(check))) return input;

  return `${input}${suffix}`;
};
