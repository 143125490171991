/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback } from 'react';
import { DOCUMENT_UPLOAD } from '../common/constants.ts';
import { FormFilesPanel } from '../components/FormFilesPanel/FormFilesPanel.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import {
  fileDeleteRequest,
  fileDownloadRequest,
  fileUpload,
  fileUploadRejected,
} from '../redux/form-file/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { IFileUploadLine } from '../types/types.ts';

export interface IFormFilesFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  line: IFileUploadLine;
}
export const FormFilesFactoryContainer: React.FC<
  IFormFilesFactoryContainerProps
> = ({ canUpdateInstance, instanceId, line }) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const user = useFormsSelector(
    (state) => state.tenantState.tenant.authenticatedUser,
  );
  const files = useFormsSelector((state) =>
    Object.values(state.formFile.files),
  );
  const rejections = useFormsSelector((state) => state.formFile.rejections);

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      dispatch(fileDeleteRequest(fileId, instanceId));
    },
    [dispatch, instanceId],
  );

  const handleDownloadFile = useCallback(
    (fileId: string) => {
      dispatch(fileDownloadRequest(fileId, instanceId));
    },
    [dispatch, instanceId],
  );

  const handleUploadFilesRejected = useCallback(
    (rejectedFiles: File[]) =>
      dispatch(fileUploadRejected(rejectedFiles, DOCUMENT_UPLOAD.maxSize)),
    [dispatch],
  );

  const handleFileUpload = useCallback(
    (uploadFiles: File[]) => {
      const uploadedByName = user ? `${user.firstName} ${user.lastName}` : '';

      uploadFiles.forEach((uploadFile) =>
        dispatch(
          fileUpload({
            fileId: uuid.generate(),
            formInstanceId: instanceId,
            file: uploadFile,
            uploadedByName,
          }),
        ),
      );
    },
    [user, dispatch, instanceId],
  );

  return (
    <FormListItemWrapped
      id={line.id}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      marginTop={60}
      marginBottom={15}
      bulletTop={-13.5}
      bulletLeft={0}
      bullet={'block'}
      isVisible={line.isEnabled && isVisible}
    >
      <SectionTitle text={line.title} />
      <div css={css({ paddingBottom: 40 })} />
      <FormFilesPanel
        allowedMimeTypes={DOCUMENT_UPLOAD.allowedMimeTypes}
        allowedTypesReadable={DOCUMENT_UPLOAD.allowedTypesReadable}
        canDelete={canUpdateInstance}
        canUpload={canUpdateInstance}
        emptySize={'small'}
        files={files}
        maxSize={DOCUMENT_UPLOAD.maxSize}
        onDelete={handleDeleteFile}
        onDownload={handleDownloadFile}
        onFilesRejected={handleUploadFilesRejected}
        onFilesUpload={handleFileUpload}
        rejections={rejections}
      />
    </FormListItemWrapped>
  );
};
