import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { useUtilSelector } from '@seeeverything/ui.util/src/redux/store.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { dashboardsQuery } from '../query/index.ts';
import {
  DashboardCommentDataKeys,
  DashboardTimespanVariable,
} from '../query/types.ts';

type UseQueryCommentsListArgs = {
  dataSetId: string;
  type: 'FULL_SHEET' | 'DASHBOARD_COMPONENT';
  entityId: string;
  entityType: 'person' | 'team';
  commentListId: string;
  dataKeys: DashboardCommentDataKeys;
  orderBy: OrderBy[];
  pageSize: number;
  templateId: string;
  timespan: DashboardTimespanVariable;
  isQueryEnabled: boolean;
};

export const useQueryCommentsList = (args: UseQueryCommentsListArgs) => {
  const client = useGraphQL();
  const module = useUtilSelector((state) => state.tenantState.tenant.module);

  const commentsPageQueryResponse = useInfiniteQuery({
    queryKey: [
      {
        key: `${module}-COMMENTS_LIST-${args.type}-${args.commentListId}`,
        dataSetId: args.dataSetId,
        entityId: args.entityId,
        entityType: args.entityType,
        commentListId: args.commentListId,
        dataKeys: args.dataKeys,
        module: module,
        orderBy: args.orderBy,
        pageSize: args.pageSize,
        templateId: args.templateId,
        timespan: args.timespan,
      },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const {
        dataSetId,
        entityId,
        entityType,
        commentListId,
        dataKeys,
        orderBy,
        pageSize,
        templateId,
        timespan,
      } = queryKey[0];

      const response = await dashboardsQuery.getCommentsList(client, {
        dataSetId,
        entityId,
        entityType,
        gridId: commentListId,
        dataKeys,
        orderBy,
        pagination: { pageSize, pageNumber: pageParam ?? 1 },
        templateId,
        timespan,
      });

      if (!response.isSuccess)
        throw new Error(
          `Error while fetching comments list for ${entityType} with id ${entityId}`,
        );

      return {
        pageInfo: response.data.pageInfo,
        comments: response.data.comments,
      };
    },
    getNextPageParam: (latestPage) =>
      latestPage ? latestPage.pageInfo.currentPage + 1 : 1,
    enabled: args.isQueryEnabled,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
  });

  const comments = useMemo(() => {
    if (!commentsPageQueryResponse.data) return [];

    return commentsPageQueryResponse.data.pages
      .filter(Boolean)
      .flatMap((page) => page.comments);
  }, [commentsPageQueryResponse.data]);

  return {
    comments,
    commentsHasNextPage: Boolean(
      commentsPageQueryResponse.data?.pages?.[
        commentsPageQueryResponse.data.pages.length - 1
      ]?.pageInfo.hasNextPage,
    ),
    isLoadingComments: commentsPageQueryResponse.isLoading,
    isFetchingComments: commentsPageQueryResponse.isFetching,
    isFetchingCommentsNextPage: commentsPageQueryResponse.isFetchingNextPage,
    fetchNextCommentsPage: commentsPageQueryResponse.fetchNextPage,
    isErrorComments: commentsPageQueryResponse.isError,
  };
};
