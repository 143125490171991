/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { useHandleAnswerVisibility } from '../../hooks/useHandleAnswerVisibility.ts';
import { formInstanceAnswerChange } from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, IOptionsAnswerLine } from '../../types/types.ts';
import { FormListItemWrapped } from '../FormListItem/FormListItemWrapped.tsx';
import { CreateAutomatedAction } from './components/CreateAutomatedAction.tsx';
import { InsightDetails } from './components/InsightDetails.tsx';
import { IssueDetails } from './components/IssueDetails.tsx';
import { OptionsAnswer } from './components/OptionsAnswer.tsx';

export type FormLineOptionProps = {
  canUpdateInstance: boolean;
  instanceId: string;
  line: IOptionsAnswerLine;
};

export const FormLineOption: React.FC<FormLineOptionProps> = ({
  canUpdateInstance,
  instanceId,
  line,
}) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [line.id],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const value = answer?.value?.toString();

  const hasIssues = line.options.some((o) => Boolean(o.issue));
  const hasInsights = line.options.some((o) => Boolean(o.insight));

  const selectedOption = line.options.find((option) => option.id === value);

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const issue = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    const formAnswer = instance?.answers?.[line.id];
    if (!formAnswer) return;

    return state.formIssue.issues.find((i) => i.answerId === answer.id);
  });

  const insight = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    const formAnswer = instance?.answers?.[line.id];
    if (!formAnswer) return;

    return state.formInsight.insights.find((i) => i.answerId === answer.id);
  });

  const isLoadingIssue = useMemo(() => {
    if (!answer) return false;

    const hasIssue = line.options.some(
      (o) => o.id === value && Boolean(o.issue),
    );
    if (!hasIssue) return false;

    return issue ? issue.isLoading : true;
  }, [answer, issue, line.options, value]);

  const isLoadingInsight = useFormsSelector((state) => {
    if (!answer) return false;

    const insightId = state.formInsight.insights.find(
      (i) => i.answerId === answer.id,
    )?.id;
    if (!insightId) return false;

    return Boolean(state.formInsight.insightLoading[insightId]);
  });

  const isLoading = isLoadingIssue || isLoadingInsight;

  const canAddActions = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;

    return (
      instance.status.status !== 'Completed' &&
      instance.permissions.edit === true
    );
  });

  const showCreateButton = useMemo(() => {
    if (!isMouseOver) return false;
    if (isLoading) return false;
    if (!canAddActions) return false;

    if (issue) return true;

    if (!insight) return false;
    if (insight.type === 'Highlight') return false;
    if (insight.type === 'Neutral' && insight.classificationValues.length === 0)
      return false;

    return true;
  }, [canAddActions, insight, isLoading, isMouseOver, issue]);

  const updateAnswer = useCallback(
    (to: string, displayValue: string) => {
      if (!isVisible) return;

      const toAnswerValue = to.toString();

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: toAnswerValue,
        displayValue,
        lineId: line.id,
        type: 'optionsAnswer',
        group: line.group,
        subType: line.multiSelect ? 'CHECKBOX' : 'RADIO',
      };

      const option = line.options.find(
        (o) => o.id?.toString() === toAnswerValue,
      );
      const hasIssueInsight = Boolean(option?.issue || option?.insight);

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
                postChangeQueryIssueInsightId: hasIssueInsight,
              }
            : {
                type: 'CREATE',
                toAnswer,
                postChangeQueryIssueInsightId: hasIssueInsight,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      line.multiSelect,
      line.options,
      newAnswerId,
    ],
  );

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      key={line.id}
      highlightOnHover={line.highlightOnHover}
      bulletTop={line.direction === 'vertical' ? 6 : 31}
      isVisible={isVisible}
    >
      <div
        css={styles.optionsBase}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isLoading && (
          <div css={styles.loadingMask}>
            <Spinner center={true} />
          </div>
        )}
        <div
          css={
            isLoading ? [styles.options, styles.loadingFade] : styles.options
          }
        >
          <OptionsAnswer
            direction={line.direction}
            error={error}
            isEnabled={canUpdateInstance}
            multiSelect={line.multiSelect}
            onChange={updateAnswer}
            options={line.options}
            question={line.question}
            value={value}
          />
          {hasIssues && (
            <IssueDetails
              answerId={answer?.id}
              instanceId={instanceId}
              issueDefinition={selectedOption?.issue}
            />
          )}
          {hasInsights && (
            <InsightDetails
              answerId={answer?.id}
              instanceId={instanceId}
              insightDefinition={selectedOption?.insight}
            />
          )}
          <CreateAutomatedAction
            insight={insight}
            instanceId={instanceId}
            isShowing={showCreateButton}
            issue={issue}
            lineId={line.id}
            onHideDropdown={handleMouseLeave}
          />
        </div>
      </div>
    </FormListItemWrapped>
  );
};

const styles = {
  optionsBase: css({
    position: 'relative',
  }),
  loadingMask: css({
    position: 'absolute',
    display: 'flex',
    inset: 0,
  }),
  options: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  loadingFade: css({
    opacity: 0.5,
    pointerEvents: 'none',
  }),
};
