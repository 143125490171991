/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanGoal } from '@se/data/forms/types.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { getCategoryOverdueLabel } from '../util.ts';
import { AddGoal } from './AddGoal.tsx';
import { Goal } from './Goal.tsx';
import { Section } from './Section.tsx';

export type GoalCategoryProps = {
  canCreateGoals: boolean;
  goals: FormActionPlanGoal[];
  goalCategoryId?: string;
  goalCategoryArchived: boolean;
  instanceId: string;
  isDefaultExpanded: boolean;
  name: string;
};

export const GoalCategory: React.FC<GoalCategoryProps> = ({
  canCreateGoals,
  goals,
  goalCategoryId,
  goalCategoryArchived,
  instanceId,
  isDefaultExpanded,
  name,
}) => {
  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const numberOfOverdueGoals = goals.filter(
    (goal) => goal.status === 'Overdue',
  ).length;

  const numberOfOverdueActions = goals
    .flatMap((goal) => goal.actions)
    .filter((action) => action.status === 'Overdue').length;

  const overdueLabel = useMemo(() => {
    if (numberOfOverdueGoals === 0 && numberOfOverdueActions === 0) return;

    return getCategoryOverdueLabel(
      numberOfOverdueGoals,
      numberOfOverdueActions,
      actionLabel,
    );
  }, [actionLabel, numberOfOverdueActions, numberOfOverdueGoals]);

  return (
    <Section
      overdueLabel={overdueLabel}
      title={`${name} (${goals.length})`}
      id={name}
      isDefaultExpanded={isDefaultExpanded}
      isArchived={goalCategoryArchived}
    >
      <div css={styles.base}>
        {goals.map((goal) => (
          <Goal
            goal={goal}
            instanceId={instanceId}
            key={`Goal-${goal.id}`}
            source={{ type: 'FORM', instanceId }}
          />
        ))}
        {canCreateGoals && !goalCategoryArchived && (
          <AddGoal
            message={goals.length ? 'Add another goal.' : 'Add a new goal.'}
            instanceId={instanceId}
            goalCategory={goalCategoryId && { id: goalCategoryId, name }}
          />
        )}
      </div>
    </Section>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  }),
};
