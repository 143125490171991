/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormAnswerAutomatedAction } from '@se/data/forms/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback } from 'react';
import { useFormsSelector } from '../../redux/store.ts';

export type SelectAutomatedActionPopupProps = {
  onHide: () => void;
  automatedActions: FormAnswerAutomatedAction[];
  onCreateAction: (automatedAction?: FormAnswerAutomatedAction) => void;
  showIssueLabel: boolean;
};

export const SelectAutomatedActionPopup: React.FC<
  SelectAutomatedActionPopupProps
> = ({ onHide, onCreateAction, automatedActions, showIssueLabel }) => {
  const actionLabel = useTenantLocale((l) => l.label.action.toLowerCase());

  const delayForAnimations = useCallback(() => {
    setTimeout(() => {
      onHide();
    }, 200);
  }, [onHide]);

  const issues = useFormsSelector((state) => state.formIssue.issues);

  if (!automatedActions?.length) return;

  return (
    <Popup onHide={delayForAnimations}>
      <div css={styles.base}>
        <div css={styles.title}>
          <Text
            uppercase={true}
            size={12}
            color={color.format(-0.9)}
          >{`Recommended ${str.plural(actionLabel)}`}</Text>
          <Text size={10} color={color.format(-0.6)}>
            {`Select to create linked ${actionLabel}`}
          </Text>
        </div>
        <div css={styles.scrollingPanel}>
          {automatedActions.map((automatedAction, index) => {
            const isLast = automatedActions.length - 1 === index;

            const issue = issues?.find((i) => i.id === automatedAction.issueId);
            const label =
              showIssueLabel && issue && issue?.label !== automatedAction.label
                ? `${issue.label} - ${automatedAction.label}`
                : automatedAction.label;

            return (
              <Button
                key={automatedAction.id}
                onClick={() => onCreateAction(automatedAction)}
                style={{ width: '100%' }}
              >
                <div css={[styles.selectionItem, !isLast && styles.border]}>
                  <Icons.bulbAndSparkles fill={COLORS.BLUE} />
                  <div css={styles.selectionItemContent}>
                    <Text color={COLORS.BLUE} size={16} cursor={'inherit'}>
                      {label}
                    </Text>
                    <Text
                      color={COLORS.BLUE}
                      size={15}
                      cursor={'inherit'}
                      weight={300}
                      ellipsis={true}
                      style={styles.actionDescription}
                    >
                      {str.removeMarkdownAndSpecialCharacters(
                        automatedAction.description,
                      )}
                    </Text>
                  </div>
                </div>
              </Button>
            );
          })}
        </div>
        <Button onClick={() => onCreateAction()} style={{ width: '100%' }}>
          <div css={[styles.selectionItem, styles.borderTop]}>
            <Icons.plus />
            <div css={styles.selectionItemContent}>
              <Text
                color={COLORS.BLUE}
                size={16}
                weight={400}
                cursor={'inherit'}
              >
                {`Create my own ${actionLabel}...`}
              </Text>
            </div>
          </div>
        </Button>
      </div>
    </Popup>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    border: `solid 1px ${color.format(-0.3)}`,
  }),
  scrollingPanel: css({
    maxHeight: 600,
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  title: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#ffefe7',
    padding: '7px 10px',
    gap: '20%',
    borderBottom: `solid 1px ${color.format(-0.15)}`,
  }),
  actionDescription: css({
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    textWrap: 'wrap',
    overflow: 'hidden',
    display: '-webkit-box',
    whiteSpace: 'wrap',
  }),
  selectionItem: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    padding: '12px 30px 12px 10px',
  }),
  selectionItemContent: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: COLORS.BLUE,
    },
  }),
  border: css({
    borderBottom: `solid 1px ${color.format(-0.25)}`,
  }),
  borderTop: css({
    borderTop: `solid 1px ${color.format(-0.35)}`,
  }),
};
