import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult, SimpleEntity } from '../../types.ts';
import {
  CoachingSummarySectionFilter,
  CoachingSummarySectionInsight,
  CoachingSummarySectionIssue,
  CoachingSummarySectionType,
  FormActionStatus,
  FormInstanceStatus,
  InsightType,
} from '../types.ts';

type GetCoachingSummaryArgs = {
  instanceId: string;
  sectionType: CoachingSummarySectionType;
  sectionFilter: CoachingSummarySectionFilter;
};

export const getCoachingSummarySection = async (
  client: IGraphQLClient,
  args: GetCoachingSummaryArgs,
): Promise<
  QueryResult<CoachingSummarySectionInsight[] | CoachingSummarySectionIssue[]>
> => {
  if (args.sectionType === 'IssuesIdentified')
    return getCoachingSummaryIssues(
      client,
      args.instanceId,
      args.sectionFilter,
    );

  return getCoachingSummaryInsights(
    client,
    args.instanceId,
    args.sectionType,
    args.sectionFilter,
  );
};

const getCoachingSummaryIssues = async (
  client: IGraphQLClient,
  instanceId: string,
  sectionFilter: CoachingSummarySectionFilter,
): Promise<QueryResult<CoachingSummarySectionIssue[]>> => {
  try {
    const response = await client.query<{
      forms: {
        coachingSummarySectionIssues: Array<{
          id: string;
          issueCoachingRequired?: boolean;
          instance: {
            id: string;
            dueBy: string;
            name: string;
            status: FormInstanceStatus;
          };
          label: string;
          notesDetail: { label: string; value: string };
          classifications?: string[];
          answer?: {
            id: string;
            key: string;
            displayValue?: string;
            value: string;
          };
          actions: Array<{
            id: string;
            assignedTo: SimpleEntity;
            description: string;
            dueBy?: string;
            status: FormActionStatus;
          }>;
          reviewActions: Array<{
            id: string;
            assignedTo: SimpleEntity;
            description: string;
            dueBy?: string;
            status: FormActionStatus;
          }>;
          formSectionName: string;
          formQuestionText: string;
        }>;
      };
    }>({
      query: gql`
        query CoachingSummarySectionIssues(
          $instanceId: ID!
          $sectionFilter: FormCoachingSummarySectionFilter!
        ) {
          forms {
            coachingSummarySectionIssues(
              instanceId: $instanceId
              sectionFilter: $sectionFilter
            ) {
              id
              issueCoachingRequired
              instance {
                id
                dueBy
                name
                status
              }
              label
              notesDetail {
                label
                value
              }
              classifications
              answer {
                id
                key
                displayValue
                value
              }
              actions {
                id
                assignedTo {
                  id
                  name
                }
                description
                dueBy
                status
              }
              reviewActions {
                id
                assignedTo {
                  id
                  name
                }
                description
                dueBy
                status
              }
              formSectionName
              formQuestionText
            }
          }
        }
      `,
      variables: {
        instanceId,
        sectionFilter,
      },
      fetchPolicy: 'network-only',
    });

    const sectionIssues = response.data.forms.coachingSummarySectionIssues;

    if (!sectionIssues)
      return {
        isSuccess: false,
        errorReason: 'NOT_FOUND',
      };

    return {
      isSuccess: true,
      data: sectionIssues.map((sectionIssue) => ({
        id: sectionIssue.id,
        issueCoachingRequired: sectionIssue.issueCoachingRequired,
        instance: sectionIssue.instance
          ? {
              id: sectionIssue.instance.id,
              dueBy: sectionIssue.instance.dueBy,
              name: sectionIssue.instance.name,
              status: sectionIssue.instance.status,
            }
          : undefined,
        label: sectionIssue.label,
        notesDetail: sectionIssue.notesDetail
          ? {
              label: sectionIssue.notesDetail.label,
              value: sectionIssue.notesDetail.value,
            }
          : undefined,
        classifications: sectionIssue.classifications ?? [],
        answer: sectionIssue.answer
          ? {
              id: sectionIssue.answer.id,
              key: sectionIssue.answer.key,
              displayValue: sectionIssue.answer.displayValue,
              value: sectionIssue.answer.value,
            }
          : undefined,
        actions: (sectionIssue.actions ?? []).map((action) => ({
          id: action.id,
          assignedTo: action.assignedTo
            ? { id: action.assignedTo.id, name: action.assignedTo.name }
            : undefined,
          description: action.description,
          dueBy: action.dueBy,
          status: action.status,
        })),
        reviewActions: (sectionIssue.reviewActions ?? []).map((action) => ({
          id: action.id,
          assignedTo: action.assignedTo
            ? { id: action.assignedTo.id, name: action.assignedTo.name }
            : undefined,
          description: action.description,
          dueBy: action.dueBy,
          status: action.status,
        })),
        formSectionName: sectionIssue.formSectionName,
        formQuestionText: sectionIssue.formQuestionText,
      })),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query coaching summary section (Issues ${sectionFilter}) for instance ${instanceId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

const getCoachingSummaryInsights = async (
  client: IGraphQLClient,
  instanceId: string,
  sectionType: 'LearningAreasIdentified' | 'HighlightsIdentified',
  sectionFilter: CoachingSummarySectionFilter,
): Promise<QueryResult<CoachingSummarySectionInsight[]>> => {
  try {
    const response = await client.query<{
      forms: {
        coachingSummarySectionInsights: Array<{
          id: string;
          instance: {
            id: string;
            dueBy: string;
            name: string;
            status: FormInstanceStatus;
          };
          label: string;
          type: InsightType;
          notes: { label: string; value: string };
          classifications: { label: string; values: string[] };
          answer?: {
            id: string;
            key: string;
            displayValue?: string;
            value: string;
          };
          actions: Array<{
            id: string;
            assignedTo: SimpleEntity;
            description: string;
            dueBy?: string;
            status: FormActionStatus;
          }>;
          formSectionName: string;
          formQuestionText: string;
        }>;
      };
    }>({
      query: gql`
        query CoachingSummarySectionInsights(
          $instanceId: ID!
          $sectionType: FormCoachingSummarySectionType!
          $sectionFilter: FormCoachingSummarySectionFilter!
        ) {
          forms {
            coachingSummarySectionInsights(
              instanceId: $instanceId
              sectionType: $sectionType
              sectionFilter: $sectionFilter
            ) {
              id
              instance {
                id
                dueBy
                name
                status
              }
              label
              type
              notes {
                label
                value
              }
              classifications {
                label
                values
              }
              answer {
                id
                key
                displayValue
                value
              }
              actions {
                id
                assignedTo {
                  id
                  name
                }
                description
                dueBy
                status
              }
              formSectionName
              formQuestionText
            }
          }
        }
      `,
      variables: {
        instanceId,
        sectionType,
        sectionFilter,
      },
      fetchPolicy: 'network-only',
    });

    const sectionInsights = response.data.forms.coachingSummarySectionInsights;
    if (!sectionInsights)
      return {
        isSuccess: false,
        errorReason: 'NOT_FOUND',
      };

    return {
      isSuccess: true,
      data: sectionInsights.map((sectionInsight) => ({
        id: sectionInsight.id,
        instance: sectionInsight.instance
          ? {
              id: sectionInsight.instance.id,
              dueBy: sectionInsight.instance.dueBy,
              name: sectionInsight.instance.name,
              status: sectionInsight.instance.status,
            }
          : undefined,
        label: sectionInsight.label,
        type: sectionInsight.type,
        notes: sectionInsight.notes
          ? {
              label: sectionInsight.notes.label,
              value: sectionInsight.notes.value,
            }
          : undefined,
        classifications: sectionInsight.classifications
          ? {
              label: sectionInsight.classifications.label,
              values: sectionInsight.classifications.values,
            }
          : undefined,
        answer: sectionInsight.answer
          ? {
              id: sectionInsight.answer.id,
              key: sectionInsight.answer.key,
              displayValue: sectionInsight.answer.displayValue,
              value: sectionInsight.answer.value,
            }
          : undefined,
        actions: (sectionInsight.actions ?? []).map((action) => ({
          id: action.id,
          assignedTo: action.assignedTo
            ? { id: action.assignedTo.id, name: action.assignedTo.name }
            : undefined,
          description: action.description,
          dueBy: action.dueBy,
          status: action.status,
        })),
        formSectionName: sectionInsight.formSectionName,
        formQuestionText: sectionInsight.formQuestionText,
      })),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query coaching summary section (${sectionType} ${sectionFilter}) for instance ${instanceId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
