/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { ChangeEvent } from 'react';

export type PasswordValidation = {
  label: string;
  valid: boolean;
};

export interface IChangePasswordInAppProps {
  onChangeCurrentPassword: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeNewPassword: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeNewPasswordRepeated: (event: ChangeEvent<HTMLInputElement>) => void;

  currentPassword: string;
  newPassword: string;
  newPasswordRepeated: string;

  validationResults: PasswordValidation[];
}

export const ChangePasswordInApp = ({
  onChangeCurrentPassword,
  onChangeNewPassword,
  onChangeNewPasswordRepeated,
  currentPassword,
  newPassword,
  newPasswordRepeated,
  validationResults,
}: IChangePasswordInAppProps) => (
  <div css={styles.base}>
    <input autoComplete={'username'} name={'username'} type={'hidden'} />
    <div css={styles.inputLine}>
      <Text weight={400} size={14}>
        {'Current password:'}
      </Text>
      <input
        css={styles.passwordInput}
        autoComplete={'current-password'}
        name={'current-password'}
        onChange={onChangeCurrentPassword}
        required={true}
        type={'password'}
        value={currentPassword}
      />
    </div>
    <div css={styles.inputLine}>
      <Text weight={400} size={14}>
        {'New password:'}
      </Text>
      <input
        css={styles.passwordInput}
        autoComplete={'new-password'}
        name={'new-password'}
        onChange={onChangeNewPassword}
        required={true}
        type={'password'}
        value={newPassword}
      />
    </div>
    <div css={styles.inputLine}>
      <Text weight={400} size={14}>
        {'New password again:'}
      </Text>
      <input
        css={styles.passwordInput}
        autoComplete={'new-password'}
        name={'new-password'}
        onChange={onChangeNewPasswordRepeated}
        required={true}
        type={'password'}
        value={newPasswordRepeated}
      />
    </div>
    <div css={styles.validation}>
      {validationResults.map(({ label, valid }) => (
        <Text
          key={label}
          color={valid ? COLORS.GREEN : COLORS.ERROR_RED}
          size={11}
        >
          {`${valid ? '✓' : '✖'} ${label}`}
        </Text>
      ))}
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
  }),
  inputLine: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: 5,
  }),
  validation: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  passwordInput: css({
    padding: 4,
    border: `solid 1px ${color.format(-0.2)}`,
    borderRadius: 3,
  }),
};
