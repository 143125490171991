/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDashboardsSelector } from '../../redux/store.ts';
import { DashboardHeaderContent } from './components/DashboardHeaderContent.tsx';

export interface IDashboardHeaderProps {
  height: number;
}

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
  height,
}) => {
  const header = useDashboardsSelector((state) => state.dashboardsV2.HEADER);

  const computedStyles = {
    base: css({
      position: 'relative',
      display: 'flex',
      height,
      borderBottom: `solid 2px ${color.format(-0.1)}`,
    }),
  };

  const isLoading = useMemo(() => {
    if (!header) return true;
    if (!header.dataState.isLoaded) return true;
    if (header.dataState.isLoading) return true;
    if (!header.dataState.data?.data) return true;

    return false;
  }, [header]);

  const client = useGraphQL();
  const tenantConfig = useDashboardsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const templateEntityId = useDashboardsSelector(
    (state) => state.dashboardsV2.template?.entityId,
  );

  const teamId = useMemo(() => {
    if (isLoading) return;

    const kind = header.dataState.data.data.kind;
    return kind === 'team'
      ? templateEntityId
      : header?.dataState.data?.data?.team?.id;
  }, [header, isLoading, templateEntityId]);

  const { data } = useQuery({
    enabled: !isLoading && Boolean(teamId),
    queryKey: [
      {
        key: 'dashboard.teams',
        tenantConfig,
        parentAndChildTeamsFilter: teamId,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const teamsResponse = await orgHierarchyQuery.getTeams(client, {
        tenantConfig: queryKey[0].tenantConfig,
        includeInactive: false,
        parentAndChildTeamsFilter: queryKey[0].parentAndChildTeamsFilter,
        excludeNonManagerMemberships: true,
      });

      if (!teamsResponse.isSuccess) return null;

      return teamsResponse.data.teams.map((t) => ({ id: t.id, path: t.path }));
    },
    placeholderData: keepPreviousData,
  });

  return (
    <div css={computedStyles.base}>
      {isLoading ? (
        <Spinner center={true} />
      ) : (
        <DashboardHeaderContent height={height} relatedTeams={data} />
      )}
    </div>
  );
};
