/** @jsxImportSource @emotion/react */
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo } from 'react';
import { FormBulletType } from '../../types/types.ts';
import { FormListItem } from './FormListItem.tsx';

export interface IFormListItemWrapperProps {
  bullet?: FormBulletType;
  bulletLeft?: number;
  bulletTop?: number;
  children?: React.ReactNode;
  highlightOnHover?: boolean;
  id: string;
  isVisible?: boolean;
  onClick?: () => void;
  parentId?: string;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  marginTop?: number;
  marginBottom?: number;
}

const View: React.FC<IFormListItemWrapperProps> = ({
  bullet,
  bulletLeft,
  bulletTop,
  children,
  highlightOnHover,
  id,
  isVisible = true,
  marginBottom = 5,
  marginTop = 5,
  onClick,
  paddingBottom = 0,
  paddingLeft,
  paddingRight,
  paddingTop = 6,
  parentId,
}) => {
  const { isPdfExport } = usePdfExportContext();
  return (
    <FormListItem
      bullet={isPdfExport ? 'none' : bullet}
      bulletLeft={bulletLeft}
      bulletTop={bulletTop}
      highlightOnHover={highlightOnHover}
      id={id}
      isVisible={isVisible}
      marginBottom={marginBottom}
      marginTop={marginTop}
      onClick={onClick}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
      parentId={parentId}
    >
      {children}
    </FormListItem>
  );
};

export const FormListItemWrapped = memo(View, propsAreEqualFastCompare);
