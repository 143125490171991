/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CoachingSummarySectionFilter,
  CoachingSummarySectionInsight,
} from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { groupBy, prop } from 'ramda';
import { CoachingSummaryHighlightGroup } from './CoachingSummaryHighlightGroup.tsx';

export type CoachingSummaryHighlightsProps = {
  filter: CoachingSummarySectionFilter;
  highlights: CoachingSummarySectionInsight[];
  instanceId: string;
  isReloading: boolean;
};

export const CoachingSummaryHighlights: React.FC<
  CoachingSummaryHighlightsProps
> = ({ instanceId, highlights, isReloading, filter }) => {
  const { isPdfExport } = usePdfExportContext();
  const formLabel = useTenantLocale((l) =>
    str.plural(l.label.form.toLowerCase()),
  );

  const highlightGroups = Object.entries(groupBy(prop('label'), highlights));

  return (
    <div css={[styles.base, isReloading && styles.reloading]}>
      {highlightGroups.length === 0 && (
        <Text>{'No highlights identified.'}</Text>
      )}
      {highlightGroups.length > 0 && (
        <>
          <Text>
            {filter === 'Last3Months'
              ? `Below are all highlights that have been identified in ${formLabel} in the last 3 months:`
              : `Below are all highlights that have been identified in ${formLabel} over this period:`}
          </Text>
          <div css={styles.groups}>
            {highlightGroups.map(([label, group]) => (
              <CoachingSummaryHighlightGroup
                key={label}
                label={label}
                highlights={group}
                instanceId={instanceId}
                sectionExpanded={highlightGroups.length < 3 || isPdfExport}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  reloading: css({
    opacity: 0.6,
  }),
  groups: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};
