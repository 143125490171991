/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import {
  todayInTenantTz,
  useDateContext,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import momentTz from 'moment-timezone';

export interface IDateAnswerProps {
  allowPastDates: boolean;
  error?: string;
  floatingText?: string;
  id: string;
  isEnabled: boolean;
  onChange: (to: string) => void;
  outputDate: 'TenantEndOfDay' | 'TenantStartOfDay';
  value?: string;
}

export const DateAnswer: React.FC<IDateAnswerProps> = ({
  allowPastDates = false,
  error,
  floatingText,
  id,
  isEnabled,
  onChange,
  outputDate,
  value,
}) => {
  const { isPdfExport } = usePdfExportContext();
  const dateContext = useDateContext();
  const minDate = allowPastDates
    ? undefined
    : todayInTenantTz(dateContext.tenantTimezone);

  const styles = {
    base: css({
      flex: '1 1 275px',
      padding: '0 15px 10px 0',
    }),
    textContainer: css({
      display: 'flex',
      flexDirection: 'column',
    }),
    textLabel: css({
      fontSize: 12,
      color: '#808080',
      paddingBottom: 5,
    }),
  };

  const utc = momentTz(value, momentTz.ISO_8601).utc();
  const tenantTimezoneDate = utc.isValid()
    ? utc.tz(dateContext?.tenantTimezone || 'Etc/UTC', false)
    : undefined;

  const formattedDate = tenantTimezoneDate
    ? momentTz()
        .year(tenantTimezoneDate.year())
        .month(tenantTimezoneDate.month())
        .date(tenantTimezoneDate.date())
        .hour(2)
        .minute(0)
        .second(0)
        .format(dateContext?.format.toUpperCase() ?? 'MM/DD/YYYY')
    : '-';

  return (
    <div css={styles.base}>
      {isPdfExport ? (
        <div css={styles.textContainer}>
          <Text style={styles.textLabel}>{floatingText}</Text>
          <Text>{formattedDate}</Text>
        </div>
      ) : (
        <DatePicker
          dataTest={`forms-dateAnswer-datePicker-${floatingText}`}
          id={`dateAnswer-${id}`}
          value={value}
          label={floatingText}
          isEnabled={isEnabled}
          onChange={onChange}
          error={error}
          outputDate={outputDate}
          minDate={minDate}
        />
      )}
    </div>
  );
};
