/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { IDropdownListProps } from './DropdownList.tsx';
import { IDropdownListInlineFilter } from './types.ts';

export interface IDropdownListInlineFilterableBehaviorProps
  extends IDropdownListProps {
  inlineFilters?: IDropdownListInlineFilter[];
}

/**
 * HOC for a query builder dropdown list to render it with inline filters at the bottom of the dropdown.
 * Useful for refining items displayed in the query builder.
 */
export const DropdownListInlineFilterableBehavior = (
  DropdownList: React.ComponentClass<IDropdownListProps>,
) =>
  function DropdownListInlineFilterableBehaviorView(
    props: IDropdownListInlineFilterableBehaviorProps,
  ) {
    const { inlineFilters = [], ...dropdownProps } = props;
    const numberOfInlineFilters = inlineFilters.length;
    const numberOfActiveInlineFilters = inlineFilters.reduce(
      (acc, curr) => (curr.isSelected ? acc + 1 : acc),
      0,
    );

    const computedStyles = {
      filterContainer: css({
        position: 'absolute',
        right: 0,
        left: 0,
        bottom: numberOfInlineFilters > 0 ? -24 : 0,
        borderTop:
          numberOfInlineFilters === 1 ? '1px solid #DBDBDB' : undefined,
      }),
      filterOuter: css({
        background: '#F2F2F2',
        padding: '0 15px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        ':hover': {
          background: '#DBDBDB',
        },
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15)',
        clipPath: 'inset(0 -4px -4px -4px)',
      }),
    };

    const handleFilterClick = useCallback(
      ({ id, onClick }: IDropdownListInlineFilter) =>
        () =>
          onClick?.(id),
      [],
    );

    const elFilterLabels = inlineFilters.map((filter) => (
      <div
        css={computedStyles.filterOuter}
        key={filter.id}
        onClick={handleFilterClick(filter)}
      >
        <Text
          style={styles.filterLabel}
          cursor={'pointer'}
          className={filter.isSelected ? 'selected' : ''}
        >
          {filter.isSelected && filter.selectedLabel
            ? filter.selectedLabel
            : filter.label}
        </Text>
        {filter.isSelected && <Icons.tickDone size={16} />}
      </div>
    ));

    const elInlineFiltersTitle = numberOfInlineFilters > 1 && (
      <div css={styles.filterCounterContainer}>
        <Text style={styles.filterCounter}>{`${numberOfInlineFilters} Filters ${
          numberOfActiveInlineFilters > 0
            ? `(${numberOfActiveInlineFilters} active)`
            : ''
        }`}</Text>
      </div>
    );

    return (
      <div css={styles.base}>
        <DropdownList {...dropdownProps} />
        <div css={computedStyles.filterContainer}>
          {elInlineFiltersTitle}
          {elFilterLabels}
        </div>
      </div>
    );
  };

const styles = {
  base: css({
    position: 'relative',
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'stretch',
  }),
  filterLabel: css({
    color: COLORS.BLUE,
    fontSize: 10,
    padding: '6px 0',
    textTransform: 'uppercase',
    fontWeight: 900,
    '.selected': {
      color: '#9C9DA0',
      fontStyle: 'italic',
    },
  }),
  filterCounter: css({
    color: color.format(1),
    textTransform: 'uppercase',
    fontSize: 10,
  }),
  filterCounterContainer: css({
    background: COLORS.BLUE,
    padding: '6px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
};
