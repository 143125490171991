import {
  ApolloClient,
  from,
  HttpOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import fetch from 'cross-fetch';
import { GraphQLClient } from '../client/GraphQLClient.ts';
import {
  createHeadersLink,
  resolveCustomHeaders,
  ResolveHeadersType,
} from '../links/headers.ts';

export type HttpLinkInitType = {
  type: 'HttpLink';
  options: HttpOptions;
};

export type BatchLinkInitType = {
  type: 'BatchHttpLink';
  options: {
    batchInterval?: number;
    batchMax?: number;
  };
};

export type UploadLinkInitType = {
  type: 'UploadLink';
  options: HttpOptions;
};

export type LinkInitOptions =
  | HttpLinkInitType
  | BatchLinkInitType
  | UploadLinkInitType;

export interface IInitGraphQLOptions {
  resolveHeaders?: ResolveHeadersType;
  useGlobalCache?: boolean;
}

const buildCache = () =>
  new InMemoryCache({
    typePolicies: {
      AnswerIssueCause: { merge: false },
      AuditLogConnection: { merge: false },
      AuthTenantsConnection: { merge: false },
      ConfigurationQuery: { merge: false },
      ContentPackCommentConnection: { merge: false },
      DashboardGridRowConnection: { merge: false },
      DashboardHeaderMetric: { merge: false },
      DashboardPerson: { merge: false },
      DashboardPersonHeader: {
        merge: false,
        fields: { metrics: { merge: false } },
      },
      DashboardQuery: { merge: false },
      DashboardTeam: { merge: false },
      DashboardTeamHeader: {
        merge: false,
        fields: { metrics: { merge: false } },
      },
      DashboardTemplateConnection: { merge: false },
      DigitalContentPackConnection: { merge: false },
      DigitalContentQuery: { merge: false },
      DistributionListConnection: { merge: false },
      FormAction: {
        merge: false,
        fields: { verification: { merge: false } },
      },
      FormActionConnection: { merge: false },
      FormAnswerConnection: { merge: false },
      FormAnswerIssueForCoaching: {
        merge: false,
        fields: { answerIssueCauses: { merge: false } },
      },
      FormAttendeeConnection: { merge: false },
      FormEditorProjectConnection: { merge: false },
      FormEditorReleaseConnection: { merge: false },
      FormGoalCategoryConnection: { merge: false },
      FormGoalConnection: { merge: false },
      FormInstance: { merge: false, fields: { signOffs: { merge: false } } },
      FormInstanceConnection: { merge: false },
      FormScheduleConnection: { merge: false },
      FormScheduleDistributionListConnection: { merge: false },
      FormScheduleFormTemplateConnection: { merge: false },
      FormsDesignerDraftConnection: { merge: false },
      FormsMutation: { merge: false },
      FormsQuery: { merge: false },
      FormTemplateCategoryConnection: { merge: false },
      FormTemplateConnection: { merge: false },
      FormTemplateDefinitionConnection: { merge: false },
      GoalScheduleConnection: { merge: false },
      metrics: { merge: false },
      OrgBusinessUnitConnection: { merge: false },
      OrgHierarchyQuery: { merge: false },
      OrgHierarchyMutation: { merge: false },
      OrgPersonConnection: { merge: false },
      OrgPersonMembershipConnection: { merge: false },
      OrgTeamConnection: { merge: false },
      OrgTeamMembershipConnection: { merge: false },
      OrgTeamManagerConnection: { merge: false },
    },
  });

const cache = buildCache();

/**
 * Initializes the GraphQL client.
 */
export function init(uri: string, options: IInitGraphQLOptions = {}) {
  const { resolveHeaders = {}, useGlobalCache = true } = options;

  // Middleware for injecting http headers.
  const customHeaders = resolveCustomHeaders(resolveHeaders);
  const headersLink = createHeadersLink();

  // Setup the network interface.
  const networkLink = createUploadLink({ uri, fetch });

  // Construct the client.
  const apollo = new ApolloClient<NormalizedCacheObject>({
    link: from([customHeaders, headersLink.concat(networkLink)]),
    cache: useGlobalCache ? cache : buildCache(),
  });
  const client = new GraphQLClient(apollo);

  return client;
}
