/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { TemplateOptionsPopup } from './TemplateOptionsPopup.tsx';

export interface ICellSettingsIconProps {
  onDelete?: () => void;
  onOpen?: () => void;

  onShowPopup?: () => void;
  onHidePopup?: () => void;

  canDelete?: boolean;
  canOpen?: boolean;
  isPopupVisible?: boolean;

  value?: any;
  rowIndex: number;
}

export const CellSettingsIcon: React.FC<ICellSettingsIconProps> = ({
  onDelete,
  onOpen,
  onShowPopup,
  onHidePopup,
  canDelete = false,
  canOpen = false,
  isPopupVisible,
  value,
  rowIndex,
}) => {
  const handleSelectionChanged = useCallback(
    (e: string) => {
      if (e === 'OPEN') onOpen?.();
      if (e === 'DELETE') onDelete?.();
    },
    [onOpen, onDelete],
  );

  const elLaunch =
    canDelete || canOpen ? (
      <div css={styles.launchIconOuter}>
        <Icons.moreVert
          size={18}
          fill={COLORS.BLUE}
          cursor={'pointer'}
          onClick={onShowPopup}
        />
      </div>
    ) : undefined;

  const elOptions = isPopupVisible ? (
    <TemplateOptionsPopup
      showOpen={canOpen}
      showDelete={canDelete}
      isFocused={true}
      rowIndex={rowIndex}
      onFocusChange={onHidePopup}
      onSelectionChanged={handleSelectionChanged}
    />
  ) : undefined;

  return (
    <div css={styles.base}>
      <div css={styles.contentOuter}>
        <div css={styles.textOuter}>
          <Text
            ellipsis={true}
            size={14}
            weight={400}
            color={color.format(-0.7)}
            style={styles.text}
          >
            {value}
          </Text>
        </div>
      </div>
      {elLaunch}
      {elOptions}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxHeight: 20,
  }),
  contentOuter: css({
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    paddingRight: 5,
  }),
  textOuter: css({
    maxHeight: 16,
    minHeight: 16,
    paddingTop: 1,
    overflow: 'hidden',
  }),
  text: css({ width: '100%' }),
  launchIconOuter: css({ paddingRight: 8 }),
};
