/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DigitalContent } from '../components/DigitalContent/DigitalContent.tsx';
import { DigitalContentContainer } from '../components/DigitalContent/DigitalContentContainer.tsx';
import { DigitalContentComments } from '../components/DigitalContentComments/DigitalContentComments.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { IDigitalContentLine } from '../types/types.ts';

export interface IDigitalContentFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isFormsDesigner: boolean;
  line: IDigitalContentLine;
}

export const DigitalContentFactoryContainer: React.FC<
  IDigitalContentFactoryContainerProps
> = ({ canUpdateInstance, instanceId, isFormsDesigner, line }) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      bullet={'block'}
      bulletLeft={0}
      bulletTop={-13.5}
      highlightOnHover={false}
      id={line.id}
      isVisible={line.isEnabled && isVisible}
      key={`factory-${instanceId}-${line.id}`}
      marginTop={30}
      marginBottom={40}
    >
      <SectionTitle text={line.title} />
      <FormListItemWrapped
        id={'digitalContent'}
        highlightOnHover={false}
        bullet={'digitalContent'}
        marginTop={30}
        paddingLeft={-32}
        paddingRight={-32}
        bulletLeft={-42}
        bulletTop={0}
      >
        {!isFormsDesigner ? (
          <DigitalContentContainer
            packId={line.packId}
            canUpdateInstance={canUpdateInstance}
          />
        ) : (
          <>
            <DigitalContent
              description={line.description}
              files={line.files ?? []}
              id={line.packId}
              isLoading={false}
              title={line.title}
            />
            {line.commentsEnabled && (
              <>
                <div css={css({ paddingBottom: 40 })} />
                <DigitalContentComments
                  canAddComments={false}
                  canReplyToComments={false}
                  comments={[]}
                  guidance={line.commentsGuidance}
                />
              </>
            )}
          </>
        )}
      </FormListItemWrapped>
    </FormListItemWrapped>
  );
};
