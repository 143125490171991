/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanContainer } from '../components/FormActionPlan/FormActionPlanContainer.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { IActionPlanLine } from '../types/types.ts';

export interface IActionPlanFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  line: IActionPlanLine;
}

export const ActionPlanFactoryContainer: React.FC<
  IActionPlanFactoryContainerProps
> = ({ instanceId, line }) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      bullet={line.isDetachable ? 'none' : 'block'}
      bulletLeft={line.isDetachable ? undefined : 0}
      bulletTop={line.isDetachable ? undefined : -12}
      highlightOnHover={false}
      id={line.id}
      key={`actionPlan-${instanceId}-${line.id}`}
      parentId={line.parentId}
      marginTop={0}
      marginBottom={15}
      isVisible={line.isEnabled && isVisible}
    >
      {!line.isDetachable && (
        <div css={css({ marginTop: 60, paddingBottom: 40 })}>
          <SectionTitle text={line.title} />
        </div>
      )}
      <FormActionPlanContainer
        includeCategories={line.includeCategories}
        instanceId={instanceId}
        lineId={line.id}
        typeFilter={line.actionPlanType}
      />
    </FormListItemWrapped>
  );
};
