/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import {
  FormAnswerAutomatedAction,
  FormInsight,
  FormIssue,
} from '@se/data/forms/types.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { ascend, prop, sortWith } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { formActionSlice } from '../../../redux/formAction/index.ts';
import { getActionTitle } from '../../../redux/formAction/util.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { SelectAutomatedActionPopup } from '../../SelectAutomatedActionPopup/SelectAutomatedActionPopup.tsx';

const sortAutomatedActions = sortWith<FormAnswerAutomatedAction>([
  ascend(prop('formQuestionIndex')),
  ascend(prop('issueInsightClassificationIndex')),
  ascend(prop('label')),
]);

export type CreateAutomatedActionProps = {
  insight?: FormInsight;
  instanceId: string;
  isShowing: boolean;
  issue?: FormIssue;
  lineId: string;
  onHideDropdown: () => void;
};

export const CreateAutomatedAction: React.FC<CreateAutomatedActionProps> = ({
  insight,
  instanceId,
  isShowing,
  issue,
  lineId,
  onHideDropdown,
}) => {
  const [mouseOver, handleMouseOver, handleMouseLeave] = useMouseOver();
  const dispatch = useFormsDispatch();

  const [dropdownShowing, setDropdownShowing] = useState(false);

  const locale = useTenantLocale();

  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[lineId];
  });

  const automatedActions = useFormsSelector((state) => {
    if (!answer) return;
    return state.automatedAction.answerAutomatedActions[answer.id]
      ?.automatedActions;
  });

  const isLoading = useFormsSelector((state) => {
    if (!answer) return;
    return (
      state.automatedAction.isLoading ||
      state.automatedAction.answerAutomatedActions[answer.id]?.isLoading
    );
  });

  const availableAutomatedActions = useMemo(() => {
    const unsorted = (automatedActions ?? []).filter(
      (aa) => !aa.createdActionId,
    );
    return sortAutomatedActions(unsorted);
  }, [automatedActions]);

  const handleNewActionDraft = useCallback(
    (automatedAction?: FormAnswerAutomatedAction) => {
      if (!issue && !insight) return;

      const issueId = issue?.id;
      const insightId = insight?.id;

      dispatch(
        formActionSlice.newDraft({
          actionId: uuid.generate(),
          title: getActionTitle(locale, insightId, issue?.id),
          assignedTo: instanceSubject
            ? { id: instanceSubject.id, name: instanceSubject.name }
            : undefined,
          issueId,
          insightId,
          formInstanceSubject: instanceSubject
            ? {
                id: instanceSubject.id,
                label: instanceSubject.name,
                type: instanceSubject.kind === 'Person' ? 'PERSON' : 'TEAM',
              }
            : undefined,
          openedFromFormInstanceId: instanceId,
          description: automatedAction?.description,
          answerAutomatedActionId: automatedAction?.id,
        }),
      );
    },
    [dispatch, insight, instanceId, instanceSubject, issue, locale],
  );

  const automated = availableAutomatedActions?.length > 0;

  const hideDropdown = useCallback(() => {
    setDropdownShowing(false);
    onHideDropdown();
  }, [onHideDropdown]);

  const showDropdown = useCallback(() => setDropdownShowing(true), []);

  const handleButtonClicked = useCallback(() => {
    if (isLoading) return;
    return automated ? showDropdown() : handleNewActionDraft();
  }, [automated, handleNewActionDraft, isLoading, showDropdown]);

  const col = getColor(automated, mouseOver);

  const show = isShowing || dropdownShowing;
  if (!show) return;

  return (
    <div
      css={styles.base}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Button onClick={handleButtonClicked} hoverAlpha={0}>
        <>
          <div css={styles.button}>
            <div
              css={[styles.left(col), isLoading && styles.sideLoading(col)]}
            />
            <div
              css={[
                styles.buttonContent(col, automated),
                isLoading && styles.buttonContentLoading(col),
              ]}
            >
              {isLoading ? (
                <div css={styles.buttonSpinner}>
                  <Spinner color={'white'} size={22} />
                </div>
              ) : (
                <>
                  <div css={styles.buttonChevron(col)} />
                  {automated && (
                    <div css={styles.automatedActionsContent}>
                      <Icons.bulbAndSparkles fill={'white'} size={20} />
                      <Text
                        color={'white'}
                        uppercase={true}
                        weight={400}
                        size={14}
                        cursor={'pointer'}
                      >{`${availableAutomatedActions.length} recommended ${str.plural(actionLabel, availableAutomatedActions.length)}`}</Text>
                    </div>
                  )}
                  <div css={styles.addActionContent}>
                    <Icons.addCircle fill={'white'} size={20} />
                    <Text
                      size={15}
                      color={'white'}
                      weight={300}
                      cursor={'pointer'}
                    >
                      {str.titleCase(actionLabel)}
                    </Text>
                  </div>
                </>
              )}
            </div>
            <div
              css={[styles.right(col), isLoading && styles.sideLoading(col)]}
            />
          </div>
          {dropdownShowing && (
            <SelectAutomatedActionPopup
              onHide={hideDropdown}
              automatedActions={availableAutomatedActions}
              onCreateAction={handleNewActionDraft}
              showIssueLabel={false}
            />
          )}
        </>
      </Button>
    </div>
  );
};

const getColor = (automated: boolean, mouseOver: boolean) => {
  const baseColor = automated ? COLORS.ORANGE : COLORS.BLUE;
  return mouseOver ? color.create(baseColor).brighten(0.5).hex() : baseColor;
};

const styles = {
  base: css({
    position: 'absolute',
    inset: 'auto 10% -36px auto',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'fit-content',
  }),
  button: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    filter: `drop-shadow(${color.format(-0.2)} 1px 1px 1px);`,
  }),
  buttonChevron: (col: string) =>
    css({
      position: 'absolute',
      inset: '-8px 50% auto auto',
      width: 0,
      height: 0,
      borderLeft: 'solid 8px transparent',
      borderRight: 'solid 8px transparent',
      borderBottom: `solid 8px ${col}`,
    }),
  buttonContent: (col: string, automated: boolean) =>
    css({
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: col,
      padding: '8px 0px',
      gap: 23,
      minWidth: automated ? 305 : 100,
      borderRadius: '0 0 3px 3px',
    }),
  buttonContentLoading: (col: string) =>
    css({
      backgroundColor: undefined,
      animation: `${keyframes({
        '0%': { backgroundColor: col },
        '50%': { backgroundColor: color.create(col).alpha(0.8).hex() },
        '100%': { backgroundColor: col },
      })} 2.33s ease-in-out infinite`,
    }),
  left: (col: string) =>
    css({
      borderTop: `solid 36px ${col}`,
      borderLeft: 'solid 10px transparent',
      borderRadius: '3px 0 25% 100%',
      marginRight: -1,
    }),
  sideLoading: (col: string) =>
    css({
      animation: `${keyframes({
        '0%': {
          borderTop: `solid 36px ${col}`,
        },
        '50%': {
          borderTop: `solid 36px ${color.create(col).alpha(0.8).hex()}`,
        },
        '100%': {
          borderTop: `solid 36px ${col}`,
        },
      })} 2.33s ease-in-out infinite`,
    }),
  right: (col: string) =>
    css({
      borderTop: `solid 36px ${col}`,
      borderRight: 'solid 10px transparent',
      borderRadius: '0 3px 100% 25%',
      marginLeft: -0.5,
    }),
  addActionContent: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    padding: '0px 8px',
  }),
  automatedActionsContent: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    padding: '0px 8px',
  }),
  buttonSpinner: css({
    height: 20,
    display: 'flex',
    justifyContent: 'center',
  }),
};
