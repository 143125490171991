import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, concatAll, filter, mergeMap, of } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { ReduxFormInstanceServerCancel } from '../types.ts';
import { cancelledFormInstance } from './actions.ts';

/**
 * Cancels a form instance.
 */
export function cancelFormInstanceEpic(
  action$: Observable<ReduxFormInstanceServerCancel>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_CANCEL'),
    filter(() => typeof window !== 'undefined'),
    mergeMap(async ({ payload }) => {
      try {
        const result = await client.mutate<{
          forms: { cancelFormInstance: { ok: boolean } };
        }>({
          mutation: gql`
            mutation CancelInstance(
              $instanceId: ID!
              $cancelReasonId: ID
              $notes: String
            ) {
              forms {
                cancelFormInstance(
                  input: {
                    formInstanceId: $instanceId
                    cancelReasonId: $cancelReasonId
                    notes: $notes
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: {
            instanceId: payload.instanceId,
            cancelReasonId: payload.cancelReasonId,
            notes: payload.notes,
          },
        });

        if (result.errors || !result.data?.forms?.cancelFormInstance?.ok) {
          throw new Error(
            `Error occurred while trying to withdraw form instance with id ${payload.instanceId}: ${result.errors}`,
          );
        }

        return of(cancelledFormInstance(payload.instanceId));
      } catch (err) {
        log.error('Error occurred during withdraw operation', err);
        return of(cancelledFormInstance(payload.instanceId, true));
      }
    }),
    concatAll(),
  );
}
