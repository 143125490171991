/** @jsxImportSource @emotion/react */

import { useHandleAnswerVisibility } from '../../hooks/useHandleAnswerVisibility.ts';
import { FormLineCoachingSummaryLine } from '../../types/types.ts';
import { FormListItemWrapped } from '../FormListItem/FormListItemWrapped.tsx';
import { CoachingSummaryHighlightsContainer } from './components/highlights/CoachingSummaryHighlightsContainer.tsx';
import { CoachingSummaryIssuesContainer } from './components/issues/CoachingSummaryIssuesContainer.tsx';
import { CoachingSummaryLearningAreasContainer } from './components/learningAreas/CoachingSummaryLearningAreasContainer.tsx';

export type FormLineCoachingSummaryProps = {
  line: FormLineCoachingSummaryLine;
  instanceId: string;
};

export const FormLineCoachingSummary: React.FC<
  FormLineCoachingSummaryProps
> = ({ line, instanceId }) => {
  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  return (
    <FormListItemWrapped
      id={line.id}
      parentId={line.parentId}
      highlightOnHover={false}
      bullet={'none'}
      isVisible={isVisible}
    >
      {line.coachingSummaryType === 'LearningAreasIdentified' && (
        <CoachingSummaryLearningAreasContainer
          instanceId={instanceId}
          lineId={line.id}
          filter={line.filter}
          hideIfEmpty={line.hideIfEmpty}
        />
      )}
      {line.coachingSummaryType === 'HighlightsIdentified' && (
        <CoachingSummaryHighlightsContainer
          instanceId={instanceId}
          lineId={line.id}
          filter={line.filter}
          hideIfEmpty={line.hideIfEmpty}
        />
      )}
      {line.coachingSummaryType === 'IssuesIdentified' && (
        <CoachingSummaryIssuesContainer
          instanceId={instanceId}
          lineId={line.id}
          filter={line.filter}
          hideIfEmpty={line.hideIfEmpty}
        />
      )}
    </FormListItemWrapped>
  );
};
