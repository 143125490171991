/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export type SectionTitleProps = {
  text: string;
};

/**
 * A block that displays the title of a section.
 */
export const SectionTitle: React.FC<SectionTitleProps> = ({
  text = 'Untitled',
}) => (
  <Text
    style={styles.text}
    uppercase={true}
    size={20}
    weight={700}
    color={color.format(-0.6)}
  >
    {text}
  </Text>
);

const styles = {
  text: css({
    cursor: 'default',
  }),
};
