/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { ObjectInspector } from '@seeeverything/ui.test/src/components/ObjectInspector/ObjectInspector.tsx';
import { debugSlice } from '@seeeverything/ui.util/src/redux/debug/index.ts';
import { StorageKey } from '@seeeverything/ui.util/src/storage/api.ts';
import { useCallback, useEffect, useState } from 'react';
import { useShellDispatch, useShellSelector } from '../../../redux/store.ts';

/**
 * Provides an interface to debug/diagnose the Redux state tree/actions.
 */
export const ReduxDebugContainer = () => {
  const [isEnabled, setIsEnabled] = useLocalStorage(
    StorageKey.DebugFilterEnabled,
    false,
  );
  const [filter, setFilter] = useLocalStorage(
    StorageKey.DebugFilterText,
    'NON-MATCH',
  );

  const [isInit, setIsInit] = useState(false);

  const appState = useShellSelector((state) => state);
  const dispatch = useShellDispatch();

  const setLoggingFlag = useCallback(
    (to: boolean) => () => {
      setIsEnabled(to);
      dispatch(debugSlice.setLoggingFlag(to));
    },
    [dispatch, setIsEnabled],
  );

  const setLoggingFilter = useCallback(
    async (to: string) => {
      setLoggingFlag(true)();
      setFilter(to);
      dispatch(debugSlice.setStartsWithFilter(to));
    },
    [dispatch, setFilter, setLoggingFlag],
  );

  useEffect(() => {
    if (!isInit) {
      dispatch(debugSlice.setLoggingFlag(isEnabled));
      dispatch(debugSlice.setStartsWithFilter(filter));
      setIsInit(true);
    }
  }, [dispatch, filter, isEnabled, isInit]);

  useEffect(() => () => dispatch(debugSlice.setLoggingFlag(false)), [dispatch]);

  return (
    isInit && (
      <>
        <div css={styles.base}>
          <input
            type={'checkbox'}
            onChange={setLoggingFlag(!isEnabled)}
            checked={isEnabled}
          />
          <TextField
            id={'redux-filter'}
            label={'redux-debug action name filter (starts with)'}
            placeholder={'e.g. ui.shell/'}
            onChange={setLoggingFilter}
            value={filter}
            style={styles.reduxFilter}
          />
        </div>
        <ObjectInspector
          name={'redux'}
          data={appState}
          expandLevel={1}
          expandPaths={['$.*.*', '$.*.*.*', '$.*.*.*.*']}
        />
      </>
    )
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  reduxFilter: css({
    padding: '0px 0px 10px 10px',
  }),
};
