/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ModalDialog } from '@seeeverything/ui.primitives/src/components/ModalDialog/ModalDialog.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useMemo } from 'react';
import { formActionSlice } from '../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { ActionDetailV2Container } from './components/ActionDetailV2Container.tsx';
import { ActionSkeleton } from './components/ActionSkeleton.tsx';

export const ActionDialogV2Container: React.FC = () => {
  const dispatch = useFormsDispatch();

  const isLoading = useFormsSelector((state) => state.formActionV2.isLoading);
  const isSaving = useFormsSelector((state) => state.formActionV2.isSaving);
  const isSpinning = isLoading || isSaving;

  const isShowing = useFormsSelector((state) => state.formActionV2.isShowing);
  const computedStyles = {
    base: css({
      pointerEvents: isShowing ? 'auto' : 'none',
    }),
  };

  const actionId = useFormsSelector((state) => state.formActionV2.id);

  const hasChanges = useFormsSelector((state) => {
    const actionHasChanged = Object.values(
      state.formActionV2.draftChanges.action,
    ).some((change) => change !== undefined);

    if (actionHasChanged) return true;

    const actionAnswersHaveChanged =
      state.formActionV2.draftChanges.answers?.length > 0;

    return actionAnswersHaveChanged;
  });

  const modalDialogActions = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        dataTest: 'dialog-cancelButton',
        isEnabled: true,
        backgroundAlways: false,
        onAction: () => dispatch(formActionSlice.hide()),
      },
      {
        id: 'OK',
        label: hasChanges ? 'Save' : 'OK',
        dataTest: 'dialog-okButton',
        onAction: () =>
          hasChanges
            ? dispatch(formActionSlice.save({ actionId }))
            : dispatch(formActionSlice.hide()),
        isEnabled: !isSpinning,
      },
    ],
    [dispatch, isSpinning, hasChanges, actionId],
  );

  const title = useFormsSelector((state) => state.formActionV2.title);

  return (
    <div css={computedStyles.base}>
      <ModalDialog
        actions={modalDialogActions}
        isShowing={isShowing}
        dialogWidth={3500}
      >
        <div css={styles.base}>
          {!isLoading && (
            <Text
              size={48}
              color={color.format(-0.2)}
              selectable={false}
              weight={FontWeight.light}
            >
              {title}
            </Text>
          )}
          <div css={styles.content}>
            <div css={styles.scrollContainer}>
              {!isLoading && <ActionDetailV2Container />}
              {isSaving && (
                <div css={styles.savingCoverOuter}>
                  <Spinner center={true} />
                </div>
              )}
              {isLoading && (
                <div css={styles.skeletonBase}>
                  <ActionSkeleton />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalDialog>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    margin: '20px 20px 20px 32px',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    gap: 20,
    position: 'relative',
  }),
  scrollContainer: css({
    flex: '1 1 auto',
    minHeight: 230,
  }),
  skeletonBase: css({
    position: 'relative',
    flex: '1 1 auto',
  }),
  savingCoverOuter: css({
    backgroundColor: color.format(0.8),
    zIndex: 2,
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};
