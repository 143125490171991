/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IAccordionChangeEvent,
} from '@seeeverything/ui.primitives/src/components/Accordion/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { usePdfExportContext } from '@seeeverything/ui.primitives/src/hooks/usePdfExport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useEffect, useState } from 'react';

export type SpeechBlockProps = {
  canHide?: boolean;
  id: string;
  isHidden?: boolean;
  markdown: string;
  onHideClicked?: () => void;
};

/**
 * A visual container for script content, questions etc.
 * Provides stateful hiding that accepts isHidden prop change.
 */
export const SpeechBlock: React.FC<SpeechBlockProps> = ({
  canHide = true,
  id,
  isHidden: propsIsHidden,
  markdown,
  onHideClicked,
}) => {
  const { isPdfExport } = usePdfExportContext();
  const [isHidden, setIsHidden] = useState(propsIsHidden === true);

  const handlePinClick = useCallback(
    (e: IAccordionChangeEvent) => {
      setIsHidden(!e.isExpanded);
      onHideClicked?.();
    },
    [onHideClicked],
  );

  useEffect(() => {
    if (propsIsHidden !== undefined) setIsHidden(propsIsHidden);
  }, [propsIsHidden]);

  const computedStyles = {
    content: css({
      display: 'flex',
      alignItems: 'center',
      border: isPdfExport ? undefined : `solid 1px ${color.format(-0.15)}`,
      borderTop: 'none',
      padding: isPdfExport ? 0 : 20,
      borderRadius: '0 0 4px 4px',
    }),
  };

  const elContent = markdown && (
    <div css={computedStyles.content}>
      <Markdown text={markdown} />
    </div>
  );

  return isPdfExport ? (
    elContent
  ) : (
    <Accordion
      id={id}
      elevation={0}
      onChange={handlePinClick}
      isExpanded={!isHidden}
      isDisabled={!canHide}
      hideDividers={true}
    >
      <AccordionSummary
        style={styles.summaryBase}
        expandedStyle={styles.summaryExpanded}
        contentStyle={styles.summaryContent}
        expandIcon={<Icons.arrowDropDown size={26} />}
      >
        <Text
          color={COLORS.BLUE}
          size={14}
          cursor={'pointer'}
          style={styles.showHideText}
        >
          {isHidden ? 'Show' : 'Hide'}
        </Text>
      </AccordionSummary>
      <AccordionDetails style={styles.detailsBase}>
        {elContent}
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  summaryBase: {
    background: color.format(-0.05),
    border: `1px solid ${color.format(-0.1)}`,
    borderRadius: 4,
    '&$expanded > $expandIcon': {
      transform: 'rotate(-180deg)',
    },
    '&$expanded > $expandIcon span svg': {
      fill: `${COLORS.BLUE} !important`,
    },
  },
  summaryExpanded: {
    background: color.format(-0.05),
    minHeight: 48,
    color: color.format(-0.4), // Ripple color.
    borderRadius: `4px 4px 0 0`,
    '&:hover': {
      background: color.format(-0.1),
    },
  },
  summaryContent: {
    color: COLORS.BLUE,
    // Aligns the show/hide label with the pin icon.
    display: 'inline-block' as const,
    textAlign: 'end' as const,
    '&$expanded': {
      color: `${COLORS.BLUE} !important`,
    },
  },
  detailsBase: {
    padding: 0,
  },
  showHideText: css({
    paddingRight: 5,
  }),
};
