import { getFormAction } from './action.ts';
import { getFormActionAudit } from './actionAudit.ts';
import { getFormActionTemplates } from './actionTemplate.ts';
import { getAnswerAutomatedActions } from './answerAutomatedActions.ts';
import { getAutomatedActionInsightConfigurations } from './automatedActionInsightConfigurations.ts';
import { getAutomatedActionIssueConfigurations } from './automatedActionIssueConfigurations.ts';
import { getAutomatedActions } from './automatedActions.ts';
import { getCoachingSummarySection } from './coachingSummarySection.ts';
import { getFormAnswer } from './formAnswer.ts';
import { getFormAnswerIssues } from './formAnswerIssue.ts';
import { getFormGoalCategories } from './formGoalCategories.ts';
import { getFormInstanceActionPlan } from './formInstanceActionPlan.ts';
import { getFormInstanceCancelReasons } from './formInstanceCancelReasons.ts';
import { getFormPermissions } from './formPermissions.ts';
import { getFormsBulkUploadJobHistory } from './formsBulkUploadJobHistory.ts';
import { getFormsBulkUploadJobHistoryFailed } from './formsBulkUploadJobHistoryFailed.ts';
import { getFormsBulkUploadJobHistoryFailedDownload } from './formsBulkUploadJobHistoryFailedDownload.ts';
import { getFormsBulkUploadJobHistorySuccess } from './formsBulkUploadJobHistorySuccess.ts';
import { getFormsBulkUploadValidate } from './formsBulkUploadValidate.ts';
import { getFormTemplate } from './formTemplate.ts';
import { getFormTemplatesByCategory } from './formTemplateByCategory.ts';
import { getFormTemplateCategories } from './formTemplateCategories.ts';
import { getFormTemplateIssues } from './formTemplateIssues.ts';
import { getFormTemplates } from './formTemplates.ts';
import { getGoalSchedules } from './goalSchedules.ts';
import { getInstanceSchedules } from './instanceSchedules.ts';

export const formsQuery = {
  getAnswerAutomatedActions,
  getAutomatedActionInsightConfigurations,
  getAutomatedActionIssueConfigurations,
  getAutomatedActions,
  getCoachingSummarySection,
  getFormAction,
  getFormActionAudit,
  getFormActionTemplates,
  getFormAnswer,
  getFormAnswerIssues,
  getFormGoalCategories,
  getFormInstanceActionPlan,
  getFormPermissions,
  getFormsBulkUploadJobHistory,
  getFormsBulkUploadJobHistoryFailed,
  getFormsBulkUploadJobHistoryFailedDownload,
  getFormsBulkUploadJobHistorySuccess,
  getFormsBulkUploadValidate,
  getFormTemplate,
  getFormTemplateCategories,
  getFormTemplateIssues,
  getFormTemplates,
  getFormTemplatesByCategory,
  getGoalSchedules,
  getInstanceSchedules,
  getFormInstanceCancelReasons,
};
