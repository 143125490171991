import { FormLineCoachingSummaryLine, ISectionLine } from '../types/types.ts';
import { defaultId } from '../util/util.data.parse.ts';
import { CoachingSummaryDefinition } from './types/section.types.ts';

export function parseCoachingSummary(
  definition: CoachingSummaryDefinition,
  parentSection: ISectionLine,
  indices?: number[],
): FormLineCoachingSummaryLine | undefined {
  if (!definition.coachingSummary) return;

  return {
    id: `coachingSummary-${defaultId(indices)}`,
    type: 'coachingSummary',
    coachingSummaryType: getCoachingSummaryType(
      definition.coachingSummary.type,
    ),
    filter: definition.coachingSummary.filter,
    hideIfEmpty: Boolean(definition.coachingSummary.hideIfEmpty),
    highlightOnHover: false,
    isRequired: false,
    parentId: parentSection.id,
    showIndex: false,
    showOnAnswer: definition.coachingSummary.showOnAnswer,
    showOnReportingDateMonth:
      definition.coachingSummary.showOnReportingDateMonth,
    showOnSwitch: definition.coachingSummary.showOnSwitch,
  };
}

const getCoachingSummaryType = (
  type: CoachingSummaryDefinition['coachingSummary']['type'],
) => {
  switch (type) {
    case 'Highlights':
      return 'HighlightsIdentified';

    case 'Issue':
      return 'IssuesIdentified';

    case 'LearningArea':
      return 'LearningAreasIdentified';
  }
};
