/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo } from 'react';
import { FormBulletType } from '../../types/types.ts';
import { Bullet } from '../Bullet/Bullet.tsx';

export interface IFormListItemProps {
  bullet?: FormBulletType;
  bulletLeft?: number;
  bulletTop?: number;
  children?: React.ReactNode;
  highlightOnHover?: boolean;
  id: string;
  isVisible?: boolean;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  onClick?: () => void;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  parentId?: string;
  style?: SerializedStyles;
}

/**
 * A single item within a form list.
 */
const View: React.FC<IFormListItemProps> = ({
  bullet = 'small-open',
  bulletLeft = -5,
  bulletTop = 4,
  children,
  highlightOnHover,
  id,
  isVisible = true,
  onClick,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  marginTop = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  paddingRight = 0,
  paddingTop = 0,
  parentId,
  style,
}) => {
  const [forwardedRef] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
  });

  const computedStyles = {
    base: css({
      position: 'relative',
      boxSizing: 'border-box',
      paddingTop,
      paddingBottom,
      cursor: onClick ? 'pointer' : 'inherit',
      marginBottom,
      marginTop,
      marginLeft,
      marginRight,
      ':hover': highlightOnHover
        ? { backgroundColor: color.format(-0.05) }
        : undefined,
    }),
    contentOuter: css({
      position: 'relative',
      paddingLeft: 32 + paddingLeft,
      paddingRight: 32 + paddingRight,
    }),
    bullet: css({
      position: 'absolute',
      inset: `${bulletTop}px auto auto ${bulletLeft}px`,
    }),
  };

  return (
    <Transition.Collapse
      in={isVisible}
      mountOnEnter={true}
      unmountOnExit={true}
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      <div id={id} css={computedStyles.base} onClick={onClick}>
        <div css={computedStyles.contentOuter}>
          <Bullet bullet={bullet} style={computedStyles.bullet} />
          <div css={style}>{children}</div>
        </div>
      </div>
    </Transition.Collapse>
  );
};

export const FormListItem = memo(View, propsAreEqualFastCompare);
